import React, { useEffect, useState,useRef } from 'react';
import { isExists } from '../../../Helpers/Utils';
import { useTranslation } from "react-i18next";
import { ErrorText, SuccessIcon } from '../../Common/HelperComponents';
import SingleSelect from '../DropDowns/SingleSelect';
import Constants from '../../../Configurations/Constants';
import i18n from '../../../i18n';
import { getDeclineReason } from '../../../Services/MatchingTandem/MatchingService';
import { useSelector } from 'react-redux';



export const CustomTextArea = props => {

    

    const {
        labelName,
        id,
        error,
        errorMessage,
        customClasses,
        allowExtraLength,
        success,
        questionDetails,
        selectedItems,
        inputValue,
        inputMaxlength = 100,
        placeholder,
        maxLength,
        simple,
        info,
        infoMessage,
        rows,
        Language,
        textNameToCheck,
        ...rest
    } = props;

    const { t: translate } = useTranslation();
    const [selectedOption, setSelectedOption] = React.useState(inputValue || "");
    const [internalError, setInternalError] = React.useState(false);
    const [internalMessage, setInternalMessage] = React.useState(errorMessage);
    const [newValue, setNewValue] = React.useState('')
    const [newId, setNewId] = React.useState('')
    const [result, setResult] = React.useState(null)
    const { user } = useSelector((state) => state.user);
    const ref = useRef();

    const handleInputChange = (e) => {
        
        setSelectedOption(e);
        handleInternalValidations(e);
        /** Invokes passed function so that parent get updated */
        const returnObj = {
            questionDetails,
            newValue: newValue,
            newId: newId,
            selectedOptions: e
        }
        selectedItems(returnObj);
    }

    const handleInternalValidations = (e) => {
        if (e && e.length > inputMaxlength) {
            setInternalError(true);
            setInternalMessage(translate(`max-characters-exceeded-error`, { count: inputMaxlength }));
        } else {
            setInternalError(false);
            setInternalMessage(errorMessage);
        }
    }

    const getDeclineReason2 = (selected) => {
        if(selected.selectedOptions.value) {
        console.log(selected.selectedOptions.value)
        setNewValue(selected.selectedOptions.value)
        setNewId(selected.selectedOptions.id);
        //handleInputChange();
    } else {
        setNewValue('');
        setNewId('');
        //handleInputChange();
    }
    }

    useEffect(() => {
        handleInputChange();
    }, [newValue, newId])



    const selectedLanguage =
        i18n.language === Constants.Language.Deutsch.Code
            ? Constants.Language.Deutsch.Name
            : Constants.Language.English.Name;
            useEffect(async () => {
                let userRoleName = '';
                if(props && props.textNameToCheck && (props.textNameToCheck[0] == 'Are you sure you want to decline this mentor proposal?' || props.textNameToCheck[0] == 'Bist du sicher, dass du diesen Mentor*in-Vorschlag ablehnen möchtest?')) {
                    userRoleName = 'Mentee'
                } else if( props && props.textNameToCheck && (props.textNameToCheck[0] == 'Are you sure you want to decline this mentee proposal?' || props.textNameToCheck[0] == 'Bist du sicher, dass du diesen Mentee-Vorschlag ablehnen möchtest?')) {
                    userRoleName = 'Mentor'
                } else {
                    userRoleName = user.userRole[0].name;
                }
                let resultDrop = await getDeclineReason(user.email, userRoleName,selectedLanguage)
                .then(response => {
                    if(isExists(response)) {
                        console.log(response);
                        return response.map(item => ({value: item.declineReasonText, label: item.declineReasonText, id: item.id}));
                    }else{
                        return []
                    }
                });
                setResult(resultDrop);
            }, [])
    
    const handleInput = (e) => {
    if (ref.current) {
      ref.current.style.height = "auto";
        ref.current.style.height = `${e.target.scrollHeight - 16}px`;
    }
  };
    
    if(props && props.textNameToCheck && (props.textNameToCheck[0] == 'Are you sure you want to decline this mentee proposal?' || props.textNameToCheck[0] == 'Bist du sicher, dass du diesen Mentee-Vorschlag ablehnen möchtest?'))
        { 
            return (
                <div data-testid="customTextArea" className={`occupy-1 column ${customClasses}`}>
                    {info &&
                        <div className={`w-80-p p-small border-r-7 bg-grey p-all-10 m-top-12`}>
                            <p>{infoMessage}</p>
                        </div>
                    }
                    <div className="custom-input flex m-top-12">
                        <p className="p2">{translate("decline-reason-mandatory")}</p>
                    </div>
                    <div className="custom-input  m-top-12">
                        <div>
                            <SingleSelect
                                selectedItems={getDeclineReason2}
                                options={result}
                                placeholder={translate("please-select-here")}
                                successTick={false}
                                errorMessage={translate("requiredFieldError")}
                                language={selectedLanguage}
                            />
                        </div>
                    </div>
            
                    <div className="custom-input flex m-top-17">
                        <p className="p2">{translate("comments-optional")}</p>
                    </div>
                    <div className="custom-input flex m-top-12">
                    {simple ?
                            <textarea className={`w-100-p ${(error || internalError) ? "error" : ""}`} placeholder={placeholder} rows={rows} maxLength={allowExtraLength ? "none" : inputMaxlength} value={selectedOption} onChange={(e) => handleInputChange(e.target.value)} {...rest} />
                            :
                            <textarea className={`w-80-p ${(error || internalError) ? "error" : ""}`} placeholder={placeholder} rows={rows} maxLength={allowExtraLength ? "none" : inputMaxlength} value={selectedOption} onChange={(e) => handleInputChange(e.target.value)} {...rest} />
                        }
                        {(isExists(selectedOption) && !internalError && !simple) && <SuccessIcon />}
                    </div>
                    {(error || internalError) && <ErrorText errorMessage={internalMessage} />}
                </div>
            )
        }  
        else if(props && props.textNameToCheck && (props.textNameToCheck[0] == 'Are you sure you want to decline this mentor proposal?' || props.textNameToCheck[0] == 'Bist du sicher, dass du diesen Mentor*in-Vorschlag ablehnen möchtest?'))
            { 
                return (
                    <div data-testid="customTextArea" className={`occupy-1 column ${customClasses}`}>
                    
                    {info &&
                        <div className={`w-80-p p-small border-r-7 bg-grey p-all-10 m-top-14`}>
                            <p>{infoMessage}</p>
                        </div>
                    }

                <div className="custom-input flex m-top-12">
                     <p className="p2">{translate("decline-reason-mandatory")}</p>
                </div>
                <div className="custom-input  m-top-10">
                    <div>
                        <SingleSelect
                            selectedItems={getDeclineReason2}
                            options={result}
                            placeholder={translate("please-select-here")}
                            successTick={false}
                            language={selectedLanguage}
                            errorMessage={translate("requiredFieldError")}
                        />
                    </div>
                </div>
                    {(error || internalError) && <ErrorText errorMessage={internalMessage} />}
                </div>
                )
                } else {
                    return (
                        <div data-testid="customTextArea" className={`occupy-1 column ${customClasses}`}>
                            <p className="p2">{labelName}</p>
                            {info &&
                                <div className={`w-80-p p-small border-r-7 bg-grey p-all-10 m-top-17`}>
                                    <p>{infoMessage}</p>
                                </div>
                            }
                            
                            
                            <div className="custom-input flex m-top-17">
                                {simple ?
                                    <textarea className={`w-100-p ${(error || internalError) ? "error" : ""}`} placeholder={placeholder} rows={rows} maxLength={allowExtraLength ? "none" : inputMaxlength} value={selectedOption} onChange={(e) => handleInputChange(e.target.value)} {...rest} onInput={handleInput} ref={ref} />
                                    :
                                    <textarea className={`w-80-p ${(error || internalError) ? "error" : ""}`} placeholder={placeholder} rows={rows} maxLength={allowExtraLength ? "none" : inputMaxlength} value={selectedOption} onChange={(e) => handleInputChange(e.target.value)} {...rest} onInput={handleInput} ref={ref}/>
                                }
                                {(isExists(selectedOption) && !internalError && !simple) && <SuccessIcon />}
                            </div>
                            {(error || internalError) && <ErrorText errorMessage={internalMessage} />}
                        </div>
                    )
                }
            }

export default CustomTextArea;