import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { getColor, isExists } from "../../../Helpers/Utils";
import { ErrorText, SuccessIcon } from "../../Common/HelperComponents";
import { BiSearchAlt2 } from "react-icons/bi";
import { useTranslation } from "react-i18next";
const SingleSelect = (props) => {
    const { preSelectedOptions, placeholder, error, errorMessage, isDisabled, successTick = true, serachIcon, options, isClearSelection, noOptionplaceholder = "no-option", language, translateKey="value", showClearIcon = true } = props;
  const [selectedOption, setSelectedOption] = useState(
    preSelectedOptions || null
  );
  const [selectedLanguage, setSelectedLanguage] = React.useState(language || null);
  const { t: translate } = useTranslation();
  const handleInputChange = (e) => {
    setSelectedOption(e);
    /** Invokes passed function so that parent get updated */
    const returnObj = {
      questionDetails: props.questionDetails,
      selectedOptions: e ? e : [],
    };
    props.selectedItems(returnObj);
  };

  useEffect(() => {
    if (isClearSelection) {
      setSelectedOption(null);
    }

  }, [isClearSelection])

  useEffect(() => {
    if (isClearSelection) {
      setSelectedOption(null);
    }
    if(isExists(selectedLanguage) && selectedLanguage !== language) {
      setSelectedLanguage(language);
      if(isExists(selectedOption)) {
          selectedOption.forEach(selection => {
              selection.label =  isExists(selection[translateKey]) ? 
              translate(selection[translateKey]) : 
              selection.label;
          });
      }
  }
  }, [options, language])

  const getComponentStyles = () => {
    const singleFitStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: getColor("--white"),
        height: "auto",
        minHeight: 50,
        borderRadius: "6px",
        borderWidth: "1px",
        boxShadow: `2px 2px 4px ${getColor("--shadow-color")}`,
        border: `1px solid ${error && !isExists(selectedOption) ? getColor("--error-color") : getColor("--border-color")}`,
        "&:focus:": {
          boxShadow: "none"
        },
        "&:hover": {
          border: `1px solid ${error && !isExists(selectedOption) ? getColor("--error-color") : getColor("--border-color")}`,
        }
      }),
      placeholder: (provided) => ({
        ...provided,
        color: getColor("--placeholder-color")
      }),
      menuList: (provided, state) => ({
        ...provided,
        padding: 0,
        border: `1px solid ${getColor("--border-color")}`,
        boxShadow: `2px 2px 4px ${getColor("--shadow-color")}`,
        borderRadius: "0px 0px 6px 6px",
      }),
      menu: (base) => ({
        ...base,
        marginTop: -3,
        marginLeft: 1,
        width: "99.5%",
        borderRadius: "0px 0px 6px 6px",
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? getColor("--white") : getColor("--primary-text-color"),
        backgroundColor: state.isSelected ? getColor("--primary-color") : getColor("--white"),
        borderBottom: `1px solid ${getColor("--dropdown-separation-color")};`,
        ":hover": {
          backgroundColor: getColor("--option-bg-color"),
          color: getColor("--primary-text-color"),
          borderLeft: `1px solid ${getColor("--primary-color")}`,
        },
      })
    };
    return singleFitStyles;
  }

  const SearchIcon = () => {
    return <BiSearchAlt2 color={`${getColor("--icons-color")}`} size={20} />
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {serachIcon && <SearchIcon />}
      </components.DropdownIndicator>
    );
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{translate(noOptionplaceholder)}</span>
      </components.NoOptionsMessage>
    );
  };

  const filterOptions = (option, input) => {
    if (input) {
      return option.label.toLowerCase().includes(input.toLowerCase());
    }
    return true;
  };

  const Option = props => {
    return (
      <components.Option {...props}>
        <div style={{ fontSize: 16 }}>{props.data.label}</div>
        {props.data.subLabel && <div style={{ fontSize: 12 }}>{`(${props.data.subLabel})`}</div> }
      </components.Option>
    );
  };

  return (
    <div className="flex column">
      <div className="flex">
        <Select
          placeholder={placeholder}
          filterOption={filterOptions}
          components={{
            IndicatorSeparator: () => null, DropdownIndicator, NoOptionsMessage, Option
          }}
          defaultValue={selectedOption}
          onChange={(e) => {
            handleInputChange(e);
          }}
          value={selectedOption}
          openMenuOnClick={() => { }}
          options={options}
          isDisabled={isDisabled}
          isClearable={showClearIcon}
          className={successTick ? "basic-single-select" : "basic-single-select-100"}
          styles={getComponentStyles()}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: getColor("--border-color"),
            },
          })}
        />
        {successTick && isExists(selectedOption) && <SuccessIcon />}
      </div>
      {(error && !isExists(selectedOption)) && <ErrorText errorMessage={errorMessage} />}
    </div>
  );
};

export default SingleSelect;
