import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { InformationBar, Question } from "../../Common/HelperComponents";
import {
  getAllUsers,
  getClusters,
  getMasterDataForMenteeStateChange,
  getStatusMaster,
  getUserDetail,
  updateMenteeInformation,
  updateUserStatusForMentor,
  deleteUserFormAzureAD
} from "../../../Services/Admin/AdminService";
import CustomBadge from "../../CustomControls/Badge/CustomBadge";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import CustomTable from "../../CustomControls/CustomTable/CustomTable";
import SingleSelect from "../../CustomControls/DropDowns/SingleSelect";
import {
  addSpaceAfterComma,
  getFormattedDate,
  isExists,
} from "../../../Helpers/Utils";
import Constants, {
  AllStates,
  MessageTypes,
  Pages,
  Roles,
  UserRoleInformationState,
} from "../../../Configurations/Constants";
import PopUp from "../../CustomControls/PopUp/PopUp";
import { Helmet } from "react-helmet";
import AdminCommonNavbar from "./AdminCommonNavbar";
import CustomAccordion from "../../CustomControls/CustomAccordion/CustomAccordion";
import { useErrorHandler } from "react-error-boundary";

const UserInformation = (props) => {
  const { t: translate, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const selectedLanguage =
    i18n.language === Constants.Language.Deutsch.Code
      ? Constants.Language.Deutsch.Name
      : Constants.Language.English.Name;
  const [formData, setFormData] = useState({
    users: null,
    email: null,
    notifications: null,
    userRole: null,
    userStatuses: null,
    isMentor: false,
    isMatchingdisabled: false,
    showNotifications: false,
    selectedFullName: null,
    language: selectedLanguage,
    clusterData: null,
    statusMaster: null,
    clusterModel: null,
    statusModel: null,
    preFilledMenteeStatusData: null,
  });

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
  const [clearSelection, setClearSelection] = useState(false);

  const [canShowMenteePopup, setMenteePopUp] = useState(false);
  const [showMenteeStatusMsg, setMenteeStatusMsg] = useState(false);
  const [menteeStatusMsg, setStatusMsg] = useState(false);
  const [isInputError, setInputError] = useState(false);
  const [showUserInfoAcc, setUserInfoAcc] = useState("0");
  const [showMenteeInfoAcc, setMenteeInfoAcc] = useState("1");
  const [showMentorInfoAcc, setMentorInfoAcc] = useState("1");
  const [showPopUp, setShowPopUp] = useState(false);
  const [messageData, setMessageData] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const popupHelperData = {
    enabled: {
      questions: [
        translate("admin-disable-profile-1"),
        translate("admin-disable-profile-2"),
      ],
      acceptText: translate("disable matching"),
      rejectText: translate("Cancel"),
    },
    disabled: {
      questions: [
        translate("admin-enable-profile-1"),
        translate("admin-enable-profile-2"),
      ],
      acceptText: translate("enable matching"),
      rejectText: translate("Cancel"),
    },
  };

  const getAllPopUpTexts = () => {
    if (formData.isMatchingdisabled) {
      return popupHelperData.disabled;
    }
    return popupHelperData.enabled;
  };

  const initiateMatching = async (val) => {
    if (val) {
      let disableMatching = true;
      if (formData.isMatchingdisabled && val) {
        disableMatching = false;
      }
      await updateUserStatusForMentor(formData.email, disableMatching)
        .then((response) => {
          if (response && response.isSuccess) {
            const { email } = formData;
            getUserDetail(email, selectedLanguage).then((response) => {
              if (response && response.isSuccess && isExists(response.value)) {
                const { userStatuses } = response.value;
                let copyOfStatus = [userStatuses[0]];
                if (isExists(copyOfStatus)) {
                  copyOfStatus.find((eachStatus) => {
                    if (eachStatus.role === Roles.Mentor) {
                      eachStatus.userStatus = eachStatus.userStatus;
                    }
                  });
                }
                setFormData({
                  ...formData,
                  userStatuses: copyOfStatus,
                  isMatchingdisabled: disableMatching,
                });
                showOrHideMessage(false);
              }
            });
          } else if (
            response &&
            response.isSuccess === false &&
            response.errorMessage === "InCompleteProfile"
          ) {
            showOrHideMessage(
              true,
              translate("admin-profile-data-incomplete"),
              MessageTypes.error
            );
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
    setShowPopUp(false);
  };

  useEffect(() => {
    if (formData.users === null) {
      getAllUsers()
        .then((response) => {
          if (response && response.isSuccess && isExists(response.value)) {
            fillFormData(response);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
    if (formData.language !== selectedLanguage && isExists(formData.email)) {
      setFormData({
        ...formData,
        preFilledMenteeStatusData: null,
      });
      getFullUserInformation();
    }
  }, [selectedLanguage]);

  const fillFormData = (response) => {
    setFormData({
      ...formData,
      users: prepareDataForSearchBox(
        response.value,
        "firstName",
        "email",
        true
      ),
      language: selectedLanguage,
    });
  };

  const prepareDataForSearchBox = (
    options,
    label,
    value,
    showSubLabel = false,
    transl = false
  ) => {
    let allOptions = [];
    options.forEach((option) => {
      const modifiedOption = showSubLabel
        ? {
            ...option,
            label: `${option[label]} ${
              label === "firstName" ? option["lastName"] : ""
            }`,
            value: option[value],
            subLabel: option[value] || "-",
          }
        : {
            ...option,
            label: `${transl ? translate(option[label]) : option[label]}${
              label === "firstName" ? `${option["lastName"]}` : ""
            }`,
            value: option[value],
          };
      allOptions.push(modifiedOption);
    });
    return allOptions;
  };

  const showOrHideMessage = (show, message, type) => {
    setMessageData({ message, type, show });
  };

  const setMenteeClusterData = (clusterData) => {
    let preFilledMenteeStatusData = { ...formData.preFilledMenteeStatusData };
    if (isExists(clusterData) && isExists(clusterData.selectedOptions)) {
      preFilledMenteeStatusData.clusterId =
        clusterData.selectedOptions.clusterId;
    } else {
      preFilledMenteeStatusData.clusterId = null;
    }

    setFormData({
      ...formData,
      preFilledMenteeStatusData,
    });
  };

  const resetForm = () => {
    setFormData({
        ...formData,
        email: null,
        selectedFullName: null,
        fullName: null,
        notifications: null,
        userRole: null,
        userStatuses: null,
        showNotifications: false,
        userCompleteProfile: null,
        allData: null,
      });
  }

  const deleteUser = (update) => {
    if(update){
        const { email } = formData;
        
        deleteUserFormAzureAD(email).then((res) => {
           if(res.isSuccess){
            setClearSelection(true);
            showOrHideMessage(
                true,
                translate("user-deleted-success"),
                MessageTypes.success
                );

            let { users } = formData;
            users = users.filter((o) => o.email !== email);

            setFormData({
                ...formData,
                users,
            });

            resetForm();
           }else if (res.isFailure) {
            showOrHideMessage(
                true,
                translate("unexpected-error"),
                MessageTypes.error
                );
           }
        })

    }

    setShowConfirmationPopup(false)
  }

  const saveMenteeStatusData = (update) => {
    if (update) {
      updateMenteeInformation(formData.preFilledMenteeStatusData)
        .then((res) => {
          setMenteeStatusMsg(true);
          if (res.isSuccess) {
            setStatusMsg(false);
          } else {
            setStatusMsg(true);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
    setMenteePopUp(false);
  };

  const setMenteeStatusData = (statusData) => {
    let preFilledMenteeStatusData = { ...formData.preFilledMenteeStatusData };
    if (isExists(statusData) && isExists(statusData.selectedOptions)) {
      preFilledMenteeStatusData.statusId = statusData.selectedOptions.id;
      preFilledMenteeStatusData.newStatus = statusData.selectedOptions.name;
    } else {
      preFilledMenteeStatusData.statusId = null;
      preFilledMenteeStatusData.newStatus = null;
    }

    setFormData({
      ...formData,
      preFilledMenteeStatusData,
    });
  };

  const getPrefilledMenteeStatus = (data) => {
    let preFilledMenteeStatusData = {
      statusId: 0,
      clusterId: 0,
      email: null,
      previousStatus: null,
      newStatus: null,
    };
    if (isExists(data)) {
      if (isExists(data.clusterModel)) {
        preFilledMenteeStatusData.clusterId = data.clusterModel.clusterId || 0;
      }
      if (isExists(data.statusModel)) {
        preFilledMenteeStatusData.statusId = data.statusModel.id || 0;
        preFilledMenteeStatusData.previousStatus =
          data.statusModel.name || null;
        preFilledMenteeStatusData.newStatus = data.statusModel.name || null;
      }
    }
    return preFilledMenteeStatusData;
  };

  const getFullUserInformation = () => {
    const { email } = formData;
    setMenteeStatusMsg(false);
    showOrHideMessage(false);
    if (isExists(email)) {
      setFormData({
        ...formData,
        preFilledMenteeStatusData: null,
      });
      getUserDetail(email, selectedLanguage)
        .then((response) => {
          if (response && response.isSuccess && isExists(response.value)) {
            const {
              userRole,
              userStatuses,
              notification,
              userCompleteProfile,
            } = response.value;
            let isMatchingdisabled,
              isMentor = false;
            const mentorStatus = userStatuses.find((eachStatus) => {
              return eachStatus.role === Roles.Mentor;
            });

            if (isExists(mentorStatus)) {
              isMentor = true;
              if (mentorStatus.userStatusId === 2) {
                isMatchingdisabled = true;
              }
            }

            const isMentee = userStatuses.find((eachStatus) => {
              return eachStatus.role === Roles.Mentee;
            });

            if (isExists(isMentee)) {
              getMasterDataForMenteeStateChange(email).then((res) => {
                let clusterData = null;
                let statusData = null;
                let preFilledMenteeStatusData = null;
                if (isExists(res.value)) {
                  const {
                    clusterMaster,
                    statusMaster,
                    menteeClusterAndStatusData,
                  } = res.value;
                  clusterData = isExists(clusterMaster)
                    ? prepareDataForSearchBox(
                        clusterMaster,
                        "clusterText",
                        "clusterId"
                      )
                    : null;
                  statusData = isExists(statusMaster)
                    ? prepareDataForSearchBox(
                        statusMaster,
                        "name",
                        "id",
                        false,
                        true
                      )
                    : null;
                  preFilledMenteeStatusData = getPrefilledMenteeStatus(
                    menteeClusterAndStatusData
                  );
                  preFilledMenteeStatusData.email = email;
                }
                setFormData({
                  ...formData,
                  fullName: formData.selectedFullName,
                  notifications: notification,
                  allData: response.value,
                  userCompleteProfile,
                  userRole,
                  userStatuses,
                  isMentor,
                  isMatchingdisabled,
                  showNotifications: true,
                  language: selectedLanguage,
                  clusterData: clusterData,
                  statusMaster: statusData,
                  clusterModel: null,
                  statusModel: null,
                  preFilledMenteeStatusData,
                });
              });
            } else {
              setFormData({
                ...formData,
                fullName: formData.selectedFullName,
                notifications: notification,
                allData: response.value,
                userCompleteProfile,
                userRole,
                userStatuses,
                isMentor,
                isMatchingdisabled,
                showNotifications: true,
                language: selectedLanguage,
              });
            }

            setUserInfoAcc("0");
            setMenteeInfoAcc("1");
            setMentorInfoAcc("1");
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      setInputError(true);
    }
  };

  
  const getUserInput = (option) => {
    setClearSelection(false)
    const { selectedOptions } = option;
    if (isExists(selectedOptions)) {
      setInputError(false);
      setFormData({
        ...formData,
        email: selectedOptions.email,
        selectedFullName: `${selectedOptions.firstName} ${selectedOptions.lastName}`,
      });
    } else {
      resetForm()
    }
  };

  const userAccountDetails = () => {
    let fieldList = [];
    const orderList = [
      "email",
      "city",
      "federalState",
      "country",
      "language",
      "gender",
      "professionalPosition",
      "company",
    ];
    const translationKeys = [
      "email-address",
      "City",
      "Federal State",
      "Country",
      "Preferred account language",
      "Gender",
      "Professional position",
      "Company / Employer",
    ];
    orderList.forEach((eachField, i) => {
      fieldList.push(
        <div key={i}>
          <p className="p2-bold m-top-20">{translate(translationKeys[i])}:</p>
          {eachField === "gender" || eachField === "language" ? (
            <span className="p-description-16">
              {translate(formData.userCompleteProfile[eachField]) || "-"}
            </span>
          ) : (
            <span className="p-description-16">
              {addSpaceAfterComma(formData.userCompleteProfile[eachField]) ||
                "-"}
            </span>
          )}
        </div>
      );
    });
    return fieldList;
  };

  const getUserQuestions = (userQuestionsAns) => {
    let allQuestions = [];
    if (isExists(userQuestionsAns)) {
      userQuestionsAns.forEach((question, i) => {
        allQuestions.push(
          <Question
            underline={i < userQuestionsAns.length - 1}
            customClasses="m-bottom-40"
            questionNameClass="p2-bold-v2"
            key={i}
            questionName={question.userQuestionText}
            helperText=""
          >
            <div className="p-top-16">
              <p className="p2-medium-light">
                {addSpaceAfterComma(question.userAnswer) || "-"}
              </p>
            </div>
          </Question>
        );
      });
    }
    return allQuestions;
  };

  const getM_ID = (userInput) => {
    let M_ID = "";
    if (isExists(formData.allData[userInput])) {
      formData.allData[userInput].forEach((val) => {
        if (M_ID !== "") {
          M_ID += ", ";
        }
        M_ID += val.matchingTandemId;
      });
    }
    return <span className="p-description-16">{`MT_ID: ${M_ID || "-"}`}</span>;
  };

  const canLoadData = () => {
    return isExists(formData.users);
  };

  const getMenteeAccountPanel = (status) => {
    const showEditWindow =
      UserRoleInformationState.AVAILABLE === status ||
      UserRoleInformationState.PENDING === status || 
      UserRoleInformationState.NEW === status;

    // const existingCluster = formData.clusterData.filter(e  => e.clusterId === formData.preFilledMenteeStatusData.clusterId);
    if (showEditWindow) {
      const existingStatus = formData.statusMaster.filter(
        (e) => e.name === status
      );
      if (isExists(existingStatus) && isExists(existingStatus[0])) {
        existingStatus[0].label = translate(status);
      }
      return (
        <>
          <div>
            <p className="p2-bold m-top-20">{translate("mentee-status")}:</p>
            <div className="m-top-10">
              {formData.preFilledMenteeStatusData && (
                <SingleSelect
                  selectedItems={setMenteeStatusData}
                  error={isInputError}
                  showClearIcon={false}
                  preSelectedOptions={existingStatus}
                  errorMessage={translate("requiredFieldError")}
                  options={formData.statusMaster}
                  placeholder={translate("Please select a value…")}
                  successTick={false}
                />
              )}
            </div>
          </div>
          <div>
            <p className="p2-bold m-top-20">{translate("Cluster")}:</p>
            <div className="m-top-10">
              {formData.preFilledMenteeStatusData && (
                <SingleSelect
                  selectedItems={setMenteeClusterData}
                  error={isInputError}
                  showClearIcon={false}
                  preSelectedOptions={formData.clusterData.filter(
                    (e) =>
                      e.clusterId ===
                      formData.preFilledMenteeStatusData.clusterId
                  )}
                  errorMessage={translate("requiredFieldError")}
                  options={formData.clusterData}
                  placeholder={translate("Please select a value…")}
                  successTick={false}
                />
              )}
            </div>
          </div>
          <CustomButton
            name={translate("save")}
            className="custom-btn-primary w-max m-top-29"
            onClickAction={() => setMenteePopUp(true)}
          />
        </>
      );
    } else {
      return (
        <>
          <div>
            <p className="p2-bold m-top-20">{translate("mentee-status")}:</p>
            <span className="p-description-16">{translate(status) || "-"}</span>
          </div>
          <div>
            <p className="p2-bold m-top-20">{translate("Cluster")}:</p>
              <span className="p-description-16">
                {formData.userCompleteProfile["cluster"] || "-"}
              </span>
          </div>
        </>
      );
    }
  };

  if (canLoadData()) {
    return (
      <>
        <Helmet>
          <title>{translate(Pages.AdminUserInformation.pageTitle)}</title>
        </Helmet>
        <PopUp
          show={canShowMenteePopup}
          acceptName={translate("save")}
          rejectName={translate("Cancel")}
          sendResponse={saveMenteeStatusData}
          questions={[translate("update-metee-status-data")]}
        />
        <PopUp
          show={showConfirmationPopup}
          acceptName={translate("yes")}
          rejectName={translate("no")}
          sendResponse={deleteUser}
          questions={[translate("delete-confirmation")]}
        />
        <AdminCommonNavbar
          msgData={messageData}
          setMessage={showOrHideMessage}
        />
        <div className="occupy-1 flex center-center m-bottom-60 m-top-60">
          <div className="w-max app-standard-gap p-left-60 p-right-60">
            <p className="p1 m-bottom-56">
              {translate("admin-user-info-title")}
            </p>

            <InformationBar
              info
              middleIcon
              addons="m-top-58 m-bottom-64"
              message={translate("admin-user-info-infoBar")}
            />

            <Row className="m-bottom-60">
              <p className="p2-medium-lh-21">
                {translate("admin-user-info-searchbox-label")}
              </p>
              <Col sm={12} lg={6} md={12} className="m-top-7">
                <Row className="m-top-18">
                  <Col sm={12} lg={8} md={12}>
                    <SingleSelect
                      selectedItems={getUserInput}
                      error={isInputError}
                      errorMessage={translate("requiredFieldError")}
                      options={formData.users}
                      placeholder={translate(
                        "admin-user-info-searchbox-placeholder"
                      )}
                      noOptionplaceholder={translate("no-name-found")}
                      successTick={false}
                      serachIcon
                      isClearSelection={clearSelection}
                    />
                  </Col>
                  <Col sm={12} lg={4} md={12}>
                    <CustomButton
                      name={translate("display-information")}
                      className="custom-btn-primary w-174"
                      onClickAction={getFullUserInformation}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {isExists(formData.userCompleteProfile) && (
              <div className="w-max">
                <Row>
                  <p className="p2-bold-20-v3 m-bottom-37">
                    {translate("selected-user")}
                  </p>
                </Row>
                <CustomAccordion
                  mixedStyle={true}
                  heading={translate("Account")}
                  activeKey={showUserInfoAcc}
                  setActiveKey={setUserInfoAcc}
                  accTitle={`${formData.fullName}`}
                >
                  <div className="container-fluid nopadding">
                    <div className="main-body">
                      <div className="row m-top-28">
                        {/* Left Section inside Accordion*/}

                        <div className="col-lg-4">
                          {/** Status Section*/}
                          {isExists(formData.userStatuses) &&
                            formData.userStatuses.map(
                              (eachStatus, i) =>
                                eachStatus.role === Roles.Mentee && (
                                  <div
                                    key={i}
                                    className={`card profile-card-border m-bottom-27`}
                                  >
                                    <div className="card-body">
                                      <div>
                                        {showMenteeStatusMsg && (
                                          <p
                                            className={`${
                                              menteeStatusMsg
                                                ? "errorText"
                                                : "successText"
                                            } m-top-20`}
                                          >
                                            {menteeStatusMsg
                                              ? translate("unexpected-error")
                                              : translate(
                                                  "update-success-message"
                                                )}
                                          </p>
                                        )}
                                        <div>
                                          <div className="h-45 flex between-center">
                                            <p className="p2-bold m-top-20">
                                              {translate(eachStatus.role)}:
                                            </p>
                                            <p className="p2-bold m-top-20">
                                              <CustomBadge
                                                type={
                                                  AllStates[
                                                    eachStatus.userStatus
                                                  ].type
                                                }
                                                labelName={
                                                  eachStatus.userStatus
                                                }
                                              />
                                            </p>
                                          </div>
                                          {getM_ID("menteeMatchingTandem")}
                                        </div>
                                        {getMenteeAccountPanel(
                                          eachStatus.userStatus
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}

                          {/** Mentor Status Section*/}
                          {isExists(formData.userStatuses) &&
                            formData.userStatuses.map(
                              (eachStatus, i) =>
                                eachStatus.role === Roles.Mentor && (
                                  <div
                                    key={i}
                                    className={`card profile-card-border m-bottom-27`}
                                  >
                                    <div className="card-body">
                                      <div>
                                        <div className="h-45 flex between-center">
                                          <p className="p2-bold m-top-20">
                                            {translate(eachStatus.role)}:
                                          </p>
                                          <p className="p2-bold m-top-20">
                                            <CustomBadge
                                              type={
                                                AllStates[eachStatus.userStatus]
                                                  .type
                                              }
                                              labelName={eachStatus.userStatus}
                                            />
                                          </p>
                                        </div>
                                        {getM_ID("mentorMatchingTandem")}
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}

                          {/** User Details section  */}
                          <div className={`card profile-card-border `}>
                            <div className="card-body">
                              {userAccountDetails()}
                            </div>
                          </div>
                        </div>
                        {/** Right Section*/}
                        <div className="col-lg-8 p-left-63">
                          {isExists(
                            formData.allData["menteeAccountQuestions"]
                          ) && (
                            <>
                              <p className="p2-bold-20-v3 m-bottom-29">
                                {translate("Mentee-user-account")}
                              </p>
                              {getUserQuestions(
                                formData.allData["menteeAccountQuestions"]
                              )}
                            </>
                          )}

                          {isExists(
                            formData.allData["mentorAccountQuestions"]
                          ) && (
                            <>
                              <p className="p2-bold-20-v3 m-bottom-29">
                                {translate("Mentor-user-account")}
                              </p>
                              {getUserQuestions(
                                formData.allData["mentorAccountQuestions"]
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomAccordion>
                <div className="disbale-matching-bottom bg-grey p-all-26">
                    <div className="flex end-center">
                      <CustomButton
                        name={translate("delete-user")}
                        className="custom-btn-primary m-top-29"
                        onClickAction={() => setShowConfirmationPopup(true)}
                      />
                    </div>
                </div>

                {isExists(formData.allData["menteeProfileQuestions"]) && (
                  <div className="m-top-40">
                    <CustomAccordion
                      mixedStyle={true}
                      heading={translate("Mentee")}
                      activeKey={showMenteeInfoAcc}
                      setActiveKey={setMenteeInfoAcc}
                      accTitle={`${formData.fullName}`}
                    >
                      <div className="container-fluid nopadding">
                        <div className="main-body">
                          <div className="row m-top-28">
                            {/* Left Section inside Accordion*/}

                            <div className="col-lg-4">
                              {/** Status Section*/}
                              {isExists(formData.userStatuses) && (
                                <div
                                  className={`card profile-card-border m-bottom-27`}
                                >
                                  <div className="card-body">
                                    {formData.userStatuses.map(
                                      (eachStatus, i) =>
                                        eachStatus.role === Roles.Mentee && (
                                          <div key={i}>
                                            <div>
                                              <div className="h-45 flex between-center">
                                                <p className="p2-bold m-top-20">
                                                  {eachStatus.role}:
                                                </p>
                                                <p className="p2-bold m-top-20">
                                                  <CustomBadge
                                                    type={
                                                      AllStates[
                                                        eachStatus.userStatus
                                                      ].type
                                                    }
                                                    labelName={
                                                      eachStatus.userStatus
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              {getM_ID("menteeMatchingTandem")}
                                            </div>
                                            <div>
                                              <p className="p2-bold m-top-20">
                                                {translate(
                                                  "Last-started-matching-date"
                                                )}
                                                :
                                              </p>
                                              <span className="p-description-16">
                                                {getFormattedDate(
                                                  eachStatus.questionLastPublishDate,
                                                  selectedLanguage
                                                ) || "-"}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="p2-bold m-top-20">
                                                {translate("Registration-date")}
                                                :
                                              </p>
                                              <span className="p-description-16">
                                                {getFormattedDate(
                                                  formData.userCompleteProfile[
                                                    "registrationDate"
                                                  ],
                                                  selectedLanguage
                                                ) || "-"}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                    )}
                                  </div>
                                </div>
                              )}

                              {/** User Details section  */}
                              <div className={`card profile-card-border `}>
                                <div className="card-body">
                                  <div>
                                    <p className="p2-bold m-top-20">
                                      {translate("Program year")}:
                                    </p>
                                    <span className="p-description-16">
                                      {formData.userCompleteProfile[
                                        "cluster"
                                      ] || "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 p-left-63">
                              {getUserQuestions(
                                formData.allData["menteeProfileQuestions"]
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CustomAccordion>{" "}
                  </div>
                )}

                {isExists(formData.allData["mentorProfileQuestions"]) && (
                  <div className="m-top-40">
                    <CustomAccordion
                      accItemClasses="no-bottom-radius"
                      mixedStyle={true}
                      heading={translate("Mentor")}
                      activeKey={showMentorInfoAcc}
                      setActiveKey={setMentorInfoAcc}
                      accTitle={`${formData.fullName}`}
                    >
                      <div className="container-fluid nopadding">
                        <div className="main-body">
                          <div className="row m-top-28">
                            {/* Left Section inside Accordion*/}

                            <div className="col-lg-4">
                              {/** Status Section*/}
                              {isExists(formData.userStatuses) && (
                                <div
                                  className={`card profile-card-border m-bottom-27`}
                                >
                                  <div className="card-body">
                                    {formData.userStatuses.map(
                                      (eachStatus, i) =>
                                        eachStatus.role === Roles.Mentor && (
                                          <div key={i}>
                                            <div>
                                              <div className="h-45 flex between-center">
                                                <p className="p2-bold m-top-20">
                                                  {translate(eachStatus.role)}:
                                                </p>
                                                <p className="p2-bold m-top-20">
                                                  <CustomBadge
                                                    type={
                                                      AllStates[
                                                        eachStatus.userStatus
                                                      ].type
                                                    }
                                                    labelName={
                                                      eachStatus.userStatus
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              <span className="p-description-16">
                                                {getM_ID(
                                                  "mentorMatchingTandem"
                                                )}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="p2-bold m-top-20">
                                                {translate(
                                                  "Last-published-profile-date"
                                                )}
                                                :
                                              </p>
                                              <span className="p-description-16">
                                                {getFormattedDate(
                                                  eachStatus.questionLastPublishDate,
                                                  selectedLanguage
                                                ) || "-"}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="p2-bold m-top-20">
                                                {translate("Registration-date")}
                                                :
                                              </p>
                                              <span className="p-description-16">
                                                {getFormattedDate(
                                                  formData.userCompleteProfile[
                                                    "registrationDate"
                                                  ],
                                                  selectedLanguage
                                                ) || "-"}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-8 p-left-63">
                              {getUserQuestions(
                                formData.allData["mentorProfileQuestions"]
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CustomAccordion>

                    <div className="disbale-matching-bottom bg-grey p-all-26">
                      {/* Toggle Section */}
                      {formData.isMentor && (
                        <div className="flex start-center">
                          <CustomButton
                            className="btn-secondary"
                            btnType="toogle"
                            toogleName={
                              formData.isMatchingdisabled
                                ? translate("enable matching")
                                : translate("disable matching")
                            }
                            onToogleAction={() => setShowPopUp(true)}
                            default={formData.isMatchingdisabled}
                            isDisplay={true}
                          ></CustomButton>
                        </div>
                      )}
                      {/* End of Toggle Section */}
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Notification Section */}
            {formData.showNotifications && (
              <Row className="m-top-56">
                <p className="p2-bold-20-v3 m-bottom-27">
                  {translate("admin-profile-notification-history")}
                </p>
                {isExists(formData.notifications) ? (
                  <CustomTable
                    allItems={formData.notifications}
                    itemsPerPage={25}
                    itemKeys={["mailSubject", "createdDate"]}
                    injectComponets={{
                      createdDate: { type: "formatDateLong" },
                    }}
                    columnNames={[translate("Subject"), translate("Date")]}
                    disabledRowClick={true}
                    language={selectedLanguage}
                    mousePointer={false}
                  />
                ) : (
                  <p className="p2">
                    {translate("admin-profile-no-history-found")}
                  </p>
                )}
              </Row>
            )}
            {/* End of Notification Section */}

            <PopUp
              show={showPopUp}
              acceptName={getAllPopUpTexts().acceptText}
              rejectName={getAllPopUpTexts().rejectText}
              sendResponse={initiateMatching}
              questions={getAllPopUpTexts().questions}
            />
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default UserInformation;
