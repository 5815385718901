const DataPrivacyPolicy =
{
  Title: 'Data privacy policy',
  Content: [
    {
      heading: "1. Data Protection",
      points: [
        <>
          <p>The operators of this website take the protection of your personal data very seriously. We treat your personal data confidentially and according to the legal data protection regulations and this Privacy Policy.</p>
          <p> The use of our website is usually possible without providing personal data. Personal data, such as names, addresses, e-mail addresses or other matching relevant data, are always provided on a voluntary basis if possible. Those data will not be passed on to third parties without your explicit permission.</p>
          <p> However, we would like to point out that data transmissions via internet (e.g. e-mail communication) cannot be entirely secure and may have security vulnerabilities. A complete protection of personal details against unauthorised access by third parties is not possible.</p>
        </>
      ],
    },
    {
      heading: "2. Cookies",
      points: [
        <>
          <p>This website partially uses so-called cookies. Cookies do not cause damages to your computer and do not contain viruses. Cookies allow us to make our offer more user-friendly, more efficient and more secure. Cookies are small text files, stored by your browser on your computer.</p>
          <p>The most cookies that we use are so-called “session cookies”. They will be deleted automatically after the end of your visit. Other cookies remain on your terminal device. Those cookies enable us to recognise your browser on your next visit.</p>
          <p>You can adjust your browser that way in order to be informed when cookies are placed, to individually allow cookies or to generally exclude cookies in certain cases as well as to activate the automatic deletion of cookies when closing the browser. The deactivation of cookies may impair the functionality of this website.
          </p>
        </>
      ],
    },
    {
      heading: "3. Privacy Policy for the use of Google Analytics",
      points: [
        <>
          <p>This website uses Google Analytics, a website analysis service by Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.</p>
          <p>Google Analytics uses so-called „cookies“, text files that are stored on your computer to analyse your use of the website. The information generated by the cookie about your use of this website is usually sent to and stored at a Google server in the U.S.A.</p>
          <p>You find more information concerning the treatment of user data by Google Analytics in Google’s Privacy Policy: <a title="https://support.google.com/analytics/answer/6004245?hl=de" href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" className="hyperlink-active">https://support.google.com/analytics/answer/6004245?hl=de</a></p>
        </>,
      ],
    },
    {
      heading: "4.  Browser Plugin",
      points: [
        <>You may prevent the installation of cookies by adjusting the settings of your browser; however, if you do so, you may be unable to use all features of this website. Further, you may prevent the collection of data generated by cookies (including your IP address) and related to the use of this websites as well as the processing of such data by Google by downloading and installing the plug-in under the following link: <a title="http://tools.google.com/dlpage/gaoptout?hl=en" href="http://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" className="hyperlink-active">http://tools.google.com/dlpage/gaoptout?hl=en</a></>,
      ],
    },
    {
      heading: "5. Objection to Data Collection",
      points: [
        "You may prevent the collection of data by Google Analytics by clicking the following link. Doing so, an Opt-Out-Cookie will be placed which prevents future data collection when visiting our website: Disable Google Analytics",
      ],
    },
    {
      heading: "6. IP-anonymisation",
      points: [
        "We us the function „Activate IP-Anonymisation“. Through this, your IP-address will be shortened priory within the member states of the European Union or other contractual states of the Treaty on the European Economic Area. Only in exceptional cases your complete IP-address will be transmitted to a server in the U.S.A. and shortened there. Commissioned by the operators of this website, Google will use this information to evaluate your use of the website, to compile reports on website activities for website operators and to provide other services related to website and internet activities. Google will not merged your IP address with any other data held by Google.",
      ],
    },
    {
      heading: "7. Privacy Policy for the use of WordPress Stats",
      points: [
        <>
          <p>This website uses the WordPress Tool Stats to statistically evaluate visitor access. The tool is provided by Automattic Inc., 60 29th Street #343, San Francisco, CA 94110-4929, USA.</p>
          <p>WordPress Stats uses so-called cookies which are stored on your computer and enable the analysis of the use of the website. The information generated by the cookies about the use of our website is stored on servers in the USA. Your IP address will be anonymised after processing and before storage.</p>
          <p>You can adjust your browser to be informed about the placement of cookies and to individually accept, to individually or generally exclude cookies as well as to activate automatic deletion of cookies when closing the browser. On deactivation of cookies the functionality of the website might be impaired.</p>
          <p>You may object to the collection and use of your date in the future by clicking the below link to place an opt-out-cookies in your browser: <a title="https://www.quantcast.com/opt-out/" href="https://www.quantcast.com/opt-out/" target="_blank" className="hyperlink-active">https://www.quantcast.com/opt-out/</a></p>
          <p>When you delete the cookies from your computer, you need to place the opt-out-cookies again.</p>
        </>
      ],
    },
    {
      heading: "8. SSL Encryption",
      points: [
        <>
          <p>For security reasons and to protect the transmission of confidential data, such as enquiries you sent to us as the provider, this website uses an SSL encryption. You recognise an encrypted transmission by the change of the address line of the browser from „http://“ to „https://“ and by the lock-symbol in your browser address line.</p>
          <p>If the SSL encryption is active, the data you have transmitted to us cannot be accessed by third parties.</p>
        </>
      ],
    },
    {
      heading: "9. Legal Disclaimer",
      points: [
        <>
          <p>As service providers, we are liable for own contents of these websites according to Sec. 7, paragraph 1 German Telemedia Act (TMG). However, according to Sec. 8 to 10 German Telemedia Act (TMG), service providers are not obligated to permanently monitor submitted or stored information or to search for evidences that indicate illegal activities.</p>
          <p>Legal obligations to removing information or to blocking the use of information remain unchallenged. In this case, liability is only possible at the time of knowledge about a specific violation of law. Illegal contents will be removed immediately at the time we get knowledge of them.</p>
        </>
      ],
    },
    {
      heading: "9.1. Liability for Links",
      isSmall: true,
      points: [
        <>
          <p>Our offer includes links to external third party websites. We have no influence on the contents of those websites, therefore we cannot guarantee for those contents. Providers or administrators of linked websites are always responsible for their own contents.</p>
          <p>The linked websites had been checked for possible violations of law at the time of the establishment of the link. Illegal contents were not detected at the time of the linking. A permanent monitoring of the contents of linked websites cannot be imposed without reasonable indications that there has been a violation of law. Illegal links will be removed immediately at the time we get knowledge of them.</p>
        </>
      ],
    },
    {
      heading: "9.2. Copyright",
      isSmall: true,
      points: [
        <>
          <p>Contents and compilations published on these websites by the providers are subject to German copyright laws. Reproduction, editing, distribution as well as the use of any kind outside the scope of the copyright law require a written permission of the author or originator. Downloads and copies of these websites are permitted for private use only.</p>
          <p>The commercial use of our contents without permission of the originator is prohibited.</p>
          <p>Copyright laws of third parties are respected as long as the contents on these websites do not originate from the provider. Contributions of third parties on this site are indicated as such. However, if you notice any violations of copyright law, please inform us. Such contents will be removed immediately.</p>
          <p>Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG:</p>
          <p>The European Commission provides a platform for online dispute resolution, which you can find at <a title="https://ec.europa.eu/consumers/odr" href="https://ec.europa.eu/consumers/odr" target="_blank" className="hyperlink-active">https://ec.europa.eu/consumers/odr</a>. We are neither obliged nor willing to participate in a dispute settlement procedure before a consumer arbitration board.</p>
        </>,
      ],
    },
    {
      heading: "9.3 Change of our data protection regulations",
      isSmall: true,
      points: [
        "We reserve the right to adapt this data protection declaration so that it always complies with current legal requirements or to implement changes to our services in the data protection declaration, e.g. when introducing new services. The new data protection declaration will then apply to your next visit.",
      ],
    },
    {
      heading: "9.4 Questions to the data protection officer",
      isSmall: true,
      points: [
        <>If you have any questions about data protection, please send us an e-mail or contact the person responsible for data protection at MentorMe directly: Person responsible at MentorMe gGmbH (limited liability) within the meaning of data protection laws, in particular the EU General Data Protection Regulation (GDPR) , is: Karin Heinzl, <a title="heinzl@mentorme-ngo.org" href="mailto:heinzl@mentorme-ngo.org" className="hyperlink-active">heinzl@mentorme-ngo.org</a>. <a title="0049.176.64949316" href="tel:+4917664949316" className="hyperlink-active">0049.176.64949316</a></>,
      ],
    },
    {
      heading: "9.5 Right of Withdrawal",
      isSmall: true,
      points: [
        <p>(A consumer is any natural person who concludes a legal transaction for purposes that are predominantly neither commercial nor their independent professional activity.)</p>,
        <>
          <p>You have the right to cancel this contract within fourteen days without giving any reason. The cancellation period is fourteen days from the day the contract is concluded.</p>
          <p>In order to exercise your right of withdrawal, you must inform us (MentorMe gGmbH, Prenzlauer Allee 186, 10405 Berlin, Germany, telephone number: <a href="tel:+4917664949316" title="004917664949316" className="hyperlink-active">004917664949316</a>, email address: <a className="hyperlink-active" href="mailto:heinzl@mentorme-ngo.org">heinzl@mentorme-ngo.org</a>) by means of a clear declaration (e.g. by post sent letter, e-mail) of your decision to withdraw from this contract. You can use the attached model withdrawal form for this purpose, but this is not mandatory.</p>
          <p>To meet the cancellation deadline, it is sufficient for you to send your notification of exercising your right of cancellation before the cancellation period has expired.</p>
        </>
      ],
    },
    {
      heading: "9.6 Consequences of the withdrawal",
      isSmall: true,
      points: [
        <>
          <p>If you withdraw from this contract, we will have given you all payments that we have received from you, including delivery costs (with the exception of the additional costs that result from choosing a different type of delivery than the cheapest standard delivery offered by us have), to be repaid immediately and at the latest within fourteen days from the day on which we received notification of your cancellation of this contract. For this repayment, we will use the same means of payment that you used for the original transaction, unless something else was expressly agreed with you; In no case will you be charged any fees for this repayment.</p>
          <p>If you have requested that the services should begin during the cancellation period, you have to pay us a reasonable amount, which corresponds to the proportion of the services already provided up to the point in time at which you informed us of the exercise of the right of cancellation with regard to this contract Compared to the total scope of the services provided for in the contract.</p>
        </>
      ],
    },
    {
      heading: "9.7 Example withdrawal form",
      isSmall: true,
      points: [
        "If you want to cancel the contract, please fill out this form and send it back:",
        <ul>
          <li>To: MentorMe gGmbH, Prenzlauer Allee 186, 10405 Berlin, Germany, telephone number: <a title="004917664949316" href="tel:+4917664949316" className="hyperlink-active">004917664949316</a>, email address: <a title="heinzl@mentorme-ngo.org" href="mailto:heinzl@mentorme-ngo.org" className="hyperlink-active">heinzl@mentorme-ngo.org</a> </li>
          <li>I / we (*) hereby revoke the contract concluded by me / us (*) for the purchase of the following goods (*) / the provision of the following service (*)</li>
          <li>Ordered on (*) / received on (*)</li>
          <li>Name of the consumer (s)</li>
          <li>Address of the consumer (s)</li>
          <li>Signature of the consumer (s) (only if this is communicated on paper)</li>
          <li>Date</li>
        </ul>
      ],
    },
  ]
}

export default DataPrivacyPolicy;
