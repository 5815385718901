import React  from "react";
import { useTranslation } from "react-i18next";
import { getColor } from "../../../Helpers/Utils";

const CustomBadge = (props) => {
const { t: translate } = useTranslation();
const {
     type,
     labelName,
     customClasses = "" /** extra classes prop. default is set to empty */
    } = props;

const getCustomStyles =()=> {
    let color = "";
    let bg = "";
    switch(type) {
        case "success": color=`${getColor("--success-color")}`; bg=`${getColor("--success-bg-color")}`; break;
        case "error": color=`${getColor("--error-color")}`; bg=`${getColor("--error-bg-color")}`; break;
        case "warning": color=`${getColor("--warning-color")}`; bg=`${getColor("--warning-bg-color")}`; break;
        case "info": color=`${getColor("--secondary-text-color")}`; bg=`${getColor("--white")}`; break;
        default: break;
    }
    return {color:color, backgroundColor: bg , border: `1px solid ${color}`};
}

 return (
    <span className={`badge badge-pill ${customClasses}`} style={getCustomStyles()}>{translate(labelName)}</span>
 );

}

export default CustomBadge;