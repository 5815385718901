import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { Language } from './Configurations/Constants';

// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";


//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator', 'cookie', 'path', 'subdomain'],
  // caches: ['cookie']
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: Language.English.Code,
    supportedLngs: [Language.English.Code, Language.Deutsch.Code],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;