import { InAppImages, Language, Pages } from "../../Configurations/Constants";
import { useTranslation } from "react-i18next";

const NavBar = (props) => {
  const { i18n } = useTranslation();
  const { children, position, sticky, customStyles, ...rest } = props;
  let type = props.type || "default";
  let itemsPosition = props.position || "start-center";

  const toogleLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div data-testid='navbar' className={`nav-bar ${customStyles}`} {...rest}>
    <div className="container w-max app-standard-gap">     
      {
        type === "default" && (
          <div className="flex lg-row-sm-col">
            <div className="occupy-1 flex logo">
               <img src={InAppImages.logo} alt="" onClick={() => (window.location = Pages.Home.path)}/>
            </div>
            <div className="flex">
            <ul className="nav-links end-center">
                  <li id="eng-lang">
                    <p
                      className={
                        i18n.language === Language.English.Code ? "active" : ""
                      }
                      onClick={() => toogleLanguage(Language.English.Code)}
                    >
                      {Language.English.Name}
                    </p>
                  </li>
                  <li id="deutch-lang">
                    <p
                      className={
                        i18n.language === Language.Deutsch.Code ? "active" : ""
                      }
                      onClick={() => toogleLanguage(Language.Deutsch.Code)}
                    >
                      {Language.Deutsch.DisplayName}
                    </p>
                  </li>
                  {children}
                </ul>
            </div>
          </div>
        )
      }

      {type !== "default" && (
        <div className="flex occupy-1">
          <ul className={`occupy-1 lg-row-sm-col mobile-gap ${itemsPosition} mobile-center-center`}>
            {children}
          </ul>
        </div>
        )}
        </div>
      </div>
  );
};

export default NavBar;
