import React from "react";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import { Row, Col, Card } from 'react-bootstrap';
import CustomBadge from "../../CustomControls/Badge/CustomBadge";
import { InAppImages } from "../../../Configurations/Constants";
import { CommandType } from "../../../Configurations/Constants";

const ScoreCard = (props) => {
  const {
    headerName,
    status = "success",
    statusName,
    userImage,
    username,
    points,
    pointsLabel,
    acceptBtnName,
    declineBtnName,
    commonAction,
    infoBtnName,
    infoBtnAction,
    MATCHED,
    customClasses,
    matchedName,
    isManualMatching = false,
    acceptPayload,
    declinePayload,
    children
  } = props;

  const handleCommonAction = (command) => {
    if (command === CommandType.Accept) {
      commonAction(command, acceptPayload);
    }
    else if (command === CommandType.Decline) {
      commonAction(command, declinePayload);
    }
  }

  const handleInfoBtnAction = () => {
    infoBtnAction();
  }

  return (
    <Col xs={12} md={6} lg={3} className={`w-321 ${customClasses || ""}`}>
      <Card className="score-card-border">
        <div className="flex h-63 bg-grey p-left-20 p-right-20">
        <div className=" occupy-1 start-center">
            <p className="p2-bold-v2">{headerName}</p>
          </div>
          <div className="occupy-1 end-center">
            <CustomBadge type={status} labelName={statusName} />
          </div>
        </div>
        <Card.Body className="p-top-0">
          <Row>
            <Col className="occupy-1 column center-center">
              <div
                style={{ width: "102px", height: "102px" }}
                className="m-top-30 m-bottom-10">
                <img
                  id="profileImg"
                  src={userImage || InAppImages.profileIcon}
                  className="rounded-circle img-objectfit"
                  style={{ width: "102px", height: "102px" }}
                  alt=""
                />
              </div>
              <p className={`p-30 ${(username && username.length > 16) && "w-100-p"}`}>{username}</p>
              {!isManualMatching && 
              <div className="m-top-33">
                <div className="flex center-center score-card-text-border">
                  <p className="p-25-light  m-bottom-5">{points} <span className="p3-medium">{pointsLabel}</span></p>
                </div>
                <div className="flex center-center">
                  <p className="p3-medium m-top-8">Matching score</p>
                </div>
              </div>             
              }
              <CustomButton name={infoBtnName} onClickAction={handleInfoBtnAction} className="custom-btn-primary info w-max m-top-18" />
            </Col>
          </Row>
        </Card.Body>
        {MATCHED && <div className="flex center-center w-max approve-label">
          <p>{matchedName}</p>
        </div>}
      </Card>
      {!MATCHED &&
        <>
          <CustomButton name={acceptBtnName} onClickAction={() => handleCommonAction(CommandType.Accept)} className="custom-btn-primary w-max m-top-10"/>
          <CustomButton name={declineBtnName} onClickAction={() => handleCommonAction(CommandType.Decline)} className="custom-btn-secondary w-max m-top-10"/>
        </>
      }
      {children}
    </Col>
  );
}

export default ScoreCard;