import React from "react";
import { useSelector } from 'react-redux';
import { InteractionType } from '@azure/msal-browser';
import { NavigationBar, Footer, Loader,SecondLoader, LinkButton, ContentWrapper } from './Common/HelperComponents';
import { loginRequest } from '../Configurations/AzureAuthConfig';
import { MsalProvider, MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SocialMediaLinks, LegalLinks, Roles, Pages } from "../Configurations/Constants";
import { useTranslation } from "react-i18next";
import NavBar from "./NavBar/NavBar";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { getUserRole, isPublicPage } from "../Helpers/Utils";
import CommonRoute from "./Routes/CommonRoute";
import PublicPagesRoute from "./Routes/PublicPagesRoute";

const MentorMe = (props) => {
    const { t: translate } = useTranslation();
    const { user } = useSelector((state) => state.user);
    const location = useLocation();
    const selectedLink = location.pathname;
    const history = useHistory();

    const msalTemplate = () => {
        return (
            <MsalAuthenticationTemplate loadingComponent={() => {
                    return (
                        <Loader isDisplay={true} />
                    )
                }}
                errorComponent={
                    ({ error }) => {
                        return <div></div>
                }}
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest} >
                <>
                <NavigationBar redirectProfile={() => { history.push(Pages.UserProfile.path); }} />
                {!isPublicPage(selectedLink) &&
                 <NavBar type="custom" position="start-center" customStyles="border-1 no-box-shadow">
                    {user && getUserRole(user) === Roles.Admin ?
                        <>
                            <LinkButton action={() => { history.push(Pages.AdminDashBoard.path); }} name={translate('matching-tandems')} active={selectedLink === Pages.Home.path || selectedLink === Pages.AdminDashBoard.path}></LinkButton>
                            <LinkButton action={() => { history.push(Pages.AdminMentees.path); }} name={translate('mentees')} active={selectedLink === Pages.AdminMentees.path}></LinkButton>
                            <LinkButton action={() => { history.push(Pages.AdminMentors.path); }} name={translate('mentors')} active={selectedLink === Pages.AdminMentors.path}></LinkButton>
                            <LinkButton action={() => { history.push(Pages.AdminUserInformation.path); }} name={translate('admin-user-info-title')} active={selectedLink === Pages.AdminUserInformation.path}></LinkButton>
                            <LinkButton action={() => { history.push(Pages.AdminProgramYear.path); }} name={translate('program-year')} active={selectedLink === Pages.AdminProgramYear.path}></LinkButton>
                        </>
                        :
                        <>
                            <LinkButton action={() => { history.push(Pages.Dashboard.path); }} name={translate('Dashboard')} active={selectedLink === Pages.Dashboard.path || selectedLink === Pages.Home.path}></LinkButton>
                            {user && Array.isArray(user.userRole) && user.userRole.find(i => i.name === Roles.Mentee) && <LinkButton action={() => { history.push(Pages.QuestionnaireMentee.path); }} name={translate('Questionnaire mentee')} active={selectedLink === Pages.QuestionnaireMentee.path}></LinkButton>}
                            {user && Array.isArray(user.userRole) && user.userRole.find(i => i.name === Roles.Mentor) && <LinkButton action={() => { history.push(Pages.QuestionnaireMentor.path); }} name={translate('Questionnaire mentor')} active={selectedLink === Pages.QuestionnaireMentor.path}></LinkButton>}
                        </>
                    }
                </NavBar>}
                </>
                <ContentWrapper>
                    <Switch>
                    <Route path={Pages.TermsAndCondition.path} render={() => <PublicPagesRoute page={Pages.TermsAndCondition} isPublic={false}/>}/>
                    <Route path={Pages.DataPrivacyPolicy.path} render={() => <PublicPagesRoute page={Pages.DataPrivacyPolicy} isPublic={false} />}/>
                    <Route path={Pages.LegalInfo.path} render={() => <PublicPagesRoute page={Pages.LegalInfo} isPublic={false} />}/>
                    <Route path="/" component={CommonRoute} />
                    </Switch>
                </ContentWrapper>
                <Footer SocialMediaLinks={SocialMediaLinks} LegalLinks={LegalLinks} /> 
                <Loader isDisplay={false} /> 
                <SecondLoader isDisplay={false} /> 

            </MsalAuthenticationTemplate >
        )
    }

    return (
        <MsalProvider instance={props.instance}>
            
            <AuthenticatedTemplate>
                {msalTemplate()}
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
            <Switch>
                <Route path={Pages.TermsAndCondition.path} render={() => <PublicPagesRoute page={Pages.TermsAndCondition} isPublic={true}/>}/>
                <Route path={Pages.DataPrivacyPolicy.path} render={() => <PublicPagesRoute page={Pages.DataPrivacyPolicy} isPublic={true} />}/>
                <Route path={Pages.LegalInfo.path} render={() => <PublicPagesRoute page={Pages.LegalInfo} isPublic={true} />}/>
                <Route path="/*" render={() => msalTemplate() }/>
            </Switch>
            </UnauthenticatedTemplate>
            
        </MsalProvider >
    );
}
export default MentorMe;