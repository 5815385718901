import { createSlice } from '@reduxjs/toolkit'
import { registerUser, updateRole, getUserDetails } from '../Services/Login/LoginService';
import { apiEndpoints } from '../Configurations/Constants';
import { isExists } from '../Helpers/Utils';
const slice = createSlice({
    name: 'user',
    initialState: {
        user: null
    },
    reducers: {
        updateProfileImg: (state, action) => {
            state.user.profileImage = action.payload;
        },
        setUserDetails: (state, action) => {
            state.user = action.payload;
        }
    },
});

export default slice.reducer;
// Actions
const { updateProfileImg, setUserDetails } = slice.actions
export const register = (account, role) => async dispatch => {
    return new Promise((resolve, reject) => {
        try {
            registerUser(apiEndpoints.Account.RegisterUser, account, role)
                .then(() => {
                    updateRole(apiEndpoints.Account.SignIn, account, role)
                        .then(() => {
                            getUserDetails(account.username)
                                .then((res) => {
                                    if (res) {
                                        dispatch(setUserDetails(res));
                                        resolve(true);
                                    }
                                })
                                .catch(error => { reject(error); });
                        })
                        .catch(error => { reject(error); });
                })
                .catch(error => { reject(error); });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const registerRole = (account, role) => async dispatch => {
    return new Promise((resolve, reject) => {
        try {
            updateRole(apiEndpoints.Account.SignIn, account, role)
                .then(() => {
                    getUserDetails(account.username)
                        .then((res) => {
                            if (res) {
                                dispatch(setUserDetails(res));
                                resolve(true);
                            }
                        })
                        .catch(error => reject(error));
                })
                .catch(error => reject(error));
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getUserInfo = (email) => async dispatch => {
    return new Promise((resolve, reject) => {
        try {
            getUserDetails(email)
                .then((res) => {
                    dispatch(setUserDetails(res));
                    resolve(true);
                })
                .catch(
                    error => {
                        reject(error);
                    }
                )
        }
        catch (error) {
            reject(error);
        }
    });
}
export const updateUserDetails = (userDetails) => async dispatch => {
    if (isExists(userDetails)) {
        dispatch(setUserDetails(userDetails));
    }
}
export const updateProfileImgPath = (imgUrl) => async dispatch => {
    dispatch(updateProfileImg(imgUrl));
}
