import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import CustomAccordion from "../../CustomControls/CustomAccordion/CustomAccordion";
import UserCard from "../../UserCard/UserCard";
import Constants, { Roles } from "../../../Configurations/Constants";
import { useTranslation } from "react-i18next";
import { declineProposal, acceptProposal } from "../../../Services/Admin/AdminService";
import { releaseMatchingTandemManual } from '../../../Services/Admin/AdminService';
import { getFormattedDateShort, isExists, space } from "../../../Helpers/Utils";
import PopUp from '../../../Components/CustomControls/PopUp/PopUp';
import { useErrorHandler } from 'react-error-boundary';

const TandemDetails = (props) => {
    const { TandemData, ProposalStates, setMessage, reloadTandemDetails } = props;
    const { t: translate, i18n } = useTranslation();
    const handleError = useErrorHandler();
    const selectedLanguage =
        i18n.language === Constants.Language.Deutsch.Code
            ? Constants.Language.Deutsch.Name
            : Constants.Language.English.Name;

    const [activeKey, setActiveKey] = useState('0'); //set 0 or null, 0 for opened
    const [formData, setFormData] = useState({
        menteeStatusDefault: null,
        mentorStatusDefault: null,
        menteeStatus: null,
        mentorStatus: null,
        TandemData: null,
        Language: selectedLanguage
    });
    const [isAccepted, setIsAccepted] = useState(false);
    useEffect(() => {
        if (TandemData !== formData.TandemData
            || formData.Language !== selectedLanguage) {
            fillData();
        }
    }, [TandemData, selectedLanguage])

    const [proposalsForDropdown, setProposalsToDisplay] = useState([]);
    const fillData = () => {
        let proposals = ProposalStates.filter(item => item.name !== Constants.TandemStates.CLOSED);
        proposals = proposals.filter(item => item.name !== Constants.TandemStates.NOREACTION);
        console.log(proposals);
        setProposalsToDisplay(proposals);
        const menteeStatus = getDefaultProposal(ProposalStates, TandemData.matchingTandemMenteeProposal.status, TandemData.matchingTandemMenteeProposal.proposalId)[0];
        const mentorStatus = getDefaultProposal(ProposalStates, TandemData.matchingTandemMentorProposal.status, TandemData.matchingTandemMentorProposal.proposalId)[0];
        setFormData({
            ...formData,
            menteeStatusDefault: { ...menteeStatus },
            mentorStatusDefault: { ...mentorStatus },
            menteeStatus,
            mentorStatus,
            TandemData,
            Language: selectedLanguage
        });
        setActiveKey('0');
        if (menteeStatus) {
            setIsAccepted(menteeStatus.value === Constants.ProposalStates.ACCEPTED);
        }
    }

    const buildSelectedStatusOptions = (options, proposalId) => {
        let items = [];
        if (isExists(options)) {
            options.forEach((option) => {
                const modifiedOption = {
                    option: {
                        proposalId,
                        proposalState: option["name"],
                        comment: ""
                    },
                    label: translate(option["name"]),
                    value: option["name"]
                }
                items.push(modifiedOption);
            });
        }
        return items;
    }

    const getDefaultProposal = (options, selected, proposalId) => {
        let items = [];
        if (isExists(options)) {
            const selectedItem = options.find((option) => option.name === selected);
            if (isExists(selectedItem)) {
                const modifiedOption = {
                    option: {
                        proposalId,
                        proposalState: selectedItem["name"],
                        comment: ""
                    },
                    label: translate(selectedItem["name"]),
                    value: selectedItem["name"]
                }
                items.push(modifiedOption);
            }
        }
        return items;
    }

    const handleReleaseTandem = async () => {
        if (isExists(formData.menteeStatus)
            && isExists(formData.mentorStatus)) {
            const requestObj = [
                {
                    ...formData.menteeStatus.option,
                    proposalState: Constants.ProposalStates.CLOSED
                },
                {
                    ...formData.mentorStatus.option,
                    proposalState: Constants.ProposalStates.CLOSED
                }];
            await releaseMatchingTandemManual(requestObj)
                .then(res => {
                    if (res && res.isSuccess) {
                        setMessage(true, translate("update-matching-tandem-confirm-save-confirmed-Message"), Constants.MessageTypes.success);
                        reloadTandemDetails();
                    }
                })
                .catch(error => { handleError(error); });
        }
    }

    const isValidForUpdate = () => {
        let result = {
            isValid: false,
            isMentorStatusChanged: false,
            isMenteeStatusChanged: false
        };
        if (formData.menteeStatus.value && formData.mentorStatus.value) {
            const isMenteeStatusChanged = (formData.menteeStatus.value !== formData.menteeStatusDefault.value);
            const isMentorStatusChanged = (formData.mentorStatus.value !== formData.mentorStatusDefault.value);
            result = {
                ...result,
                isValid: (isMenteeStatusChanged || isMentorStatusChanged),
                isMentorStatusChanged,
                isMenteeStatusChanged
            };
        }
        return result;
    }
    const acceptProposalAPI = async (acceptRequest) => {
        await acceptProposal(acceptRequest)
            .then(res => {
                if (res && res.isSuccess) {
                    setMessage(true, translate("update-matching-tandem-confirm-save-confirmed-Message"), Constants.MessageTypes.success);
                    reloadTandemDetails();
                }
            }).catch(error => { handleError(error); });
    }
    const declineProposalAPI = async (declineRequest) => {
        await declineProposal(declineRequest)
            .then(res => {
                if (res && res.isSuccess) {
                    setMessage(true, translate("update-matching-tandem-confirm-save-confirmed-Message"), Constants.MessageTypes.success);
                    reloadTandemDetails();
                }
            })
            .catch(error => { handleError(error); });
    }

    const acceptAndDeclineAPI = async (acceptRequest, declineRequest) => {
        await acceptProposal(acceptRequest)
            .then(res => {
                if (res && res.isSuccess) {
                    declineProposal(declineRequest)
                        .then(res => {
                            if (res && res.isSuccess) {
                                setMessage(true, translate("update-matching-tandem-confirm-save-confirmed-Message"), Constants.MessageTypes.success);
                                reloadTandemDetails();
                            }
                        })
                        .catch(error => { handleError(error); });
                }
            }).catch(error => { handleError(error); });

    }
    const onChangeProposal = (val) => {
        const { selectedOptions } = val.selectedData;
        if (selectedOptions) {
            if (val.userRole === Constants.Roles.Mentee) {
                const isAccepted = (selectedOptions.value === Constants.ProposalStates.ACCEPTED);
                setIsAccepted(isAccepted);
                const mentorStatus = isAccepted ? formData.mentorStatus : formData.mentorStatusDefault;
                setFormData({
                    ...formData,
                    menteeStatus: selectedOptions,
                    mentorStatus
                });
            }
            else if (val.userRole === Constants.Roles.Mentor) {
                setFormData({
                    ...formData,
                    mentorStatus: selectedOptions
                });
            }
        }
    }

    const [showConfirmPopupSave, setShowConfirmPopupSave] = useState(false);
    const getReponseFromPopUpSave = (value) => {
        setShowConfirmPopupSave(false);
        if (value) {
            saveTandem();
        }
    }
    const [showConfirmPopupRelease, setShowConfirmPopupRelease] = useState(false);
    const getReponseFromPopUpRelease = (value) => {
        setShowConfirmPopupRelease(false);
        if (value) {
            handleReleaseTandem();
        }
    }

    const handleSaveTandemChanges = () => {
        const validationResult = isValidForUpdate();
        if (validationResult.isValid) {
            setShowConfirmPopupSave(true);
        }
        // else {
        //     setMessage(true, translate("update-matching-tandem-no-change-error"), Constants.MessageTypes.error);
        // }
    }

    const saveTandem = () => {
        const validationResult = isValidForUpdate();
        const matchingTandemId = TandemData.tandemId;
        const menteeEmail = TandemData["matchingTandemMenteeProposal"].email;
        const mentorEmail = TandemData["matchingTandemMentorProposal"].email;
        const updatedMenteeStatus = formData.menteeStatus.value;
        const updatedMentorStatus = formData.mentorStatus.value;
        if (updatedMenteeStatus === Constants.ProposalStates.DECLINED) {
            const proposalId = TandemData["matchingTandemMenteeProposal"].proposalId;
            const declineRequest =
            {
                declineProposal: {
                    proposalId,
                    proposalState: Constants.ProposalStates.DECLINED,
                    comment: "",
                    declineMatchingTandem: {
                        matchingTandemId,
                        tandemState: Constants.ProposalStates.DECLINED
                    }
                },
                menteeEmail,
                mentorEmail,
                role: Roles.Mentee
            }
            declineProposalAPI(declineRequest);
        }
        else if (updatedMenteeStatus === Constants.ProposalStates.ACCEPTED) {
            let isDecline = false;
            const reqMenteeAccept = {
                email: menteeEmail,
                role: Roles.Mentee,
                pairedUserEmail: mentorEmail,
                proposalSaveModel: [
                    {
                        proposalId: TandemData["matchingTandemMenteeProposal"].proposalId,
                        proposalState: Constants.ProposalStates.ACCEPTED,
                        comment: ""
                    }],
                matchingTandemSaveModel: [
                    {
                        matchingTandemId,
                        tandemState: Constants.TandemStates.ACTIVE
                    }
                ]
            };

            let acceptRequest = [reqMenteeAccept];

            if (validationResult.isMentorStatusChanged) {
                //Mentee-Accepted + Mentor Change
                switch (updatedMentorStatus) {
                    case Constants.ProposalStates.ACCEPTED:
                        {
                            let acceptReqMentor = [
                                {
                                    email: mentorEmail,
                                    role: Roles.Mentor,
                                    pairedUserEmail: menteeEmail,
                                    proposalSaveModel: [
                                        {
                                            proposalId: TandemData["matchingTandemMentorProposal"].proposalId,
                                            proposalState: Constants.ProposalStates.ACCEPTED,
                                            comment: ""
                                        }],
                                    matchingTandemSaveModel: [
                                        {
                                            matchingTandemId,
                                            tandemState: Constants.TandemStates.MATCHED
                                        }
                                    ]
                                }];

                            //Check if mentee status was also changed to accepted, if yes, add it else don't
                            if (validationResult.isMenteeStatusChanged) {
                                acceptReqMentor.push({
                                    ...reqMenteeAccept,
                                    matchingTandemSaveModel: [{
                                        matchingTandemId,
                                        tandemState: Constants.TandemStates.MATCHED
                                    }]
                                })
                            }
                            acceptRequest = acceptReqMentor;
                            break;
                        }
                    case Constants.ProposalStates.DECLINED:
                        {
                            isDecline = true;
                            break;
                        }
                    default:
                        break;
                }
            }

            //Send requests to API
            if (isDecline) {
                const declineRequest =
                {
                    declineProposal: {
                        proposalId: TandemData["matchingTandemMentorProposal"].proposalId,
                        proposalState: Constants.ProposalStates.DECLINED,
                        comment: "",
                        declineMatchingTandem: {
                            matchingTandemId,
                            tandemState: Constants.ProposalStates.DECLINED
                        }
                    },
                    menteeEmail,
                    mentorEmail,
                    role: Roles.Mentor
                }
                acceptAndDeclineAPI(acceptRequest, declineRequest);
            }
            else {
                acceptProposalAPI(acceptRequest);
            }
        }
    }

    if (isExists(TandemData)
        && ((formData.mentorStatus && formData.mentorStatus) || TandemData.tandemStatus === Constants.TandemStates.CLOSED)) {
        const isSaveChangesDisabled = !(TandemData.tandemStatus === Constants.TandemStates.ACTIVE);
        const isReleaseTandemDisabled = !(TandemData.tandemStatus === Constants.TandemStates.ACTIVE || TandemData.tandemStatus === Constants.TandemStates.MATCHED);
        const isDropDownOrBadgeMentee = (TandemData.tandemStatus === Constants.TandemStates.ACTIVE && formData.menteeStatusDefault.value == Constants.ProposalStates.PROPOSED) ? "Dropdown" : "Badge";
        const isDropDownOrBadgeMentor = (TandemData.tandemStatus === Constants.TandemStates.ACTIVE && isAccepted) ? "Dropdown" : "Badge";

        return (
            <div className="m-top-60">
                <PopUp show={showConfirmPopupSave}
                    acceptName={translate('save-changes')}
                    rejectName={translate('Cancel')}
                    sendResponse={getReponseFromPopUpSave}
                    questions={[translate('update-matching-tandem-confirm-save-changes')]} />
                <PopUp show={showConfirmPopupRelease}
                    acceptName={translate('release-matching-tandem')}
                    rejectName={translate('Cancel')}
                    sendResponse={getReponseFromPopUpRelease}
                    questions={[translate("release-matching-tandem-confirm1"), translate("release-matching-tandem-confirm2")]} />
                <CustomAccordion
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    accTitle={`MT_ID: #${TandemData.tandemId}${space(6)}${TandemData.matchingTandemMenteeProposal.lastName} / ${TandemData.matchingTandemMentorProposal.lastName}`}>
                    {TandemData === formData.TandemData &&
                        <Row>
                            <Col md={6} lg={6} xs={12}>
                                <UserCard
                                    userRole={Constants.Roles.Mentee}
                                    userPID={`${translate('filter-column-pid')}: #${TandemData.matchingTandemMenteeProposal.proposalId}`}
                                    userName={`${TandemData.matchingTandemMenteeProposal.firstName} ${TandemData.matchingTandemMenteeProposal.lastName}`}
                                    userEmail = {`${TandemData.matchingTandemMenteeProposal.email}`}
                                    userProposalDate={getFormattedDateShort(TandemData.matchingTandemMenteeProposal.createdDate, selectedLanguage, true)}
                                    userDefaultProposalData={formData.menteeStatus}
                                    userProposalData={buildSelectedStatusOptions(proposalsForDropdown, TandemData.matchingTandemMenteeProposal.proposalId)}
                                    userInformation={isExists(TandemData.matchingTandemMenteeProposal.comment) ? TandemData.matchingTandemMenteeProposal.comment : "-"}
                                    userInformationDeclineReason={isExists(TandemData.matchingTandemMenteeProposal.declineReasonText) ? TandemData.matchingTandemMenteeProposal.declineReasonText : "-"}
                                    fetchSelectedData={onChangeProposal}
                                    statusPattern={isDropDownOrBadgeMentee} />
                            </Col>
                            <Col md={6} lg={6} xs={12}>
                                <UserCard
                                    userRole={Constants.Roles.Mentor}
                                    userPID={`${translate('filter-column-pid')}: #${TandemData.matchingTandemMentorProposal.proposalId}`}
                                    userName={`${TandemData.matchingTandemMentorProposal.firstName} ${TandemData.matchingTandemMentorProposal.lastName}`}
                                    userEmail = {`${TandemData.matchingTandemMentorProposal.email}`}
                                    userProposalDate={getFormattedDateShort(TandemData.matchingTandemMentorProposal.createdDate, selectedLanguage, true)}
                                    userDefaultProposalData={formData.mentorStatus}
                                    userProposalData={buildSelectedStatusOptions(proposalsForDropdown, TandemData.matchingTandemMentorProposal.proposalId)}
                                    userInformation={isExists(TandemData.matchingTandemMentorProposal.comment) ? TandemData.matchingTandemMentorProposal.comment : "-"}
                                    userInformationDeclineReason={isExists(TandemData.matchingTandemMentorProposal.declineReasonText) ? TandemData.matchingTandemMentorProposal.declineReasonText : "-"}
                                    fetchSelectedData={onChangeProposal}
                                    statusPattern={isDropDownOrBadgeMentor} />
                            </Col>
                        </Row>
                    }
                    <Row className="m-top-40 m-bottom-40 flex end-center">
                        <Col sm={12} md={6} lg={6} className="flex end-center">
                            <CustomButton
                                name={translate('release-matching-tandem')}
                                className="custom-btn-secondary w-263 m-right-17"
                                onClickAction={() => { setShowConfirmPopupRelease(true); }}
                                className={(isReleaseTandemDisabled ? `custom-btn-primary-disabled` : `custom-btn-primary`) + ` w-263 m-right-17`}
                                disable={isReleaseTandemDisabled} />
                            <CustomButton
                                name={translate('save-changes')}
                                onClickAction={handleSaveTandemChanges}
                                className={(isSaveChangesDisabled ? `custom-btn-primary-disabled` : `custom-btn-primary`) + ` w-263`}
                                disable={isSaveChangesDisabled} />
                        </Col>
                    </Row>
                </CustomAccordion>
            </div>
        );
    }
    else
        return null;
};
export default TandemDetails;
