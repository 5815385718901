const TermsAndCondition =
{
  Title: 'Terms and conditions',
  Content:
    [{
      heading: "§ 1 Validity, definition of terms",
      points: [
        <>(1) MentorMe gGmbH, Prenzlauer Allee 186, 10405 Berlin, Germany (hereinafter: "we") operates a matching platform for professional mentoring purposes as a service on the website <a title="https://mentorme-ngo.org" href="https://mentorme-ngo.org" target="_blank" className="hyperlink-active">https://mentorme-ngo.org</a>. The following general terms and conditions apply to all services between us and our customers (hereinafter: "customer" or "you") in the version valid at the time of the order, unless otherwise expressly agreed.</>,
        `(2) “Consumer” within the meaning of these terms and conditions is any natural person who concludes a legal transaction for purposes that are predominantly neither commercial nor self-employed. "Entrepreneur" is a natural or legal person or a legal partnership who, when concluding a legal transaction, acts in the exercise of their commercial or independent professional activity, whereby a legal partnership is a partnership that is equipped with the ability to acquire rights and enter into liabilities.`
      ],
    },
    {
      heading: "§ 2 Conclusion of contracts, storage of the contract text",
      points: [
        <>(1) The following provisions on the conclusion of a contract apply to services offered in the name of MentorMe via our online shop at <a title="https://mentorme-ngo.org" href="https://mentorme-ngo.org" target="_blank" className="hyperlink-active">https://mentorme-ngo.org</a> or offered on behalf of third parties for internal mentoring purposes of these third parties.</>,
        `(2) Our product presentations on the Internet are non-binding and not a binding offer to conclude a contract.`,
        `(3) When an order is received in our online shop from customers or when a contract is concluded in writing by companies and organizations, the following rules apply: The customer submits a binding contract offer by following the ordering procedure provided in our online shop or through a company or a matching service acquired by an organization. The order takes place in the following steps:`,
        `(3.1) For individual customers:`,
        <ol>
          <li> Selection of the desired goods, the selected service (s),</li> <br />
          <li> Adding the products by clicking the appropriate button (e.g. "In the shopping cart", "In the shopping bag" or similar),</li><br />
          <li> Checking the information in the shopping cart,</li><br />
          <li> Calling up the order overview by clicking the corresponding button (e.g. "Continue to checkout", "Continue to payment", "To order overview" or similar),</li><br />
          <li> Entering / checking the address and contact details, selecting the payment method, confirming the terms and conditions and cancellation policy,</li><br />
          <li> Complete the order by clicking the "Buy now" button. This represents your binding order.</li><br />
          <li> The contract is concluded when you receive an order confirmation from us at the specified email address within three working days.</li><br />
          <li> Before ordering, the contract data can be printed out or electronically saved using the browser's print function. The processing of the order and the transmission of all information required in connection with the conclusion of the contract, in particular the order data, the terms and conditions and the cancellation policy, is carried out by email after you have placed the order, in some cases automatically. We do not save the contract text after the contract is concluded.</li><br />
          <li> Input errors can be corrected using the usual keyboard, mouse and browser functions (e.g. the browser's »back button«). They can also be corrected by canceling the order process prematurely, closing the browser window and repeating the process.</li><br />
          <li> The processing of the order and the transmission of all information required in connection with the conclusion of the contract is partly automated by e-mail. You must therefore ensure that the e-mail address you have stored with us is correct, that the receipt of e-mails is technically ensured and, in particular, is not prevented by SPAM filters.</li>
        </ol>,
        `(3.2) For employees of customers, they are companies or organizations:`,
        <ol>
          <li> Selection of the desired goods, the selected service (s) by the company or the organizations</li><br />
          <li> The contract is concluded when an order confirmation is sent to the specified e-mail address of the company or organization within 30 working days or a contract is signed by both parties.</li>
        </ol>,
        `(4) If the contract is concluded, the contract is concluded with MentorMe gGmbH, Prenzlauer Allee 186, 10405 Berlin, Germany.`
      ],
    },
    {
      heading: "§ 3 Subject matter of the contract and essential characteristics of the products",
      points: [
        `(1) The subject of the contract in our online shop is:`,
        <ol type="a">
          <li> Sales of services. The specific services offered can be found on our article pages and our website or the website of the company or organization that purchased our matching services.</li><br />
          <li> The provision of services. The specific services offered can be found on our article pages or website. Beneficiaries of our services from companies or organizations can also obtain the services offered from the websites of the companies or organizations.</li>
        </ol>,
        `(2) The essential characteristics of the goods and services can be found in the item description or on the websites of the companies or organizations that have purchased a matching service from us.`,
        `(3) For the sale of digital services, the restrictions evident from the service description or otherwise resulting from the circumstances apply, in particular to hardware and / or software requirements for the target environment. Unless expressly agreed otherwise, the subject of the contract is only the private and commercial use of the services without the right to resell or sublicense.`
      ],
    },
    {
      heading: "§ 4 Prices, shipping costs and delivery",
      points: [
        `(1) The prices listed in the respective offers as well as the shipping costs are total prices and include all price components including all applicable taxes.`,
        `(2) The respective purchase price must be paid prior to delivery of the service (advance payment), unless we expressly offer purchase on account. The payment methods available to our individual customers are shown under a correspondingly labeled button in the online shop or in the respective offer. Unless otherwise stated for the individual payment methods, payment claims are due for payment immediately.`,
        `(3) In addition to the prices quoted, shipping costs may apply for the delivery of products and services, unless the respective article is shown as free of shipping costs. The shipping costs are clearly communicated to you on the offers, possibly in the shopping cart system and on the order overview.`,
        `(4) Unless clearly stated otherwise in the service description, all offered services are ready for dispatch immediately (delivery time: max. 3 weeks) after receipt of payment.`,
        `(5) Delivery takes place worldwide.`
      ],
    }, {
      heading: "§ 5 Right of Retention, Retention of Title",
      points: [
        `(1) You can only exercise a right of retention if it concerns claims from the same contractual relationship.`,
        `(2) The service remains our property until the purchase price has been paid in full.`
      ],
    }, {
      heading: "§ 6 Right of withdrawal",
      points: [`As a consumer, you have a right of withdrawal. This is based on our cancellation policy.`],
    }, {
      heading: "§ 7 Liability",
      points: [`(1) Subject to the following exceptions, our liability for breaches of contractual obligations and for tortious acts is limited to intent or gross negligence.`,
        `(2) We have unlimited liability in the event of slight negligence in the event of injury to life, limb, health or in the event of a breach of an essential contractual obligation. If we are in default with the service due to slight negligence, if the service has become impossible or if we have violated an essential contractual obligation, the liability for property and financial damage attributable to this is limited to the foreseeable damage typical for the contract. An essential contractual obligation is one, the fulfillment of which makes the proper execution of the contract possible in the first place, the violation of which endangers the achievement of the purpose of the contract and on whose compliance you can regularly rely. This includes in particular our duty to act and to fulfill the contractually owed service, which is described in § 3.`
      ],
    }, {
      heading: "§ 8 Contract language",
      points: [`English or German is available as the contract language.`],
    }, {
      heading: "§ 9 Warranty / Customer Service",
      points: [
        `(1) The warranty is based on the statutory provisions.`,
        `(2) With regard to entrepreneurs, the warranty period for services provided is 12 months.`,
        `(3) As a consumer, you are asked to check the service / digital goods or the service provided for completeness, obvious defects and transport damage immediately upon fulfillment of the contract and to notify us and the freight forwarder of any complaints as soon as possible. If you do not comply, this will of course have no effect on your statutory warranty claims.`,
        <>(4) Our customer service for questions, complaints and complaints is available Monday to Thursday. from 9 a.m. to 4 p.m., by email:<a title="mail@mentorme-ngo.org" href="mailto:mail@mentorme-ngo.org" className="hyperlink-active">mail@mentorme-ngo.org</a>.</>],
    }, {
      heading: "§ 10 Final provisions",
      points: [`(1) German law applies. For consumers, this choice of law only applies insofar as this does not remove the protection granted by mandatory provisions of the law of the state in which the consumer is habitually resident (favourability principle).`,
        `(2) The provisions of the UN Sales Convention expressly do not apply.`,
        `(3) If the customer is a merchant, a legal entity under public law or a special fund under public law, the place of jurisdiction for all disputes arising from contractual relationships between the customer and the provider is the provider's registered office.`],
    }]
}
export default TermsAndCondition;
