import React, { useEffect } from "react";
import { useMsal, useAccount, AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { loginRequest } from '../../Configurations/AzureAuthConfig';
import NavBar from "../NavBar/NavBar";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DropDown, { Options } from '../CustomControls/DropDowns/DropDown';
import { FaInfoCircle } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import dompurify from 'dompurify';

export const NavigationBar = (props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [firstname, setFirstName] = React.useState(null);
    const { t } = useTranslation();
    const logoutSession = () => { instance.logoutRedirect({ postLogoutRedirectUri: "/" }) };

    useEffect(() => {
        if (account && account.idTokenClaims.given_name) {
            setFirstName(account.idTokenClaims.given_name);
        }
    }, [instance, account])

    return (
        <NavBar>
            <AuthenticatedTemplate>
                <DropDown dmargin="31px" displayName={`Hi, ${firstname}!`}>
                    <Options name={t('userProfile')} onClickOption={() => props.redirectProfile()}></Options>
                    <Options name={t('logout')} onClickOption={logoutSession}></Options>
                </DropDown>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
               {props.showSignIn && <li className="navbar-brand" onClick={() => instance.loginRedirect(loginRequest)}><a className="active">{t('signin')}</a></li>}
            </UnauthenticatedTemplate>
        </NavBar>
    );
};

const FooterLinks = (props) => {
    const { linkClass, links, targetLink } = props;
    const { t: translate } = useTranslation();
    return (
        <ul className="footer-links"> {
            links && Object.keys(links).map((key, i) => (           
                <li key={key} className={linkClass}> 
                    <a id={key} title={translate(key)} href={links[key].link} rel="noreferrer" target={targetLink}>
                        {translate(key)}
                    </a>
                    {i < Object.keys(props.links).length-1 && <span className="dot"></span> }
                </li>
            ))}
        </ul>
    );


}

export const Footer = (props) => {
    const { SocialMediaLinks, LegalLinks } = props;
    return (
        <div id="footer" className="m-top-200">
            <div id="upperFooter">
                <FooterLinks linkClass="fo-upperLinks" links={SocialMediaLinks} targetLink="_blank" />
            </div>
            <div id="lowerFooter">
                <FooterLinks linkClass="fo-lowerLinks" links={LegalLinks} targetLink="_self" />
            </div>
        </div>
    )
}

export const Loader = ({ isDisplay }) => {
    const styleLoaderContainer = {
        display: isDisplay ? 'block' : 'none'
    }
    return (
        <div id="loader-container" className="clsLoader-container text-center py-3" style={styleLoaderContainer}>
            <div className="clsLoaderDiv">
                <div className="flex column center-center">
                    <Spinner animation="border" />
                    <p className="m-left-20 m-top-10 p2 cprimary">Loading...</p>
                </div>
            </div>
        </div>
    )
}


export const SecondLoader = ({ isDisplay }) => {
    const styleLoaderContainer = {
        display: isDisplay ? 'block' : 'none'
    }
    return (
        <div id="second-loader-container" className="second-clsLoader-container text-center py-3" style={styleLoaderContainer}>
            <div className="clsLoaderDiv">
                <div className="flex column center-center">
                    <Spinner animation="border" />
                    <p className="m-left-20 m-top-10 p2 cprimary">Loading...</p>
                </div>
            </div>
        </div>
    )
}


export const GeneratePara = (props) => {
    return (
        <div id={`subpart-${props.pointNo}`} className="sub-parts">
            <span className={props.isSmall ? "subHeading" : "partHeading"}>{props.heading}</span>
            <div>
                {props.points && props.points.map((subPoint, index) => (
                    <div key={index} >
                        <span className="partText">
                            {subPoint}
                        </span>
                        <p />
                        <br />
                    </div>
                ))}
            </div>
        </div>
    );
}

export const LinkButton = (props) => {
    const { name, action, active, ...rest } = props;
    let isActive = active ? "active" : "";
    return (
        <a title={name} className={`btn-link ${isActive}`} onClick={action} {...rest}>{name}</a>
    );
}

export const MessageBar = ({ type = "warning", message, show = true }) => {
    const sanitizer = dompurify.sanitize;
    return (
        <div className={`occupy-1 center-center ${type} ${!show ? "hide" : ""}`} id="message-bar">
            <div className="w-max start-center">
                <div className="p-left-60">
                    <p className="w-text-max" dangerouslySetInnerHTML={{ __html: sanitizer(message) }}></p>
                </div>
            </div>
        </div>
    )
}

export const InformationBar = props => {
    let message = props.message || "";
    const {
        info,
        rounded,
        squared,
        middleIcon,
        light
    } = props;

    const sanitizer = dompurify.sanitize;
    return (
        <div className={`occupy-1 start-center ${squared ? "gap" : "p-left-33"} ${props.addons} ${rounded ? "border-r-20" : "border-r-7"}`} id={ light ? "information-bar-light" :"information-bar"}>
            <div className="flex p-right-20 p-top-20 p-bottom-20">
                {info && <div className={`svg-conatiner ${middleIcon ? "flex center-center": "p-top-3"}`}>
                    <FaInfoCircle className="info-icon" size={26} />
                </div>}
                <p className="w-text-max" dangerouslySetInnerHTML={{ __html: sanitizer(message) }}></p>
            </div>
        </div>
    )
}

export const Question = props => {
    const { children,
        questionName,
        helperText,
        customClasses,
        questionNameClass,
        underline } = props;
    const showHelperText = helperText ? true : false;
    return (
        <div className={`occupy-1 column ${customClasses}`}>
            <p className={`question ${questionNameClass ? questionNameClass : "p2"}`} >{questionName} </p>
            {showHelperText && <p className="helper-text p3-light m-top-5">{helperText}</p>}
            {children}
            {underline ? <Underline /> : ""}
        </div>
    )
}

export const Underline = () => {
    return (
        <hr className="occupy-1 underline m-top-40 m-bottom-0">
        </hr>
    )
}

export const ErrorText = ({ errorMessage, customClasses }) => {
    const sanitizer = dompurify.sanitize;
    return (
        <p className={`errorText ${customClasses}`} dangerouslySetInnerHTML={{ __html: sanitizer(errorMessage) }}></p>
    )
}

export const StickyWrapper = props => {
    return (
        <div className="sticky">
            {props.children}
        </div>
    );
}

export const ContentWrapper = (props) => {
    return (
        <div className="content-wrapper">
            {props.children}
        </div>  
    )
}

export const SuccessIcon = props => {
    return (
        <div className="svg-conatiner flex center-center m-left-10" >
            <FaRegCheckCircle className="success-icon" size={26} />
        </div>
    );
}