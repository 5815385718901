import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import user from './signInSignUpSlice';
import notification from './notificationSlice';
import userprofile from "./userProfileSlice";
import machingTandem from "./matchingTendemSlice"

const reducer = combineReducers({
    user,
    notification,
    userprofile, 
    machingTandem
})
const store = configureStore({
    reducer,
})
export default store;