import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    matching: false,
}


  
  export const matchingTandemSlice = createSlice({
    name: 'machingTandem',
    initialState,
    reducers: {
      startMatching: (state) => {
        state.matching = true
      },
      stopMatching: (state) => {
        state.matching = false
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { startMatching, stopMatching } = matchingTandemSlice.actions
  
  export default matchingTandemSlice.reducer