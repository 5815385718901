import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import NavBar from "../NavBar/NavBar";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomControls/Buttons/CustomButton";
import MultiSelect from "../CustomControls/DropDowns/MultiSelect";
import { Helmet } from "react-helmet";
import { useErrorHandler } from "react-error-boundary";
import UserFormControl from "./UserFormControl";
import { useDispatch } from "react-redux";
import { ProfileUpdateReducer } from "../../Store/userProfileSlice";
import { updateProfileImgPath } from "../../Store/signInSignUpSlice";
import { updateUserProfile } from "../../Services/User/UserProfileService";
import { InAppImages } from "../../Configurations/Constants";
import { connect } from "react-redux";
import { getUserInfo } from "../../Store/signInSignUpSlice";
import {
  getUserRole,
  hideSecondLoader,
  showSecondLoader,
} from "../../Helpers/Utils";
import {
  Language,
  MessageTypes,
  MessageBarTemplates,
  QuestionnaireRule,
  Roles,
  StatusByRole as StatusByRoleEnum,
  Pages,
  CommandType,
} from "../../Configurations/Constants";
import {
  InformationBar,
  MessageBar,
  Question,
  StickyWrapper,
} from "../Common/HelperComponents";
import CustomInputText from "../CustomControls/Inputs/CustomInputText";
import SingleSelect from "../CustomControls/DropDowns/SingleSelect";
import {
  getQuestionnaire,
  saveQuestionnaire,
  updateUserStatus,
  createMatchingTandem,
} from "../../Services/Questionnaire/QuestionnaireService";
import { isExists } from "../../Helpers/Utils";
import {
  validateYearsFit,
  validateSingleSelect,
  validateMultiSelect,
} from "../../Helpers/Validations/QuationnaireValidations";
import { useHistory } from "react-router-dom";
import PopUp from "../../Components/CustomControls/PopUp/PopUp";

import { startMatching, stopMatching } from "../../Store/matchingTendemSlice";

const QuestionnaireForm = (props) => {
  const isMchingTendemInProgress = useSelector(
    (state) => state.machingTandem.matching
  );
  const [showMachingInProgressPopup, setShowMachingInProgressPopup] = useState(false);

  const { role } = { ...props };
  const { user } = useSelector((state) => state.user);
  const { t: translate, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const [isMatchingDisabled, setIsMatchingDisabled] = useState(false);
  const dispatch = useDispatch();
  const buttonTwoName =
    role === Roles.Mentee ? "start-matching" : "save-and-publish";
  const infoText = translate(
    role === Roles.Mentee
      ? `Questionnaires-Info-Mentor`
      : `Questionnaires-Info-Mentee`
  );
  const language =
    i18n.language === Language.Deutsch.Code
      ? Language.Deutsch.Name
      : Language.English.Name;

  const [formData, setFormData] = useState({
    Questionnaire: null,
    Role: role,
    Language: language,
    IsProfilePublished: false,
    StatusByRole: null,
    SelectedAnswers: {
      email: "",
      roleName: "",
      isSavePublish: false,
      userSelectedAnswerSaveModel: [],
    },
    IsUpdateRequire: false,
    errorFields: null,
    IsPresavedItemsAdded: false,
  });

  const formDataRef = useRef(formData);
  
  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);
  
  const [isStartMathingForMentee, setIsStartMathingForMentee] = useState(false);
  useEffect(() => {
    if (isStartMathingForMentee) {
      showSecondLoader();
    } else {
      hideSecondLoader();
    }
  }, [isStartMathingForMentee]);

  useEffect(() => {
    let isSaveAndPublish = { ...formData.SelectedAnswers };
    dispatch(ProfileUpdateReducer({ Questionnaire: isSaveAndPublish }));
  }, [formData]);

  const [messageData, setMessageData] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  let preSavedItemsToAdd = [];
  const history = useHistory();

  useEffect(() => {
    if (
      formData.Questionnaire === null ||
      formData.Role !== role ||
      formData.Language !== language
    ) {
      fillQuetionnaireForm();
      showOrHideMessage(false);
    }
    if (preSavedItemsToAdd.length > 0 && !formData.IsPresavedItemsAdded) {
      let selectedItems = [...preSavedItemsToAdd];
      preSavedItemsToAdd = [];
      setFormData({
        ...formData,
        SelectedAnswers: {
          ...formData.SelectedAnswers,
          userSelectedAnswerSaveModel: selectedItems,
        },
        IsPresavedItemsAdded: true,
      });
    }
  }, [formData, language, role, preSavedItemsToAdd.length]);

  const [mappedAnswers, setMappedAnswers] = useState({
    Country: null,
    Cluster: null,
    Language: null,
    State: null,
  });

  //#region  Popup
  const [confirmPopup, setConfirmPopup] = React.useState({
    isDisplay: false,
    textAccept: "",
    textToDisplay: [],
  });
  const getReponseFromPopUp = (value) => {
    setConfirmPopup({
      ...confirmPopup,
      isDisplay: false,
    });
    if (formData.Role === Roles.Mentor) {
      let reqObj = {
        email: formData.SelectedAnswers.email,
        roleName: formData.Role,
        disableMatching: null,
      };
      if (value) {
        if (isMatchingDisabled) {
          //when clicked on enabled
          //AC12-424
          showOrHideMessage(false);
          if (!handleQuestionValidation(true)) {
            showOrHideMessage(
              true,
              translate("questionnaire-profile-data-incomplete"),
              MessageTypes.error
            );
          } else {
            reqObj = {
              ...reqObj,
              disableMatching: !value,
            };
            handleMatchingStatus(reqObj);
          }
        } else {
          //when clicked on disabled
          reqObj = {
            ...reqObj,
            disableMatching: value,
          };
          handleMatchingStatus(reqObj);
        }
      }
    }
  };
  const handleMatchingStatus = async (data) => {
    await updateUserStatus(data)
      .then((res) => {
        if (res) {
          if (res.isSuccess === true) {
            setIsMatchingDisabled(data.disableMatching);
            //1344 Fix
            setFormData({
              ...formData,
              StatusByRole:
                data.disableMatching === true
                  ? StatusByRoleEnum.PAUSED
                  : StatusByRoleEnum.AVAILABLE,
            });
            if (isMatchingDisabled) {
              if (formData.Role === Roles.Mentor) {
                // showOrHideMessage(true, translate('questionnaire-all-fields-are-valid'), MessageTypes.success);
              }
            } else {
              showOrHideMessage(false);
              //AC5-424
              if (formData.Role === Roles.Mentor) {
                showOrHideMessage(
                  true,
                  translate("questionnaire-profile-paused"),
                  MessageTypes.warning
                );
              }
            }
          } else if (
            res.isSuccess === false &&
            res.errorMessage === "InCompleteProfile"
          ) {
            showOrHideMessage(
              true,
              translate("questionnaire-profile-data-incomplete"),
              MessageTypes.error
            );
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const pauseQuestionnaire = (val) => {
    if (val) {
      setConfirmPopup({
        ...confirmPopup,
        isDisplay: true,
        textAccept: translate("enable matching"),
        textToDisplay: [
          translate("questionnaire-enable-profile-1"),
          translate("questionnaire-enable-profile-2"),
        ],
      });
    } else {
      setConfirmPopup({
        ...confirmPopup,
        isDisplay: true,
        textAccept: translate("disable matching"),
        textToDisplay: [
          translate("questionnaire-disable-profile-1"),
          translate("questionnaire-disable-profile-2"),
        ],
      });
    }
  };
  //#endregion

  const fillQuetionnaireForm = async () => {
    await getQuestionnaire(language, role, user.email)
      .then((response) => {
        if (response && response.isSuccess) {
          //add error prop
          let allQuestions = [...response.value.questionAnswerModel];
          allQuestions.forEach((item) => {
            item.error = false;
            item.errorMessage = "";
          });

          const isUpdate = response.value.questionAnswerModel.some(
            (question) =>
              Array.isArray(question.userSelectedAnswers) &&
              question.userSelectedAnswers.length > 0
          );

          setFormData({
            ...formData,
            Questionnaire: allQuestions,
            Role: role,
            Language: language,
            IsProfilePublished: response.value.isProfilePublished,
            StatusByRole: response.value.statusByRole,
            SelectedAnswers: {
              email: user.email,
              roleName: role,
              userSelectedAnswerSaveModel: [],
            },
            IsUpdateRequire: isUpdate,
          });
          //Bug#779
          if (role === Roles.Mentor) {
            if (response.value.statusByRole === StatusByRoleEnum.PAUSED) {
              setIsMatchingDisabled(true);
              showOrHideMessage(
                true,
                translate("questionnaire-profile-paused"),
                MessageTypes.warning
              );
            } else {
              setIsMatchingDisabled(false);
              //AC1.2-334
              if (response.value.statusByRole === StatusByRoleEnum.AVAILABLE) {
                showOrHideMessage(
                  true,
                  translate("questionnaire-all-fields-are-valid"),
                  MessageTypes.success
                );
              }
            }
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleSavePublishAndMatching = async () => {

    if (isMchingTendemInProgress) {
      setShowMachingInProgressPopup(true);
      return;
    }


    if (formData.Role === Roles.Mentee) {
      setIsStartMathingForMentee(true);
      showSecondLoader();
    }
    await saveOrUpdateQuetionnaire(CommandType.Save);

    if (formData.Role === Roles.Mentee) {
      //handle start matching
      if (!handleQuestionValidation(true)) {
        setIsStartMathingForMentee(false);
        hideSecondLoader();
        showOrHideMessage(
          true,
          translate("questionnaire-mentee-profile-incomplete-error"),
          MessageTypes.error
        );
      } else if (formData.StatusByRole === StatusByRoleEnum.PENDING) {
        showOrHideMessage(
          true,
          translate("could-not-start-matching-alorithm-error"),
          MessageTypes.error
        );
      } else {
        showOrHideMessage(
          true,
          translate("questionnaire-mentee-profile-date-complete"),
          MessageTypes.success
        );
        saveOrUpdateQuetionnaire(CommandType.StartMatching);
      }
    } else if (formData.Role === Roles.Mentor) {
      //handle save and publish
      if (isMatchingDisabled) {
        showOrHideMessage(
          true,
          translate("questionnaire-maching-disabled"),
          MessageTypes.error
        );
      } else if (!handleQuestionValidation(true)) {
        showOrHideMessage(
          true,
          translate("questionnaire-some-fields-not-valid"),
          MessageTypes.error
        );
      } else {
        saveOrUpdateQuetionnaire(CommandType.SaveAndPublish);
      }
    }
  };

  const getImageOrSetDefaultImage = (imageUrl) => {
    if (imageUrl && imageUrl !== "") {
      return imageUrl;
    }
    return InAppImages.profileIcon;
  };

  const prepareAPIRequestData = (userProfile) => {
    let requiredDetails = {
      email: userProfile.email,
      professionalPosition: userProfile.professionalPosition,
      company: userProfile.company,
      countryCodeId: userProfile.countryCodeId,
      stateOriginalId: userProfile.stateOriginalId,
      city: userProfile.city,
      languageId: userProfile.defaultLanguage,
      clusterId: userProfile.clusterId,
      profileQuestionAnswer: userProfile.userProfileQuestionAnswers,
      imageFile: getImageOrSetDefaultImage(userProfile.profileImage),
      isDeletedProfileImage: false,
    };
    return requiredDetails;
  };

  const prepareOptionsForQuestions = (
    options,
    label,
    value,
    mappedLabel,
    MappedValue,
    translateLabel = false
  ) => {
    if (Array.isArray(options) && options.length > 1) {
      options = options.flat();
    }
    let allOptions = [];
    options.forEach((option) => {
      const modifiedOption = {
        ...option,
        label: translateLabel ? translate(option[label]) : option[label],
        value: option[value],
        saveId: value,
      };
      allOptions.push(modifiedOption);
      if (option[value] === MappedValue) {
        let MappedDetails = mappedAnswers;
        MappedDetails[mappedLabel] = modifiedOption;
        setMappedAnswers(MappedDetails);
      }
    });
    return allOptions;
  };

  const userRole = getUserRole(user);

  const inputConditions = (fieldId) => {
    switch (fieldId) {
      case "professionalPosition":
      case "company":
        return props.UP[fieldId].length <= 200;
      case "city":
        return isExists(props.UP[fieldId]) && props.UP[fieldId].length <= 200;
      default:
        return isExists(props.UP[fieldId]);
    }
  };

  const isCountryGermany = () => {
    return props.UP["countryCodeId"] === 64;
  };

  const getErrorValueByCondition = (condition) => {
    if (condition) return false;
    return true;
  };

  const metInputIinitialCondition = (fieldId) => {
    switch (fieldId) {
      case "stateOriginalId":
        return isCountryGermany();
      case "clusterId":
        return userRole === Roles.Mentee || userRole === Roles.Both;
      default:
        return true;
    }
  };

  const defaultStatusRequiredFields = {
    countryCodeId: false,
    stateOriginalId: false,
    languageId: false,
    city: false,
    clusterId: false,
  };

  const [requiredfieldErrors, setRequiredFieldErrors] = useState(
    defaultStatusRequiredFields
  );

  const handleValidation = (fieldId, isRequired = true) => {
    let errorCounter = Object.keys(requiredfieldErrors).length;
    let errorsInFields = { ...requiredfieldErrors };
    if (fieldId && isRequired) {
      errorsInFields[fieldId] = metInputIinitialCondition(fieldId)
        ? getErrorValueByCondition(inputConditions(fieldId))
        : false;
    } else {
      Object.keys(requiredfieldErrors).forEach((eachField) => {
        errorsInFields[eachField] = metInputIinitialCondition(eachField)
          ? getErrorValueByCondition(inputConditions(eachField))
          : false;
        if (!errorsInFields[eachField]) {
          errorCounter--;
        }
      });
    }
    setRequiredFieldErrors(errorsInFields);
    return errorCounter === 0;
  };

  const validateMaxLengthQuestions = () => {
    const { profileQuestionAnswer } = props.UP;
    let errorCount = profileQuestionAnswer.length || 0;
    if (isExists(profileQuestionAnswer)) {
      profileQuestionAnswer.forEach((eachQuestion) => {
        if ((eachQuestion?.userAnswer?.length || 0) < 1000) {
          errorCount--;
        }
      });
    }
    return errorCount === 0;
  };

  const [imagePath, setImagePath] = useState(null);
  const [isInvalidImage, setIsInvalidImage] = useState(false);

  //handleSavePublishAndMatching

  const saveOrUpdateQuetionnaire = async (command, saveAnswers = true) => {
    let isValid = false;
    const isSaveAndPublish = command === CommandType.SaveAndPublish;
    const isStartMatching = command === CommandType.StartMatching;
    if (command === CommandType.Save) {
      if (formData.Role === Roles.Mentor) {
        if (formData.IsProfilePublished) {
          isValid = handleQuestionValidation(!isMatchingDisabled);
          if (!isValid && !isMatchingDisabled)
            showOrHideMessage(
              true,
              translate("questionnaire-profile-data-incomplete-save"),
              MessageTypes.error
            );
        } else {
          isValid = handleQuestionValidation(false);
        }
      } else if (formData.Role === Roles.Mentee) {
        isValid = handleQuestionValidation(false);
      }
    }
    if (isValid || isSaveAndPublish) {
      let reqObj = { ...formData.SelectedAnswers };
      if (isSaveAndPublish) {
        reqObj.isSavePublish = true;
      }

      if(saveAnswers)
      await saveQuestionnaire(reqObj, formData.IsUpdateRequire)
        .then((res) => {
          if (res) {
            if (res.isSuccess === true) {
              if (isSaveAndPublish) {
                setFormData({
                  ...formData,
                  SelectedAnswers: {
                    ...formData.SelectedAnswers,
                  },
                  IsUpdateRequire: true,
                  IsProfilePublished: true,
                  StatusByRole: StatusByRoleEnum.AVAILABLE,
                });
                showOrHideMessage(
                  true,
                  translate("questionnaire-all-fields-are-valid"),
                  MessageTypes.success
                );
              } else {
                setFormData({
                  ...formData,
                  SelectedAnswers: {
                    ...formData.SelectedAnswers,
                  },
                  IsUpdateRequire: true,
                });
                //Bug#733
                if (
                  role === Roles.Mentor &&
                  formData.IsProfilePublished &&
                  formData.StatusByRole !== StatusByRoleEnum.PAUSED
                ) {
                  showOrHideMessage(
                    true,
                    translate("questionnaire-all-fields-are-valid-save-again"),
                    MessageTypes.success
                  );
                } else {
                  showOrHideMessage(
                    true,
                    translate(MessageBarTemplates[role].saved.message),
                    MessageBarTemplates[role].saved.messageType
                  );
                }
              }
            }
          }
        })
        .catch((error) => {
          setIsStartMathingForMentee(false);
          hideSecondLoader();
          handleError(error);
        });

      if (
        handleValidation() &&
        validateMaxLengthQuestions() &&
        !isInvalidImage
      ) {
        const isImgUpdate =
          isSaveAndPublish.profileImage !== props.UP.imageFile;
        await updateUserProfile(props.UP, isImgUpdate)
          .then((res) => {
            if (res && res.isSuccess) {
              dispatch(getUserInfo(user.email));
              showOrHideMessage(
                true,
                translate("Your user account has been successfully updated"),
                MessageTypes.success
              );
              setIsInvalidImage(false);

              // Bug #695
              // const savedLang = getUserInfo(
              //   (lang) => lang.languageId === props.UP.languageId
              // );
              // if (isExists(savedLang)) {
              //   const langCode =
              //     savedLang.languageName === Language.Deutsch.Name
              //       ? Language.Deutsch.Code
              //       : Language.English.Code;
              //   i18n.changeLanguage(langCode);
              // }

              //update mini profile image as well
              if (isExists(imagePath)) {
                dispatch(updateProfileImgPath(imagePath));
              } else if (props.UP.isDeletedProfileImage) {
                dispatch(updateProfileImgPath(InAppImages.profileIcon));
              }
            }
          })
          .catch((error) => {
            setIsStartMathingForMentee(false);
            hideSecondLoader();
            handleError(error);
          });
      } else {
        showOrHideMessage(
          true,
          translate(MessageBarTemplates.General.profileMissingFieldsError),
          MessageTypes.error
        );
      }
    } else if (isStartMatching) {
      showSecondLoader();
      dispatch(startMatching());
      await createMatchingTandem(formData.SelectedAnswers.email)
        .then((res) => {
          dispatch(stopMatching());
          hideSecondLoader();
          if (res) {
            if (res.isSuccess === true) {
              if (res.value === true) {
                setFormData({
                  ...formData,
                  StatusByRole: StatusByRoleEnum.AVAILABLE,
                });
                //redirect to Matching page
                history.push(Pages.Dashboard.path);
              } else if (
                res.value === false &&
                res.errorMessage === "InCompleteProfile"
              ) {
                setIsStartMathingForMentee(false);
                showOrHideMessage(
                  true,
                  translate("questionnaire-mentee-profile-incomplete-error"),
                  MessageTypes.error
                );
              }
            } else if (
              res.value === false &&
              res.statusCode === 400 &&
              res.errorMessage === "User not available for matching"
            ) {
              setIsStartMathingForMentee(false);
              showOrHideMessage(
                true,
                translate("could-not-start-matching-alorithm-error"),
                MessageTypes.error
              );
            } else {
              setIsStartMathingForMentee(false);
              showOrHideMessage(
                true,
                translate("questionnaire-mentee-update-profile"),
                MessageTypes.error
              );
            }
          }
        })
        .catch((error) => {
          dispatch(stopMatching());
          setIsStartMathingForMentee(false);
          handleError(error);
        });
    }
  };

  const getSavedAnswers = (userSelectedAnswers, possibleAnswers, question) => {
    let preSelectedOptions = [];
    userSelectedAnswers.forEach((eachAnswer) => {
      const matchedAnswer = possibleAnswers.find(
        (item) =>
          eachAnswer.originalPossibleAnswerId === item.originalPossibleAnswerId
      );
      preSelectedOptions.push(matchedAnswer);
    });

    //disabled this due to USER STORY 1233
    //Set default option if no selected element found
    // if (!preSelectedOptions.length) {
    //     const defaultOption = possibleAnswers.find((answer) => (answer.optionText === translate('No-Preference')));
    //     if (defaultOption) {
    //         preSelectedOptions.push(defaultOption);

    //         const objSelected = {
    //             questionDetails: question,
    //             selectedOptions: (question.ruleName === QuestionnaireRule.MultipleMatchesFit ||
    //                 question.ruleName === QuestionnaireRule.MultipleFit) ? [defaultOption] : defaultOption
    //         }
    //         const answerDefault = getAnswerModel(objSelected);
    //         preSavedItemsToAdd.push(answerDefault);
    //     }
    // }
    return preSelectedOptions;
  };

  const getAnswerModel = (selectedItem) => {
    let possibleAnswersArr = [];
    switch (selectedItem.questionDetails.ruleName) {
      case QuestionnaireRule.MultipleFit:
      case QuestionnaireRule.MultipleMatchesFit: {
        const isSetOption = selectedItem.selectedOptions.some(
          (item) => item.optionText === translate("No-Preference")
        );

        if (isExists(selectedItem.selectedOptions))
          selectedItem.selectedOptions.forEach((item) => {
            possibleAnswersArr.push({
              originalPossibleAnswerId: isExists(item)
                ? item.originalPossibleAnswerId
                : null,
              isSetOptionThatFitsAll: isSetOption === true ? 1 : 0,
            });
          });
        break;
      }
      case QuestionnaireRule.MentorOnly:
      case QuestionnaireRule.SingleFit: {
        const isSetOption =
          selectedItem.selectedOptions.optionText ===
          translate("No-Preference");
        if (isExists(selectedItem.selectedOptions))
          possibleAnswersArr.push({
            originalPossibleAnswerId: isExists(selectedItem.selectedOptions)
              ? selectedItem.selectedOptions.originalPossibleAnswerId
              : null,
            isSetOptionThatFitsAll: isSetOption === true ? 1 : 0,
          });
        break;
      }
      default:
        break;
    }
    const selectedObj = {
      OriginalQuestionId: selectedItem.questionDetails.originalQuestionId,
      QuestionPairId: selectedItem.questionDetails.questionPairId,
      DirectFieldAnswer:
        selectedItem.questionDetails.ruleName === QuestionnaireRule.YearsFit
          ? selectedItem.selectedOptions
          : null,
      RuleName: selectedItem.questionDetails.ruleName,
      MaxOption: selectedItem.questionDetails.maxOption,
      PossibleAnswer: possibleAnswersArr,
    };
    return selectedObj;
  };

  const selectedValues = (selected) => {
    validateFieldsOnChange(selected);
    if (selected.selectedOptions || selected.selectedOptions === "") {
      let selectedAnswersArr = [
        ...formData.SelectedAnswers.userSelectedAnswerSaveModel,
      ];
      const itemIndexToUpdate = selectedAnswersArr.findIndex(
        (item) =>
          item.OriginalQuestionId ===
          selected.questionDetails.originalQuestionId
      );

      if (itemIndexToUpdate > -1) {
        selectedAnswersArr[itemIndexToUpdate] = getAnswerModel(selected);
      } else {
        selectedAnswersArr.push(getAnswerModel(selected));
      }

      setFormData({
        ...formData,
        SelectedAnswers: {
          email: user.email,
          roleName: role,
          userSelectedAnswerSaveModel: selectedAnswersArr,
        },
      });
    }
  };

  const showOrHideMessage = (show, message, type) => {
    setMessageData({ message, type, show });
  };

  const validateFieldsOnChange = (selectedField) => {
    const { questionDetails, selectedOptions } = selectedField;
    let error = {
      isError: false,
      errorMessage: null,
    };
    switch (questionDetails.ruleName) {
      case QuestionnaireRule.YearsFit: {
        error = validateYearsFit(selectedOptions, translate);
        break;
      }
      case QuestionnaireRule.SingleFit:
      case QuestionnaireRule.MentorOnly: {
        error = validateSingleSelect(selectedOptions, translate);
        break;
      }
      case QuestionnaireRule.MultipleFit:
      case QuestionnaireRule.MultipleMatchesFit: {
        error = validateMultiSelect(
          selectedOptions,
          questionDetails.maxOption,
          questionDetails,
          translate
        );
        break;
      }
      default:
        break;
    }
    questionDetails.error = error.isError;
    questionDetails.errorMessage = error.errorMessage;
  };

  const handleQuestionValidation = (isRequiredValidate) => {
    const { Questionnaire, SelectedAnswers } = formData;
    const selectedAnswersArr = SelectedAnswers.userSelectedAnswerSaveModel;
    //run validation
    Questionnaire.forEach((question) => {
      //check if we have answer against every question/presaved and flag respectively
      const answer = selectedAnswersArr.find(
        (answer) => answer.OriginalQuestionId === question.originalQuestionId
      );
      if (
        isRequiredValidate &&
        !isExists(answer) &&
        !isExists(question.userSelectedAnswers)
      ) {
        question.error = true;
        question.errorMessage = translate("requiredFieldError");
      } else if (answer) {
        let error = {
          isError: false,
          errorMessage: null,
        };
        //custom validations goes here
        switch (question.ruleName) {
          case QuestionnaireRule.YearsFit:
            error = validateYearsFit(
              answer.DirectFieldAnswer,
              translate,
              isRequiredValidate
            );
            break;
          case QuestionnaireRule.SingleFit:
          case QuestionnaireRule.MentorOnly:
            error = validateSingleSelect(
              answer.PossibleAnswer,
              translate,
              isRequiredValidate
            );
            break;
          case QuestionnaireRule.MultipleFit:
          case QuestionnaireRule.MultipleMatchesFit:
            error = validateMultiSelect(
              answer.PossibleAnswer,
              answer.MaxOption,
              question,
              translate,
              isRequiredValidate
            );
            break;
          default:
            break;
        }
        question.error = error.isError;
        question.errorMessage = error.errorMessage;
      } else {
        question.error = false;
        question.errorMessage = null;
      }
    });
    setFormData({
      ...formDataRef.current,
      Questionnaire: Questionnaire,
    });
    if (Questionnaire.some((item) => item.error === true)) {
      return false;
    } else {
      return true;
    }
  };

  const getSelectorType = (selectorType, item) => {
    switch (selectorType) {
      case QuestionnaireRule.YearsFit: {
        const answer = item.userSelectedAnswers.find(
          (ques) => ques.originalQuestionId === item.originalQuestionId
        )?.directFieldAnswer;
        return (
          <CustomInputText
            customClasses="m-top-17"
            inputMaxlength={4}
            questionDetails={item}
            selectedItems={selectedValues}
            placeHolderName="YYYY"
            inputValue={answer}
            error={item.error}
            errorMessage={item.errorMessage}
          />
        );
      }
      case QuestionnaireRule.MultipleMatchesFit:
      case QuestionnaireRule.MultipleFit:
        const possibleAnswers = getAnswersFromQuestionnaire(item, language);
        const answers = getSavedAnswers(
          item.userSelectedAnswers,
          possibleAnswers,
          item
        );
        return (
          <MultiSelect
            selectedItems={selectedValues}
            questionDetails={item}
            options={possibleAnswers}
            userSelects={item.userSelectedAnswers}
            preSelectedOptions={answers}
            error={item.error}
            errorMessage={item.errorMessage}
            placeholder={translate("Please select a value…")}
          />
        );
      case QuestionnaireRule.SingleFit:
      case QuestionnaireRule.MentorOnly: {
        const possibleAnswers = getAnswersFromQuestionnaire(item, language);
        const answers = getSavedAnswers(
          item.userSelectedAnswers,
          possibleAnswers,
          item
        );
        //1286
        // if (
        //   selectorType === QuestionnaireRule.MentorOnly &&
        //   formData.Role === Roles.Mentor &&
        //   item.originalQuestionId === 13 &&
        //   isMatchingDisabled == false &&
        //   (formData.StatusByRole === StatusByRoleEnum.NEW ||
        //     formData.StatusByRole === StatusByRoleEnum.PAUSED) === false
        // ) {
        //   const ques13 =
        //     formData.SelectedAnswers?.userSelectedAnswerSaveModel?.find(
        //       (i) => i.OriginalQuestionId === 13
        //     );
        //   let answer;
        //   if (!isExists(ques13)) answer = answers.shift();
        //   if (!isExists(answer) && isExists(ques13)) {
        //     const answerObj = ques13.PossibleAnswer;
        //     const answersFromUserSelected = getSavedAnswers(
        //       answerObj,
        //       possibleAnswers,
        //       item
        //     );
        //     answer = answersFromUserSelected.shift();
        //   }
        //   if (isExists(answer)) {
        //     return answer.optionText || "-";
        //   } else {
        //     return "-";
        //   }
        // } else {
        return (
          <SingleSelect
            selectedItems={selectedValues}
            questionDetails={item}
            options={possibleAnswers}
            preSelectedOptions={answers}
            error={item.error}
            errorMessage={item.errorMessage}
            placeholder={translate("Please select a value…")}
          />
        );
        // }
      }
      default:
        break;
    }
  };

  const getQuestions = () => {
    let allQuestions = [];
    formData.Questionnaire.forEach((item, i, questionArray) => {
      let helperTxt = "";
      if (Number(item.maxOption) > 0) {
        helperTxt =
          item.ruleName === QuestionnaireRule.MultipleFit ||
          item.ruleName === QuestionnaireRule.MultipleMatchesFit
            ? translate("Please Choose up to n options.", {
                count: item.maxOption,
              })
            : "";
      }
      const questionTxt = item.questionText;
      allQuestions.push(
        <Question
          key={i}
          questionName={translate(questionTxt)}
          helperText={translate(helperTxt)}
          customClasses="m-top-40 m-left-38"
          underline={i > questionArray.length - 1 ? false : true}
        >
          <div className="w-523 p-top-17">
            {getSelectorType(item.ruleName, item)}
          </div>
        </Question>
      );
    });
    return allQuestions;
  };

  const canRenderQuestions = () => {
    return formData.Role === role && formData.Language === language;
  };

  const getReponseFromPopUpSave = () => {
    setShowMachingInProgressPopup(false)
  }

  if (formData !== null && formData.Questionnaire && canRenderQuestions()) {
    const isStartMatchingDisabled =
      role === Roles.Mentee &&
      (formData.StatusByRole === StatusByRoleEnum.PROPOSED ||
        formData.StatusByRole === StatusByRoleEnum.MATCHED);

    return (
      <div className="wh-max">
        <PopUp
          show={showMachingInProgressPopup}
          acceptName={translate('confirmation-button')}
          sendResponse={getReponseFromPopUpSave}
          questions={[translate("maching-tandem-warning")]}
        />

        <Helmet>
          <title>
            {translate(
              role === Roles.Mentee
                ? Pages.QuestionnaireMentee.pageTitle
                : Pages.QuestionnaireMentor.pageTitle
            )}
          </title>
        </Helmet>
        <StickyWrapper>
          <MessageBar {...messageData} />
          <NavBar
            type="other"
            position="end-center"
            sticky={false}
            customStyles="nav-bar-sticky-border h-110"
          >
            <CustomButton
              className="btn-secondary m-left-22"
              btnType="toogle"
              name={translate("save")}
              toogleName={
                isMatchingDisabled
                  ? translate("enable matching")
                  : translate("disable matching")
              }
              onToogleAction={pauseQuestionnaire}
              default={isMatchingDisabled}
              isDisplay={formData.Role === Roles.Mentor}
            ></CustomButton>
            <PopUp
              show={confirmPopup.isDisplay}
              acceptName={confirmPopup.textAccept}
              rejectName={translate("Cancel")}
              sendResponse={getReponseFromPopUp}
              questions={confirmPopup.textToDisplay}
            />
            <div>
              <CustomButton
                className="custom-btn-secondary m-left-22 w-150"
                onClickAction={() => saveOrUpdateQuetionnaire(CommandType.Save)}
                name={translate("save")}
              ></CustomButton>
              {/* display start matching only if role is mentee and status is not matched/proposed US-525*/}
              <CustomButton
                disable={isStartMatchingDisabled || isStartMathingForMentee}
                className={
                  (isStartMatchingDisabled || isStartMathingForMentee
                    ? `custom-btn-primary-disabled`
                    : `custom-btn-primary`) + ` m-left-22 w-150`
                }
                name={translate(buttonTwoName)}
                onClickAction={handleSavePublishAndMatching}
              ></CustomButton>
            </div>
          </NavBar>
        </StickyWrapper>
        <div className="flex column center-center">
          <div className="w-max app-standard-gap">
            <InformationBar
              message={infoText}
              addons="m-top-60 m-bottom-68"
              info
            />
            <UserFormControl Role={role} />
            {getQuestions()}
          </div>
        </div>
      </div>
    );
  } else return null;
};

function getAnswersFromQuestionnaire(questionnaireObj, language) {
  let respone = [];
  const option = questionnaireObj.possibleAnswers.filter(
    (item) => item.languageName === language
  );
  option.forEach((element) =>
    respone.push({
      ...element,
      label: element.optionText,
      value: element.originalPossibleAnswerId,
    })
  );
  return respone;
}
const mapStateToProps = (state) => {
  return {
    UP: state.userprofile.userProfile,
  };
};
export default connect(mapStateToProps, null)(QuestionnaireForm);
