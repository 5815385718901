import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Constants, { Pages } from "../../../Configurations/Constants";
import { isExists } from "../../../Helpers/Utils";
import { getMentees, getStatusMaster } from "../../../Services/Admin/AdminService";
import { InformationBar } from "../../Common/HelperComponents";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import CustomTable from "../../CustomControls/CustomTable/CustomTable";
import MultiSelect from "../../CustomControls/DropDowns/MultiSelect";
import AdminCommonNavbar from "./AdminCommonNavbar";
import { useErrorHandler } from 'react-error-boundary';

const AdminMentees = (props) => {
  const { t: translate, i18n } = useTranslation();
  const selectedLanguage =
    i18n.language === Constants.Language.Deutsch.Code
      ? Constants.Language.Deutsch.Name
      : Constants.Language.English.Name;
  const [messageData, setMessageData] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const handleError = useErrorHandler();
  const showOrHideMessage = (show, message, type) => {
    setMessageData({ message, type, show });
  };

  const [formData, setFormData] = useState({
    filterOptions: null,
    requestFilterOptions: null,
    allMentees: null,
    selectedFilterOptions: null,
    Language: selectedLanguage
  });

  useEffect(() => {
    if (formData.filterOptions === null) {
      getStatusMaster()
        .then((response) => {
          fillFormData(response);
        })
        .catch(error => { handleError(error); });
    }
    if (formData.Language !== selectedLanguage) {
      setFormData({
        ...formData,
        Language: selectedLanguage,
        filterOptions: prepareDataForFilterInput(formData.requestFilterOptions, "name", "id"),
      })
    }
  }, [selectedLanguage])

  const fillFormData = (response) => {
    setFormData({
      ...formData,
      requestFilterOptions: response.value,
      filterOptions: prepareDataForFilterInput(response.value, "name", "id"),
      allMentees: null,
      filterStarted: false,
      Language: selectedLanguage
    })
  }

  const prepareDataForFilterInput = (options, label, value) => {
    let allOptions = [];
    if (isExists(options)) {
      options.filter(state => state[label] !== Constants.StatusByRole.PAUSED)
        .forEach((option) => {
          const modifiedOption = {
            ...option,
            label: translate(option[label]),
            value: option[value]
          }
          allOptions.push(modifiedOption);
        });
    }
    return allOptions;
  }

  const canLoadData = () => {
    return isExists(formData);
  }

  const getUserInput = (val) => {
    if (isExists(val)) {
      setFormData({
        ...formData,
        selectedFilterOptions: prepareApiRequestData(val)
      })
    }
  }

  const getFilterData = () => {
    const { selectedFilterOptions, requestFilterOptions } = formData;
    const requestOptions = isExists(selectedFilterOptions) ? selectedFilterOptions : requestFilterOptions;
    getMentees(requestOptions)
      .then((response) => {
        if (response && response.isSuccess) {
          setFormData({
            ...formData,
            allMentees: isExists(response.value) ? response.value : [],
            filterStarted: true
          });
        }
      })
      .catch(error => { handleError(error); });
  }

  const prepareApiRequestData = (val) => {
    let options = [];
    if (isExists(val.selectedOptions)) {
      val.selectedOptions.forEach(eachItem => {
        options.push({
          id: eachItem.id,
          name: eachItem.name
        });
      })
    }
    return options;
  }

  if (canLoadData()) {
    return (
      <>
        <Helmet>
          <title>{translate(Pages.AdminMentees.pageTitle)}</title>
        </Helmet>
        <AdminCommonNavbar msgData={messageData} setMessage={showOrHideMessage} />
        <div className="occupy-1 flex center-center m-bottom-60 m-top-60">
          <div className="w-max app-standard-gap p-left-60 p-right-60">
            <p className="p1 m-bottom-58">{translate("mentees")}</p>

            {/* View matching tandems*/}
            <div className="occupy-1 paragraph-container-full column">

              <InformationBar
                info
                addons="m-bottom-68"
                message={translate("admin-mentee-infobar-message")}
              />
              <p className="p2-bold-v2">
                {translate("mentee-status")}
              </p>
              <p className="p3-medium-light-2 m-bottom-7">
                {translate("filter-select-one-or-more-option")}
              </p>
              <Row>
                <Col sm={12} lg={4} md={12} className="m-top-10">

                  <div>
                    <MultiSelect
                      selectedItems={getUserInput}
                      successTick={false}
                      placeholder={translate("Please select a value…")}
                      options={formData.filterOptions}
                      language={selectedLanguage}
                      translateKey="name"
                      noOptionplaceholder="no-entry-found-to-display"
                    />
                  </div>
                  {/* <p className="p3-medium-light-3 m-top-10">
                {translate("Filtered matching tandems returned 46 entries.")}
              </p> */}
                </Col>
                <Col
                  sm={12}
                  lg={3}
                  md={12}
                  className=" flex start-center m-top-6"
                >
                  <CustomButton
                    name={translate("filter-and-display")}
                    className="custom-btn-primary w-156"
                    onClickAction={getFilterData}
                  />
                </Col>
              </Row>
            </div>
            <Row className="m-top-26">
              {isExists(formData.allMentees) ?
                <CustomTable
                  allItems={formData.allMentees}
                  language={selectedLanguage}
                  itemsPerPage={25}
                  itemKeys={[
                    "firstName",
                    "lastName",
                    "email",
                    "registrationDate",
                    "programYear",
                    "status"
                  ]}

                  columnNames={[
                    translate("First name"),
                    translate("Last name"),
                    translate("E-mail address"),
                    translate("registration-date"),
                    translate("program-year"),
                    translate("mentee-status")
                  ]}
                  disabledRowClick={true}
                  injectComponets={{ status: { type: "badge" }, registrationDate: { type: "formatDateShort" } }}
                />
                :
                formData.filterStarted && <p className="p2">{translate("no-entry-found-to-display")}</p>}
            </Row>

          </div>
        </div>
      </>
    );
  } else return null;
};

export default AdminMentees;
