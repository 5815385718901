import { Post } from '../Services/service';
import { apiEndpoints } from '../Configurations/Constants';
import { isExists } from './Utils';
export default function LogError(error, errorInfo) {
    const errorPayload =
        `:::::: Error occured at ${new Date().toLocaleString()}::::::
        \n
        Endpoint: ${error.name}
        \n
        Error: ${error.message}
        \n
        ErrorInfo: ${JSON.stringify(error)}
        \n
        :::::::::::::::::::::End of error detail:::::::::::::::::::::::
        \n\n`;

    if (isExists(error.name) && error.name !== "/api/Logger/LogError") {
        Post(apiEndpoints.Logger.LogError,
            { ErrorMessage: errorPayload },
            true);
    }
}