import React, { memo } from "react";
import { Modal, Row, Col, Container } from "react-bootstrap";
import { isExists } from "../../../Helpers/Utils";
import CustomButton from "../Buttons/CustomButton";
import dompurify from 'dompurify';

const PopUp = (props) => {
  const {
    show, /* Flag to show/hide modal*/
    questions, /* Format ["Question1", "Quetion2"] */
    sendResponse, /* pass your parent function to get user response*/
    acceptName,
    rejectName,
    children,
    isActionEnabled = true,
    size = "md",
    btnTop="m-top-39",
    btnDirection = "center" /* you can pass start, end, centre */
  } = props;
  const sanitizer = dompurify.sanitize;


  const prepareResponse = (value) => {
    /** Reponse is given either true or false handle in the parent accordingly*/
    sendResponse(value);
  }

  const getQuestions = () => {
    let displayQuestions = [];
    if (isExists(questions)) {
      questions.forEach((eachQ, i) => {
        displayQuestions.push(<p key={i} className="p2-medium" dangerouslySetInnerHTML={{ __html: sanitizer(eachQ)}}></p>);
      })
    }
    return displayQuestions;
  }

  return (
    <Modal size={size} centered show={show} animation={false}>
      <Modal.Body>
        <Container>

          <Row className="occupy-1 flex center-center m-top-39">
            <Col className="occupy-1 flex column " xs={12} md={12}>
              {getQuestions()}
            </Col>
          </Row>

          <Row className="occupy-1 flex center-center">
            <Col className="occupy-1 flex" xs={12} md={12}>
              {children}
            </Col>
          </Row>

          <Row className={`occupy-1 flex center-center ${btnTop} m-bottom-39`}>
            <Col className={`occupy-1 flex ${btnDirection}-center`} xs={12} md={12}>
               {rejectName && <CustomButton onClickAction={() => prepareResponse(false)} className="custom-btn-secondary w-150 m-right-18" name={rejectName} /> }
               {acceptName && <CustomButton disable={!isActionEnabled} onClickAction={() => prepareResponse(true)} className={`${isActionEnabled ? "custom-btn-primary" : "custom-btn-primary-disabled"} w-150`} name={acceptName} /> }
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default memo(PopUp);

