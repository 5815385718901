import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import store from './Store/store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './Configurations/AzureAuthConfig';
import "./i18n";

require('bootstrap/dist/css/bootstrap.min.css');
require('./assets/styles/App.scss');

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App instance={msalInstance} />
    </Provider>
  </Router>,
  document.getElementById('root')
);
