import React from 'react';
import { useTranslation } from "react-i18next";
import dompurify from 'dompurify';
import { logoutFromApplication } from '../../Helpers/Utils';

const ErrorPage = (props) => {
    const { t: translate } = useTranslation();
    const {errorCode, redirectLink, redirectPageName, center } = props;
    const sanitizer = dompurify.sanitize;

    const redirectPage = (e) => {
        if(e.target.id === "redirectPage") {
            logoutFromApplication();
        }
    }

    const helperTexts = {
       401 : {
            error: "error-page-401-error",
            errorSubtext: "error-page-401-error-subtext",
            pageName: "login-page",
            redirectionLink: "#",
            redirectId: "redirectPage"
            
       } ,
       403 : {
            error: "error-page-403-error",
            errorSubtext: "error-page-403-error-subtext",
            pageName: "home-page",
            redirectionLink: "/",
            redirectId: "noRedirect"
       },
        404 : {
            error: "error-page-404-error",
            errorSubtext: "error-page-404-error-subtext",
            pageName: "home-page",
            redirectionLink: "/",
            redirectId: "noRedirect"
        }  
    }

    const getErrorText = () => {
        const errorTexts = helperTexts[errorCode] || helperTexts[404];
        const redirectText = `<a id=${errorTexts.redirectId} href="${redirectLink  || errorTexts.redirectionLink}">${translate(redirectPageName || errorTexts.pageName)}</a>`;
        return (
            <>
                <p className='p2-bold-v2'>{translate(errorTexts.error)}</p>
                <p className='.p-regular-v4 errorLink'
                  onClick={redirectPage}
                 dangerouslySetInnerHTML={{ __html: sanitizer(translate(errorTexts.errorSubtext, {link : redirectText})) }}/>
            </>
        )
    }

    return (
        <div className={`${center ? "wh-max gap flex center-center" : "w-max"}`}>
                <div className="errorPage">
                    <p className='p1 m-bottom-46'>{translate("error-page-title")}</p>
                    {getErrorText()}
                    <p className='.p-regular-v4'>{translate("error-code", {errorCode : errorCode || "404"})}</p>
                </div>
        </div>
    )
}

export default ErrorPage;