import { msalConfig } from '../Configurations/AzureAuthConfig';
import { RolesParams, Roles, Language, PublicPages, MentorMeUIBaseUrl } from '../Configurations/Constants';

export function getRoleFromUrl(url) {
    return (url.split('/').pop() === RolesParams[0] ? Roles.Mentee : url.split('/').pop() === RolesParams[1] ? Roles.Mentor : null);
}
export function getIsMentorOrMentee(url) {
    return RolesParams.some((role) => url.split('/')?.includes(role));
}

export function hideLoader() {
    if (isExists(document.getElementById('loader-container'))) {
        document.getElementById('loader-container').style.display = 'none';
    }
}

export function hideSecondLoader() {
    console.log("hide")
    if (isExists(document.getElementById('second-loader-container'))) {
        document.getElementById('second-loader-container').style.display = 'none';
    }
}

export function showLoader() {
    if (isExists(document.getElementById('loader-container'))) {
        document.getElementById('loader-container').style.display = 'block';
    }
}


export function showSecondLoader() {
    console.log("Show")
    if (isExists(document.getElementById('second-loader-container'))) {
        document.getElementById('second-loader-container').style.display = 'block';
    }
}

export function Downloader(url, fileName) {
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName;  
    return function() { 
        a.click()
    };
}

/**Usage: gets Colors from Global CSS to JS*/
export function getColor(color) {
  const allStyles = getComputedStyle(document.documentElement);
  return allStyles.getPropertyValue(color);
}

export function isExists(value) {
    if (value) {
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        if (Object.getPrototypeOf(value) === Object.prototype) {
            return Object.keys(value).length > 0;
        }
        return true;
    }
    return false;
}

export function getUserRole(user) {
    let isMentee = false;
    let isMentor = false;
    let isMatchingAdmin = false;
    if (user && Array.isArray(user.userRole)) {
        isMentee = user.userRole.find(i => i.name === Roles.Mentee);
        isMentor = user.userRole.find(i => i.name === Roles.Mentor);
        isMatchingAdmin = user.userRole.find(i => i.name === Roles.Admin);
    }
    if (isMatchingAdmin)
        return Roles.Admin;
    else if (isMentee && isMentor)
        return Roles.Both
    else if (isMentee)
        return Roles.Mentee;
    else if (isMentor)
        return Roles.Mentor;
}

export function getFormattedDate(dateString, lang) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const langObj = {
        English: "en-GB",
        German: "de-DE"
    }
    if(isExists(dateString)) {
        // convert to local time from utc
        let date = new Date(dateString + 'Z');
        if (date instanceof Date) {
            const formattedDate = date.toLocaleDateString(langObj[lang], options);
            const formattedTime = lang === Language.English.Name ? formatAMPM(date) : getCurrentHoursAndMins(date);
            return `${formattedDate}, ${formattedTime}`;
        }
        return dateString;
    }
    return null;
}

function getCurrentHoursAndMins(dateObj) {
    let hours = dateObj.getHours();
    let formattedHours = hours > 9 ? hours : `0${hours}`;
    let minutes = dateObj.getMinutes();
    let formattedMinutes = minutes > 9 ? minutes : `0${minutes}`;
    return `${formattedHours}:${formattedMinutes}`
}

export function getFormattedDateShort(dateString, lang, isTime) {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    const langObj = {
        English: "en-GB",
        German: "de-DE"
    }
    // convert to local time from utc
    let date = new Date(dateString + 'Z');
    if (date instanceof Date) {
        const formattedDate = date.toLocaleDateString(langObj[lang], options);
        if (isTime) {
            const formattedTime = lang === Language.English.Name ? formatAMPM(date) : getCurrentHoursAndMins(date);
            return `${formattedDate}, ${formattedTime}`
        }
        else {
            return `${formattedDate}`;
        }
    }
    return dateString;
}

export function logoutFromApplication() {
    clearCache();
    window.location = getLogoutURL();
}

function getLogoutURL() {
    let url = `${msalConfig.auth.authority}/oauth2/v2.0/logout?post_logout_redirect_uri=${MentorMeUIBaseUrl}`;
    return url;
}

function clearCache() {
    localStorage.clear();
    sessionStorage.clear();
    clearCookies();
}

function clearCookies() {
    var allCookies = document.cookie.split(';');
    for (var i = 0; i < allCookies.length; i++) {
        document.cookie = allCookies[i] + "=;expires="
        + new Date(0).toUTCString();
    }
}

export function getDateInDotFormat(dateInString) {
    if (isExists(dateInString)) {
        return dateInString.split("/").join(".");
    }
    return dateInString;
}

export function addSpaceAfterComma(txt) {
    if(isExists(txt)) {
        return  txt.split(",").join(", ");
    }
    return txt;
}

const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function BytesToMb(sizeInBytes) {
    return Number((sizeInBytes / (1024 * 1024)).toFixed(2));
}

export function space(limit) {
    let spaces = ``;
    for (let i = 0; i < limit; i++) {
        spaces += `\xa0`;
    }
    return spaces;
}

export function getTranslationSource(t, page) {
    return {
        Title: `${t('legalInfo-Title')}`,
        Content: [
            {
                heading: `${t('legalInfo-h1')}`,
                points: [`${t('legalInfo-p1')}`],
            },
            {
                heading: `${t('legalInfo-h2')}`,
                points: [`Karin Heinzl`],
            },
            {
                heading: `${t('Contact')}:`,
                points: [
                    <>{t('Telephone')}: <a className="hyperlink-active" href={`tel:${t('ContactNumberCall')}`}>{t('ContactNumberDisplay')}</a></>,
                    <>{t('Email')}:	<a className="hyperlink-active" href={`mailto:${t('ContactEmail')}`}>{t('ContactEmail')}</a></>]
            },
            {
                heading: `${t('legalInfo-h3')}`,
                points: [
                    `${t('legalInfo-p3.1')}`,
                    `${t('legalInfo-h3.2')}`],
            }
        ]
    }
}

export function isPublicPage(path) {
   return  Object.keys(PublicPages).some(key => path === PublicPages[key].path);
}
