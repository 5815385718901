import React, { useEffect } from 'react';
import { isExists } from '../../../Helpers/Utils';
import { useTranslation } from "react-i18next";
import { ErrorText, SuccessIcon } from '../../Common/HelperComponents';

export const CustomInputText = props => {
    const {
        labelName,
        placeHolderName,
        id,
        inputMaxlength = 1000,
        error,
        errorMessage,
        customClasses,
        allowExtraLength,
        allowInternalValidation = false,
        success,
        questionDetails,
        selectedItems,
        inputValue,
        simple,
        ...rest
    } = props;

    const { t: translate } = useTranslation();
    const [selectedOption, setSelectedOption] = React.useState(inputValue || "");
    const [internalError, setInternalError] = React.useState(false);
    const [internalMessage, setInternalMessage] = React.useState(errorMessage);

    const handleInputChange = (e) => {
        setSelectedOption(e);
        if(allowInternalValidation) {
            handleInternalValidations(e);
        }
        /** Invokes passed function so that parent get updated */
        const returnObj = {
            questionDetails,
            selectedOptions: e
        }
        selectedItems(returnObj);
    }

    const handleInternalValidations = (e) => {
        if (e && e.length > inputMaxlength) {
            setInternalError(true);
            setInternalMessage(translate(`max-characters-exceeded-error`, { count: inputMaxlength }));
        } else {
            setInternalError(false);
        }
    }

    useEffect(() => {
        if(selectedOption !== inputValue) {
            setSelectedOption(inputValue || "");
        }
    },[inputValue])

    return (
        <div data-testid="customInputText" className={`occupy-1 column ${customClasses}`}>
            <p className="p2">{labelName}</p>
            <div className={`custom-input flex ${labelName && "m-top-17"}`}>
                <input className={` ${simple ? "w-100-p" : "w-80-p"} ${(error) ? "error" : ""}`} type="text" value={selectedOption} maxLength={allowExtraLength ? "none" : inputMaxlength} placeholder={placeHolderName} {...rest} onChange={(e) => handleInputChange(e.target.value)}></input>
                {!simple && isExists(selectedOption) && !internalError  && !error && <SuccessIcon />}
            </div>
            {(error || internalError) && <ErrorText errorMessage={internalError ? internalMessage : errorMessage} />}
        </div>
    )
}

export default CustomInputText;