import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { MessageTypes } from '../../../Configurations/Constants';
import { syncDBFromAzureAD } from '../../../Services/Admin/AdminService';
import { MessageBar, StickyWrapper } from '../../Common/HelperComponents';
import CustomButton from '../../CustomControls/Buttons/CustomButton';
import PopUp from '../../CustomControls/PopUp/PopUp';
import NavBar from '../../NavBar/NavBar';
import ExportExcel from '../Admin/ExportExcel';
import { useErrorHandler } from 'react-error-boundary';

const AdminCommonNavbar = props => {

    const [showExport, setShowExport] = useState();
    const [showSynchronise, setShowSynchronise] = useState();
    const { msgData, setMessage } = props;
    const { t: translate } = useTranslation();
    const handleError = useErrorHandler();
    
    const getExportResult = (value) => {
        setShowExport(false);
    }

    const synchronizeData = (canSync) => {
        setShowSynchronise(false);
        if (canSync) {
            syncDBFromAzureAD()
                .then((response) => {
                    if (response && response.isSuccess) {
                        if (setMessage) {
                            setMessage(
                                true,
                                translate("synchronize-success-message"),
                                MessageTypes.success);
                        }
                    }
                })
                .catch(error => { handleError(error); });
        }
    }

    return (
        <StickyWrapper>
            <MessageBar {...msgData} />
            <NavBar
                type="other"
                position="end-center"
                sticky={false}
                customStyles="nav-bar-sticky-border h-110">
                <CustomButton name="Export" onClickAction={() => setShowExport(true)} className="custom-btn-primary w-150 m-left-10" />
                <CustomButton name={translate("synchronize-data-button-name")} onClickAction={() => setShowSynchronise(true)} className="custom-btn-primary w-150 m-left-10" />
            </NavBar>
            {showExport && <ExportExcel show={showExport} onActionClick={getExportResult} />}
            <PopUp show={showSynchronise}
                acceptName={translate("synchronize-data-button-name")}
                rejectName={translate("Cancel")}
                sendResponse={(res) => synchronizeData(res)}
                questions={[translate("synchronize-popup-message")]} />
        </StickyWrapper>
    );
};

export default AdminCommonNavbar;