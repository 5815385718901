import { createSlice } from '@reduxjs/toolkit'
import { sendNotification } from '../Services/Notification/NotificationService'
import { apiEndpoints } from '../Configurations/Constants';

const slice = createSlice({
    name: 'notification',
    initialState: {
        notification: null
    },

    reducer: {

    }
});

export default slice.reducer;
// const notificationSuccess = slice.actions;

export const pushNotification = (teamplateName, emailTo, emailFrom) => async dispatch => {
    try {
        sendNotification(apiEndpoints.Notification.SendNotification, teamplateName, emailTo, emailFrom)
            .then((res) => {
                if (res && res.isSuccess) {
                    alert('mail sent to ' + emailTo);
                }
            })
    }
    catch (e) {
        return console.error(e.message);
    }
}