import { getColor, getDateInDotFormat, getFormattedDate, getFormattedDateShort, isExists } from "../../../Helpers/Utils";
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import CustomBadge from "../Badge/CustomBadge";
import { AllStates } from "../../../Configurations/Constants";
import CustomButton from "../Buttons/CustomButton";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { FaUnlink } from "react-icons/fa";

const Record = (props) => {
    const { currentItems,
        itemKeys,
        componentsList,
        passRowDetails,
        disableClick,
        overFlowKeys,
        onToogleAction,
        hightLight,
        language,
        showPointer,
        onButtonAction } = props;
    const [selectedRow, setSelectedRow] = useState(null);
    let currentPageRows = [];

    const handleRowClick = (rowDetails, indexOfRow) => {
        setSelectedRow(indexOfRow);
        if (!disableClick) {
            passRowDetails(rowDetails);
        }
    }

    if (isExists(currentItems)) {
        currentItems.map((eachRow, i) => {
            currentPageRows.push(
                <tr key={i} className={`${showPointer ? "pointer" : ""}`} onClick={() => handleRowClick(eachRow, i)}>
                    <FetchCellItems selected={i === selectedRow}
                        hightLight={hightLight}
                        overFlowKeys={overFlowKeys}
                        itemKeys={itemKeys}
                        componentsList={componentsList}
                        singleRecord={eachRow}
                        language={language}
                        onToogleAction={onToogleAction}
                        onButtonAction={onButtonAction} />
                </tr>);
        });
    }
    return currentPageRows;
}

const GetDeactivateClusterCell = (props) => {
    const { t: translate, i18n } = useTranslation();
    const { singleRecord, onButtonAction } = props;
    if (isExists(singleRecord)) {
        if (singleRecord.isEnded === true) {
            return <>{translate("Deactivated")}</>
        }
        else if (singleRecord.isDefault === true) {
            return <>{translate("default-cluster")}</>
        }
        else {
            return <CustomButton
                name={translate("Deactivate")}
                className="custom-btn-primary w-50 m-left-20"
                onClickAction={() => { onButtonAction(singleRecord) }}>
                    <FaUnlink className="deactivateIcon"/>
            </CustomButton>
        }
    }
    return null;
}

const FetchCellItems = ({ itemKeys, componentsList, singleRecord, selected, overFlowKeys, onToogleAction, hightLight, language, onButtonAction }) => {
    let AllCells = [];
    itemKeys.forEach((cellKey, i) => {
        const type = (isExists(componentsList) && componentsList[cellKey]) ? componentsList[cellKey].type : "default";
        const value = singleRecord[cellKey];
        const stopOverflow = isExists(overFlowKeys) && overFlowKeys.includes(cellKey);

        switch (type) {
            case "badge":
                AllCells.push(<td key={i} className={`${selected && hightLight ? "selected" : ""}`}><CustomBadge type={AllStates[value].type} labelName={value} /></td>);
                break;
            case "deactivateCluster":
                AllCells.push(<td key={i} className={`${selected && hightLight ? "selected" : ""}`}>
                    <GetDeactivateClusterCell
                        singleRecord={singleRecord}
                        onButtonAction={onButtonAction} />
                </td>);
                break;
            case "toogle":
                AllCells.push(<td key={i} className={`${selected && hightLight ? "selected" : ""}`}>
                    <CustomButton
                        noLabel
                        className="btn-secondary"
                        btnType="toogle"
                        onToogleAction={() => { onToogleAction(singleRecord) }}
                        default={value === true}
                        isDisplay={true}
                        customStyle={{ width: "40px" }} /** For Table Cell this should be given inline */
                    ></CustomButton>
                </td>);
                break;
            case "formatDateDot":
                AllCells.push(<td key={i} className={`${selected && hightLight ? "selected" : ""} ${stopOverflow ? "overflow" : ""} `}>{getDateInDotFormat(value)}</td>);
                break;
            case "formatDateShort":
                AllCells.push(<td key={i} className={`${selected && hightLight ? "selected" : ""} ${stopOverflow ? "overflow" : ""} `}>{getFormattedDateShort(value, language)}</td>);
                break;
            case "formatDateLong":
                AllCells.push(<td key={i} className={`${selected && hightLight ? "selected" : ""} ${stopOverflow ? "overflow" : ""} `}>{getFormattedDate(value, language)}</td>);
                break;
            default: AllCells.push(<td key={i} className={`${selected && hightLight ? "selected" : ""} ${stopOverflow ? "overflow" : ""} `}>{value}</td>);
        }
    })
    return AllCells;
}


export const CustomTable = props => {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [startPoint, setStartPoint] = useState(0);
    const [fromPageChange, setFromPageChange] = useState(false);
    const [currentPageNo, setPageNumber] = useState(0);
    const {
        language,
        columnNames,
        allItems,
        itemsPerPage,
        itemKeys,
        injectComponets,
        onRowClick,
        disabledRowClick,
        overFlowKeys, /**ex: {["dateOfBirth", "IsAcceptedDataPrivacyPolicy"]} keys with bigger names are shown with three dots*/
        onToogleActions,
        onButtonAction,
        hightLight = false,  /** On click of row, the row gets highlighted*/
        mousePointer = false /** To mouse hover on the row to display cursor as pointer */
    } = props;

    useEffect(() => {
        const endPoint = startPoint + itemsPerPage;
        setCurrentItems(allItems.slice(startPoint, endPoint));
        setPageCount(Math.ceil(allItems.length / itemsPerPage));
        if (!fromPageChange) {
            setStartPoint(0);
            setPageNumber(0);
        } else {
            setFromPageChange(false);
        }

    }, [startPoint, itemsPerPage, allItems]);

    const handlePageChange = (event) => {
        const newStartPoint = (event.selected * itemsPerPage) % allItems.length;
        setPageNumber(event.selected);
        setFromPageChange(true);
        setStartPoint(newStartPoint);
    }

    const pushRowDetails = (rowDetails) => {
        onRowClick(rowDetails);
    }

    const pushToogleResponse = (toogleDetails) => {
        onToogleActions(toogleDetails);
    }

    if (isExists(columnNames) && currentItems !== null) {
        return (
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            {columnNames.map((colName, i) => (
                                <th key={i} className={`${isExists(overFlowKeys) && overFlowKeys.includes(colName) ? "overflow" : ""}`}>{colName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <Record passRowDetails={pushRowDetails}
                            currentItems={currentItems}
                            itemKeys={itemKeys}
                            componentsList={injectComponets}
                            overFlowKeys={overFlowKeys}
                            onToogleAction={pushToogleResponse}
                            onButtonAction={onButtonAction}
                            hightLight={hightLight}
                            showPointer={mousePointer}
                            language={language}
                            disableClick={disabledRowClick} />
                    </tbody>
                </table>

                <div className="pagination-container">
                    <ReactPaginate
                        breakLabel="..."
                        previousLabel={<BsChevronLeft />}
                        nextLabel={<BsChevronRight />}
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={currentPageNo}
                    />
                </div>
            </div>
        )
    } else return null;
}

export default CustomTable;