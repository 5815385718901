import { Get, Post } from '../service';
import { apiEndpoints } from '../../Configurations/Constants';

export async function getQuestionnaire(language, role, emailId) {
    const requestEndpoint = apiEndpoints.Questionnaire.GetQuestionAnswerPairs + `?language=${language}&role=${role}&emailId=${emailId}`;
    return await Get(requestEndpoint)
        .then(res => res)
        .catch(error => { throw error; });
}

export async function saveQuestionnaire(formData, isUpdate) {
    const saveEndpoint = isUpdate ? apiEndpoints.Questionnaire.UpdateAnswer : apiEndpoints.Questionnaire.CreateAnswer;
    return await Post(saveEndpoint, formData)
        .then(res => res)
        .catch(error => { throw error; });
}

export async function updateUserStatus(data) {
    return await Post(apiEndpoints.Questionnaire.UpdateUserStatus, data)
        .then(res => res)
        .catch(error => { throw error; });
}

export async function createMatchingTandem(emailId) {
    const reqObj = {
        menteeEmail: emailId
    }
    return await Post(apiEndpoints.MatchingTandem.CreateMatchingTandem, reqObj)
        .then(res => res)
        .catch(error => { throw error; });
}