import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { InformationBar } from "../../Common/HelperComponents";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import CustomTable from "../../CustomControls/CustomTable/CustomTable";
import MultiSelect from "../../CustomControls/DropDowns/MultiSelect";
import Constants from "../../../Configurations/Constants";
import { useTranslation } from "react-i18next";
import {
    getMatchingTandemList,
    getMatchingTandemById
} from '../../../Services/Admin/AdminService';
import { isExists, space } from "../../../Helpers/Utils";
import TandemDetails from './TandemDetails';
import CustomInputText from "../../CustomControls/Inputs/CustomInputText";
import SingleSelect from "../../CustomControls/DropDowns/SingleSelect";
import { useErrorHandler } from 'react-error-boundary';

const FilterMatchingTandem = (props) => {
    const { TandemStates,
        ProposalStates,
        setMessage,
        Users,
        Clusters } = props;
    const { t: translate, i18n } = useTranslation();
    const selectedLanguage =
        i18n.language === Constants.Language.Deutsch.Code
            ? Constants.Language.Deutsch.Name
            : Constants.Language.English.Name;

    const [formData, setFormData] = useState({
        TandemStatuses: null,
        UserSearchData: null,
        ClusterSearchData: null,
        Language: selectedLanguage,
        filterStarted: false
    });
    const handleError = useErrorHandler();
    const accordionDv = useRef(null);
    const [tandemStateFilters, setTandemStateFilters] = useState(null);
    const [selectedMT, setSelectedMT] = useState(null);
    const [selectedProposalState, setSelectedProposalState] = useState({
        menteeStatus: null,
        mentorStatus: null
    });
    const [filteredData, setFilteredData] = useState({
        result: [],
        filteredObj: []
    });
    const [rowData, setRowData] = useState(null);

    useEffect(() => {
        if (formData.TandemStatuses === null) {
            fillTableData();
        }
        if (formData.Language !== selectedLanguage) {
            
            buildFilterResultToDisplay(filteredData.result);
            setFormData({
                ...formData,
                TandemStatuses: {
                    ...formData.TandemStatuses,
                    States: buildStatusOptions(TandemStates)
                },
                UserSearchData: {
                    ...formData.UserSearchData,
                    UserSearchOptions: buildUsersSearchOptions(Users)
                },
                ClusterSearchData: {
                    ...formData.ClusterSearchData,
                    ClustersSearchOptions: buildClusterSearchOptions(Clusters)
                },
                Language: selectedLanguage
            })
        }
    }, [formData, selectedLanguage]);

    const fillTableData = () => {
        setFormData(
            {
                ...formData,
                TandemStatuses: {
                    StateResult: TandemStates,
                    States: buildStatusOptions(TandemStates),
                    SelectedStates: null
                },
                UserSearchData: {
                    UserSearchOptions: buildUsersSearchOptions(Users),
                    SelectedUser: null
                },
                ClusterSearchData: {
                    ClustersSearchOptions: buildClusterSearchOptions(Clusters),
                    SelectedCluster: null
                },
                Language: selectedLanguage
            }
        )
        setTandemStateFilters(TandemStates);
    }

    const buildUsersSearchOptions = (options) => {
        let items = [];
        if (isExists(options)) {
            options.forEach((option) => {
                const modifiedOption = {
                    option,
                    label: `${option["firstName"]} ${option["lastName"]}`,
                    value: option["email"],
                    subLabel: option["email"]
                }
                items.push(modifiedOption);
            });
        }
        return items;
    }
    const buildClusterSearchOptions = (options) => {
        let items = [];
        if (isExists(options)) {
            options.forEach((option) => {
                const modifiedOption = {
                    option,
                    label: translate(option["clusterText"]),
                    value: option["clusterId"]
                }
                items.push(modifiedOption);
            });
        }
        return items;
    }
    const buildStatusOptions = (options) => {
        let items = [];
        if (isExists(options)) {
            options.forEach((option) => {
                const modifiedOption = {
                    option,
                    label: translate(option["name"]),
                    value: option["name"]
                }
                items.push(modifiedOption);
            });
        }
        return items;
    }

    const getDefaultProposal = (options, selected, proposalId) => {
        let items = [];
        if (isExists(options)) {
            const selectedItem = options.find((option) => option.name === selected);
            if (isExists(selectedItem)) {
                const modifiedOption = {
                    option: {
                        proposalId,
                        proposalState: selectedItem["name"],
                        comment: ""
                    },
                    label: selectedItem["name"],
                    value: selectedItem["name"]
                }
                items.push(modifiedOption);
            }
        }
        return items;
    }

    const onTandemStateFilterSelect = (selectedFilters) => {
        if (selectedFilters
            && isExists(selectedFilters.selectedOptions)) {
            let arrObj = [];
            selectedFilters.selectedOptions.forEach((item) => arrObj.push(item.option));
            setTandemStateFilters(arrObj);
        }
        else {
            setTandemStateFilters(formData.TandemStatuses.StateResult);
        }
    }

    const [tandemIDFilter, setTandemIdFilter] = useState({
        id: null,
        isError: false
    });
    const handleMatchingTandemInput = (inputTxt) => {
        const { selectedOptions } = inputTxt;
        if (inputTxt) {
            setTandemIdFilter({
                ...tandemIDFilter,
                id: selectedOptions,
                isError: isExists(selectedOptions) && isValidMT_ID(selectedOptions)
            });
        }
    }

    function isValidMT_ID(tandelIdTxt) {
        const isNum = /^\d+$/.test(tandelIdTxt);
        return !isNum;
    }
    const onUserFilterSelect = (selectedFilters) => {
        if (selectedFilters && isExists(selectedFilters.selectedOptions)) {
            const { selectedOptions } = selectedFilters;
            setFormData({
                ...formData,
                UserSearchData: {
                    ...formData.UserSearchData,
                    SelectedUser: selectedOptions.option
                }
            })
        }
        else {
            setFormData({
                ...formData,
                UserSearchData: {
                    ...formData.UserSearchData,
                    SelectedUser: null
                }
            })
        }
    }

    const onClusterFilterSelect = selectedFilters => {
        if (selectedFilters && isExists(selectedFilters.selectedOptions)) {
            let arrObj = [];
            const { selectedOptions } = selectedFilters;
            selectedOptions.forEach((item) => arrObj.push(item.option));
            setFormData({
                ...formData,
                ClusterSearchData: {
                    ...formData.ClusterSearchData,
                    SelectedCluster: arrObj
                }
            })
        }
        else {
            setFormData({
                ...formData,
                ClusterSearchData: {
                    ...formData.ClusterSearchData,
                    SelectedCluster: null
                }
            })
        }
    }

    const buildFilterResultToDisplay = (result) => {
        let arrResult = [];
        if (isExists(result)) {
            result.forEach(item =>
                arrResult.push({
                    id: item.id,
                    createdDate: item.createdDate,
                    programYear: item.programYear,
                    menteeMentorLastNames: `${item.menteeLastName} / ${item.mentorLastName}`,
                    tandemStatus: item.tandemStatus,
                    menteeProposalStatus: item.menteeProposalStatus,
                    mentorProposalStatus: item.mentorProposalStatus,
                    maxScore: Number(item.percentage) > 0 ? 
                                `${item.baseMatchRuleScore}${space(1)}/${space(1)}${item.maxScore}${space(6)}${Number(item.percentage).toFixed(2)}% `
                                : "Manual Matching"
                })
            )
        }
        setFilteredData({
            ...filteredData,
            filteredObj: arrResult,
            result
        });
        setRowData(null);
    }

    const handleFilterData = async () => {
        
        if (isExists(tandemStateFilters) && !tandemIDFilter.isError) {
            const email = isExists(formData.UserSearchData.SelectedUser) ? formData.UserSearchData.SelectedUser.email : "";
            const tandemId = isExists(tandemIDFilter.id) ? tandemIDFilter.id : 0;
            const filterCluster = isExists(formData.ClusterSearchData.SelectedCluster) ? formData.ClusterSearchData.SelectedCluster : null;

            const request = {
                tandemStates: tandemStateFilters,
                tandemId,
                email,
                filterCluster
            }
            await getMatchingTandemList(request)
                .then(res => {
                    if (res && res.isSuccess) {
                        buildFilterResultToDisplay(res.value);
                    }
                })
                .catch(error => { handleError(error); });

            setMessage(false);
            setFormData({
                ...formData,
                filterStarted: true,
                Language: selectedLanguage
            });
        }
    }

    const handleRowClick = async (rowData, isReloaded) => {
        setSelectedMT(rowData);
        await getMatchingTandemById(rowData.id)
            .then(res => {
                if (res && res.isSuccess) {
                    setTimeout(() => {
                        accordionDv.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end'
                          });
                    },500);
                    setRowData(res.value);
                    setSelectedProposalState({
                        ...selectedProposalState,
                        menteeStatus: getDefaultProposal(ProposalStates, res.value["matchingTandemMenteeProposal"].status, res.value["matchingTandemMenteeProposal"].proposalId)[0],
                        mentorStatus: getDefaultProposal(ProposalStates, res.value["matchingTandemMentorProposal"].status, res.value["matchingTandemMentorProposal"].proposalId)[0]
                    })
                    if (!isExists(isReloaded)) {
                        setMessage(false);
                    }
                }
            })
            .catch(error => { handleError(error); });
    }

    if (isExists(formData.TandemStatuses) && isExists(ProposalStates)) {
        return (
            <>
                {/* View matching tandems*/}
                <div className="occupy-1 paragraph-container-light column">
                    <p className="p2-bold-20 m-bottom-23 m-top-56">
                        {translate("view-matching-tandems")}
                    </p>
                    <InformationBar
                        info
                        addons="m-bottom-66"
                        message={translate("view-matching-tandems-info-text")} />

                    <Row className="hide-small">
                        <Col sm={12} lg={3} md={12}>
                            <p className="p2-bold-v2">
                                {translate("matching-tandem-Id")}
                            </p>
                            <p className="p3-medium-light-2 m-bottom-17 m-top-5">
                                {translate("Invalid-tandem-id-error")}
                            </p>
                        </Col>
                        <Col sm={12} lg={4} md={12}>
                            <p className="p2-bold-v2">
                                {translate("matching-tandem-status")}
                            </p>
                            <p className="p3-medium-light-2 m-bottom-17 m-top-5">
                                {translate("select-option-to-filter")}
                            </p>
                        </Col>
                    </Row>

                    <Row >
                        <Col sm={12} lg={3} md={12}>
                            <div>
                                <div className="show-small">
                                    <p className="p2-bold-v2">
                                        {translate("matching-tandem-Id")}
                                    </p>
                                    <p className="p3-medium-light-2 m-bottom-17 m-top-5">
                                        {translate("Invalid-tandem-id-error")}
                                    </p>
                                </div>
                                <CustomInputText
                                    placeHolderName={translate("enter-an-id-text")}
                                    inputMaxlength={10}
                                    selectedItems={handleMatchingTandemInput}
                                    error={tandemIDFilter.isError}
                                    errorMessage={translate("enter-a-valid-id")}
                                    simple />
                            </div>
                        </Col>
                        <Col sm={12} lg={3} md={12}>
                            <div>
                                <div className="show-small">
                                    <p className="p2-bold-v2">
                                        {translate("matching-tandem-status")}
                                    </p>
                                    <p className="p3-medium-light-2 m-bottom-17 m-top-5">
                                        {translate("select-option-to-filter")}
                                    </p>
                                </div>
                                <MultiSelect
                                    selectedItems={onTandemStateFilterSelect}
                                    options={formData.TandemStatuses.States}
                                    placeholder={translate("Please enter here…")}
                                    successTick={false}
                                    language={selectedLanguage} />
                            </div>
                        </Col>
                    </Row>

                    <Row className="hide-small m-top-41">
                        <Col sm={12} lg={3} md={12}>
                            <p className="p2-bold-v2">
                                {translate("User")}
                            </p>
                            <p className="p3-medium-light-2 m-bottom-17 m-top-5">
                                {translate("select-user-by-name")}
                            </p>
                        </Col>
                        <Col sm={12} lg={4} md={12}>
                            <p className="p2-bold-v2">
                                {translate("program-year")}
                            </p>
                            <p className="p3-medium-light-2 m-bottom-17 m-top-5">
                                {translate("select-option-to-filter")}
                            </p>
                        </Col>
                    </Row>

                    <Row >
                        <Col sm={12} lg={3} md={12}>
                            <div>
                                <div className="show-small">
                                    <p className="p2-bold-v2">
                                        {translate("User")}
                                    </p>
                                    <p className="p3-medium-light-2 m-bottom-17 m-top-5">
                                        {translate("select-user-by-name")}
                                    </p>
                                </div>
                                <SingleSelect
                                    selectedItems={onUserFilterSelect}
                                    options={formData.UserSearchData.UserSearchOptions}
                                    error={false}
                                    successTick={false}
                                    placeholder={translate('admin-user-info-searchbox-placeholder')}
                                    serachIcon />
                            </div>
                        </Col>
                        <Col sm={12} lg={3} md={12}>
                            <div>
                                <div className="show-small">
                                    <p className="p2-bold-v2">
                                        {translate("program-year")}
                                    </p>
                                    <p className="p3-medium-light-2 m-bottom-17 m-top-5">
                                        {translate("select-option-to-filter")}
                                    </p>
                                </div>
                                <MultiSelect
                                    selectedItems={onClusterFilterSelect}
                                    options={formData.ClusterSearchData.ClustersSearchOptions}
                                    placeholder={translate("Please enter here…")}
                                    successTick={false}
                                    language={selectedLanguage} />
                            </div>
                        </Col>
                        <Col
                            sm={12}
                            lg={3}
                            md={12}>
                            <CustomButton
                                name={translate('filter-and-display')}
                                className="custom-btn-primary w-156"
                                onClickAction={handleFilterData} />
                        </Col>
                    </Row>
                </div>
                {isExists(filteredData.filteredObj) ?
                    <>
                        <Row>
                            <CustomTable
                                allItems={filteredData.filteredObj}
                                itemsPerPage={Constants.MaxLimits.RowsPerPageInTable}
                                itemKeys={["id",
                                    "createdDate",
                                    "programYear",
                                    "menteeMentorLastNames",
                                    "tandemStatus",
                                    "menteeProposalStatus",
                                    "mentorProposalStatus",
                                    "maxScore"]}
                                columnNames={[translate('filter-column-mt-id'),
                                translate("filter-column-creation-date"),
                                translate("filter-column-program-year"),
                                translate("filter-column-mentee-mentor"),
                                translate("filter-column-mt-status"),
                                translate("mentee-status"),
                                translate("mentor-status"),
                                translate("filter-column-score")]}
                                onRowClick={(val) => handleRowClick(val, null)}
                                injectComponets={{ tandemStatus: { type: "badge" }, menteeProposalStatus: { type: "badge" }, mentorProposalStatus: { type: "badge" }, createdDate: { type: "formatDateShort" } }}
                                disabledRowClick={false}
                                hightLight={isExists(rowData)}
                                mousePointer={true}
                                language={selectedLanguage} />
                        </Row>
                        
                        <div ref={accordionDv}>
                            {rowData ?
                                <TandemDetails
                                    TandemData={rowData}
                                    ProposalStates={ProposalStates}
                                    setMessage={setMessage}
                                    reloadTandemDetails={() => handleRowClick(selectedMT, true)} />
                                :
                                <div className="displaybox-grey flex start-center  m-top-60">
                                    <p className="p2-medium-white m-left-20">Select a matching team to display details</p>
                                </div>
                            }
                        </div>
                        
                    </>
                    :
                    formData.filterStarted && !tandemIDFilter.isError && <p className="p2">{translate("no-entry-found-to-display")}</p>
                }
            </>
        );
    }
    else
        return null;
};
export default FilterMatchingTandem;
