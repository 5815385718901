import React, { useEffect } from 'react';
import Select, { components } from 'react-select';
import { getColor, isExists } from '../../../Helpers/Utils';
import { ErrorText, SuccessIcon } from '../../Common/HelperComponents';
import { useTranslation } from "react-i18next";

const MultiSelect = props => {
    const { preSelectedOptions, placeholder, error, errorMessage, successTick = true, noOptionplaceholder="no-option", language ="en", translateKey } = props;
    const [selectedOption, setSelectedOption] = React.useState(preSelectedOptions || null);
    const [selectedLanguage, setSelectedLanguage] = React.useState(language || null);

    const { t: translate } = useTranslation();
    const handleInputChange = (e) => {
        setSelectedOption(e);
        /** Invokes passed function so that parent get updated */
        const returnObj = {
            questionDetails: props.questionDetails,
            selectedOptions: e ? e : []
        }
        props.selectedItems(returnObj);
    }

    useEffect(()=> {
        if(isExists(selectedLanguage) && selectedLanguage !== language) {
            setSelectedLanguage(language);
            
            if(isExists(selectedOption) &&  isExists(translateKey)) {
                selectedOption.forEach(selection => {
                    selection.label =  isExists(selection[translateKey]) ? 
                    translate(selection[translateKey]) : 
                    selection.label;
                });
            }
        }
    }, [language]);

    const getComponentStyles = () => {
        const multipleFitStyles = {
            control: (styles) => ({
                ...styles,
                backgroundColor: getColor("--white"),
                height: "auto",
                minHeight: 50,
                borderRadius: "6px",
                borderWidth: "1px",
                boxShadow: `2px 2px 4px ${getColor("--shadow-color")}`,
                border: `1px solid ${error && !isExists(selectedOption) ? getColor("--error-color"): getColor("--border-color")}`,
                "&:focus:": {
                    boxShadow: "none"
                },
                "&:hover": {
                    border: `1px solid ${error && !isExists(selectedOption) ? getColor("--error-color") : getColor("--border-color")}`,
                }
            }),
            option: (styles, { provided }) => ({
                ...styles,
                ...provided,
                backgroundColor: getColor("--white"),
                color: getColor("--primary-text-color"),
                'borderBottom': `1px solid ${getColor("--dropdown-separation-color")};`,
                ':hover': {
                    backgroundColor: getColor("--option-bg-color"),
                    color: getColor("--primary-text-color"),
                    borderLeft: `1px solid ${getColor("--primary-color")}`
                }
            }),
            placeholder: (provided) => ({
                ...provided,
                color: getColor("--placeholder-color")
            }),
            menuList: (provided, state) => ({
                ...provided,
                padding: 0,
                border: `1px solid ${getColor("--border-color")}`,
                boxShadow: `2px 2px 4px ${getColor("--shadow-color")}`,
                borderRadius: "0px 0px 6px 6px"
            }),
            menu: base => ({
                ...base,
                marginTop: -3,
                marginLeft: 1,
                width: "99.5%",
                borderRadius: "0px 0px 6px 6px",
            }),
            multiValue: (styles) => {
                return {
                    ...styles,
                    backgroundColor: getColor("--infobox-bg-color"),
                };
            },
            multiValueLabel: (styles) => ({
                ...styles,
                color: getColor("--primary-text-color"),
            }),
            multiValueRemove: (styles) => ({
                ...styles,
                color: '#555D68',
                ':hover': {
                    backgroundColor: 'none',
                    color: '#555D68',
                },
            }),
            IndicatorsContainer: (styles) => ({
                ...styles,
                display: 'none !important'
            })
        };
        return multipleFitStyles;
    }
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span>{translate(noOptionplaceholder)}</span>
            </components.NoOptionsMessage>
        );
    };

    return (
        <div role="multiselect" className="flex column">
            <div className="flex">
                <Select
                    components={{
                        IndicatorSeparator: () => null, NoOptionsMessage
                    }}
                    placeholder={placeholder}
                    closeMenuOnSelect={true}
                    defaultValue={selectedOption}
                    onChange={(e) => { handleInputChange(e) }}
                    isMulti={true}
                    name="MultiSelect"
                    options={props.options}
                    /* isOptionDisabled={(option) => option && option.disabled }*/
                    className={successTick ? "basic-multi-select" : "basic-multi-select-100"}
                    classNamePrefix="select"
                    styles={getComponentStyles()}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: getColor("--border-color"),
                        },
                    })}
                />
                {successTick && isExists(selectedOption) && !error && <SuccessIcon />}
            </div>
            {error && <ErrorText errorMessage={errorMessage} />}
        </div>);
}

export default MultiSelect;