import React from "react";
import { getColor } from "../../../Helpers/Utils";
const ToogleButton = props => {
    const handleCheck = () => {
        props.onToogleAction(props.default);
    }
    if (props.isDisplay) {
        return (
            <div id="toogle-button" style={props.customStyle || { }} >
                <div id="toogle-switch" className="center-center">
                    <p className="p3-light-16 m-right-10">{props.toogleName}</p>
                    <label className="switch">
                        <input type="checkbox" value={props.default} onClick={handleCheck} />
                        <span className={`${!props.default ? "slider" : "sliderRight"} round`} style={{ backgroundColor: props.default ? getColor("--primary-color") : getColor("--secondary-text-color") }}></span>
                    </label>
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

const StyledButton = props => {
    const { onClickAction, name, disable, ...rest } = props;
    if(props.children) {
        return (
            <button disabled={disable} onClick={onClickAction} {...rest}>{props.children}</button>
        );  
    } else {
        return (
            <button disabled={disable} onClick={onClickAction} {...rest}>{name}</button>
        );
    }
}

const CustomButton = props => {
    let { btnType, ...rest } = props;
    if (btnType === "toogle") {
        return (
            <ToogleButton {...rest} />
        )
    } else {
        return (
            <StyledButton {...rest} />
        )
    }
}

export default CustomButton;