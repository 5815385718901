import { Pages, Roles } from "./Constants";
import AdminMentees from '../Components/Dashboard/Admin/AdminMentees';
import AdminMentors from '../Components/Dashboard/Admin/AdminMentors';
import AdminProgramYear from '../Components/Dashboard/Admin/AdminProgramYear';
import MatchingTandemAdmin from '../Components/Dashboard/Admin/MatchTandemAdmin';
import UserInformation from '../Components/Dashboard/Admin/UserInformation';
import ErrorPage from '../Components/PublicPages/ErrorPage';
import UserProfile from '../Components/UserProfile/UserProfile';
import Dashboard from '../Components/Dashboard/Dashboard';
import QuestionnaireForm from '../Components/Dashboard/Questionnaires';

export const UnAuthorized = () => {
    return (
        <div className="occupy-1 flex center-center m-bottom-60 m-top-60">
            <div className="w-max app-standard-gap">
                <ErrorPage errorCode={401} />
            </div>
        </div>
    )
}

export const PageNotFound = () => {
    return (
        <div className="occupy-1 flex center-center m-bottom-60 m-top-60">
            <div className="w-max app-standard-gap">
                <ErrorPage errorCode={404} />
            </div>
        </div>
    )
}

const MenteeQuestionnaire = () => {
   return <QuestionnaireForm role={Roles.Mentee}/>; 
}

const MentorQuestionnaire = () => {
    return <QuestionnaireForm role={Roles.Mentor}/>; 
 }

export const PageNotFoundRoute = {
    Component: PageNotFound,
    ...Pages.Error
}

export const UserRoles = {
    Mentee: {
        Permissions: {
            Routes: [
                    {Component: Dashboard, ...Pages.Home},
                    {Component: Dashboard, ...Pages.Dashboard},
                    {Component: Dashboard, ...Pages.RegisterAsMentee},
                    {Component: Dashboard, ...Pages.RegisterAsMentor},
                    {Component: UserProfile, ...Pages.UserProfile},
                    {Component: MenteeQuestionnaire, ...Pages.QuestionnaireMentee},
                    {Component: PageNotFound, ...Pages.QuestionnaireMentor},
                    {Component: PageNotFound, ...Pages.Error, global:true}
                ]
        }
    },
    Mentor: {
        Permissions: {
            Routes: [
                    {Component: Dashboard, ...Pages.Home},
                    {Component: Dashboard, ...Pages.Dashboard},
                    {Component: Dashboard, ...Pages.RegisterAsMentee},
                    {Component: Dashboard, ...Pages.RegisterAsMentor},
                    {Component: UserProfile, ...Pages.UserProfile},
                    {Component: MentorQuestionnaire, ...Pages.QuestionnaireMentor},
                    {Component: PageNotFound, ...Pages.QuestionnaireMentee},
                    {Component: PageNotFound, ...Pages.Error, global:true}
                ]
        }
    },
    Both: {
        Permissions: {
            Routes: [
                    {Component: Dashboard, ...Pages.Home},
                    {Component: Dashboard, ...Pages.Dashboard},
                    {Component: Dashboard, ...Pages.RegisterAsMentee},
                    {Component: Dashboard, ...Pages.RegisterAsMentor},
                    {Component: UserProfile, ...Pages.UserProfile},
                    {Component: MentorQuestionnaire, ...Pages.QuestionnaireMentor},
                    {Component: MenteeQuestionnaire, ...Pages.QuestionnaireMentee},
                    {Component: PageNotFound, ...Pages.Error, global:true}
                ]
        }
    },
    Admin: {
        Permissions: {
            Routes: [
                    {Component: MatchingTandemAdmin, ...Pages.Home},
                    {Component: MatchingTandemAdmin, ...Pages.RegisterAsMentee},
                    {Component: MatchingTandemAdmin, ...Pages.RegisterAsMentor},
                    {Component: UserProfile, ...Pages.UserProfile},
                    {Component: MatchingTandemAdmin, ...Pages.AdminDashBoard},
                    {Component: AdminMentees, ...Pages.AdminMentees},
                    {Component: AdminMentors, ...Pages.AdminMentors},
                    {Component: UserInformation, ...Pages.AdminUserInformation},
                    {Component: AdminProgramYear, ...Pages.AdminProgramYear},
                    {Component: PageNotFound, ...Pages.Error, global:true}
                ]
        }
    }
}