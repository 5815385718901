import React, { useState } from "react";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import { InformationBar } from "../../Common/HelperComponents";
import {
  Roles,
  CommandType,
  MessageBarTemplates,
  Pages,
} from "../../../Configurations/Constants";
import { useTranslation } from "react-i18next";
import { createMatchingTandem } from "../../../Services/Questionnaire/QuestionnaireService";
import { useHistory } from "react-router-dom";
import { getProposals } from "../../../Services/MatchingTandem/MatchingService";
import { isExists } from "../../../Helpers/Utils";
import { useErrorHandler } from "react-error-boundary";
import {
  startMatching,
  stopMatching,
} from "../../../Store/matchingTendemSlice";
import { useDispatch, useSelector } from "react-redux";
import PopUp from "../../CustomControls/PopUp/PopUp";

const NoMatchingProposalsYet = (props) => {
  const dispatch = useDispatch();
  const {
    Role,
    Email,
    handleStartMatchingEvent,
    handleReloding,
    isPending = false,
  } = props;
  const { t: translate } = useTranslation();
  const history = useHistory();
  const handleError = useErrorHandler();
  const infoText = isPending
    ? translate("could-not-start-matching-alorithm-error")
    : translate(MessageBarTemplates[Role].noMatchingProposalYet.info);

  const isMchingTendemInProgress = useSelector(
    (state) => state.machingTandem.matching
  );
  console.log(isMchingTendemInProgress);

  const [showMachingInProgressPopup, setShowMachingInProgressPopup] = useState(false)

  const getReponseFromPopUpSave = () => {
    setShowMachingInProgressPopup(false)
  }

  const handleClick = async (command) => {
    if (command === CommandType.StartMatching) {

        if(isMchingTendemInProgress){
            setShowMachingInProgressPopup(true);
            return;
        }


      dispatch(startMatching());
      await createMatchingTandem(Email)
        .then((res) => {
          dispatch(stopMatching());
          if (res) {
            getProposals(Email).then((response) => {
              if (response && response.isSuccess) {
                const { menteeProposal } = response.value;
                if (Role === Roles.Mentee && isExists(menteeProposal)) {
                  handleReloding(true);
                } else {
                  handleStartMatchingEvent(true);
                }
              }
            });
          }
        })
        .catch((error) => {
          dispatch(stopMatching())
          handleError(error);
        });
    } else if (command === CommandType.PublishProfile) {
      history.push(Pages.QuestionnaireMentor.path);
    }
  };
  return (
    <div className="m-top-41">
        <PopUp
          show={showMachingInProgressPopup}
          acceptName={translate('confirmation-button')}
          sendResponse={getReponseFromPopUpSave}
          questions={[translate("maching-tandem-warning")]}
        />
      <p className="p-20-m">
        {translate(MessageBarTemplates[Role].noMatchingProposalYet.subline)}
      </p>
      <InformationBar
        info
        middleIcon
        addons="m-top-18 m-bottom-69"
        message={infoText}
      />
      {isPending === false && (
        <CustomButton
          name={translate(
            MessageBarTemplates[Role].noMatchingProposalYet.actionButton
          )}
          onClickAction={() =>
            handleClick(
              Role === Roles.Mentee
                ? CommandType.StartMatching
                : CommandType.PublishProfile
            )
          }
          className="custom-btn-primary w-321"
        />
      )}
    </div>
  );
};
export default NoMatchingProposalsYet;
