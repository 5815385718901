import React, { useEffect, useState,useRef} from "react";
import $ from 'jquery';
import NavBar from "../NavBar/NavBar";
import { MessageBarTemplates, MessageTypes, Roles, InAppImages, Language, UserRoleInformationState, Pages } from "../../Configurations/Constants";
import { MessageBar, InformationBar, StickyWrapper, Question, ErrorText } from "../Common/HelperComponents";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../CustomControls/Buttons/CustomButton";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { getUserProfile, updateUserProfile } from '../../Services/User/UserProfileService';
import { BytesToMb, getColor, getUserRole, isExists } from "../../Helpers/Utils";
import CustomInputText from "../CustomControls/Inputs/CustomInputText";
import SingleSelect from "../CustomControls/DropDowns/SingleSelect";
import CustomTextArea from "../CustomControls/Inputs/CustomTextArea";
import { updateProfileImgPath } from '../../Store/signInSignUpSlice';
import { useErrorHandler } from 'react-error-boundary';
import {ProfileUpdateReducer } from '../../Store/userProfileSlice';

const UserFormControl = (props) => {
  const { t: translate, i18n } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const viewProfileType = props.Role;

  const selectedLanguage =
    i18n.language === Language.Deutsch.Code
      ? Language.Deutsch.Name
      : Language.English.Name;


  const [formData, setFormData] = useState({
    ViewProfileType: viewProfileType,
    SelectedLanguage: selectedLanguage,
    Countries: null,
    FederalState: null,
    Languages: null,
    Cluster: null,
    Questionnaire: null,
    RequestDetails: null
  });

  useEffect(() => {
   // const { RequestDetails: requestDetails } = formData;
   let userDetails={...formData.RequestDetails};
    dispatch(ProfileUpdateReducer(userDetails));
      },[formData] );
      
  const [mappedAnswers, setMappedAnswers] = useState({
    Country: null,
    Cluster: null,
    Language: null,
    State: null,
  });

  const defaultStatusRequiredFields = {
    countryCodeId: false,
    stateOriginalId: false,
    languageId: false,
    city: false,
    clusterId: false
  };

  const [isInvalidImage, setIsInvalidImage] = useState(false);
  const [requiredfieldErrors, setRequiredFieldErrors] = useState(defaultStatusRequiredFields);

  const handleValidation = (fieldId, isRequired = true) => {
    let errorCounter = Object.keys(requiredfieldErrors).length;
    let errorsInFields = { ...requiredfieldErrors };
    if (fieldId && isRequired) {
      errorsInFields[fieldId] = metInputIinitialCondition(fieldId) ? getErrorValueByCondition(inputConditions(fieldId)) : false;
    } else {
      Object.keys(requiredfieldErrors).forEach(eachField => {
        errorsInFields[eachField] = metInputIinitialCondition(eachField) ? getErrorValueByCondition(inputConditions(eachField)) : false;
        if (!errorsInFields[eachField]) {
          errorCounter--;
        }
      });
    }
    setRequiredFieldErrors(errorsInFields);
    return errorCounter === 0;
  }

  const validateMaxLengthQuestions = () => {
    const { profileQuestionAnswer } = formData.RequestDetails;
    let errorCount = profileQuestionAnswer.length || 0;
    if (isExists(profileQuestionAnswer)) {
      profileQuestionAnswer.forEach(eachQuestion => {
        if ((eachQuestion?.userAnswer?.length || 0) < 1000) {
          errorCount--;
        }
      })
    }
    return errorCount === 0;
  }

  const metInputIinitialCondition = (fieldId) => {
    switch (fieldId) {
      case "stateOriginalId": return isCountryGermany();
      case "clusterId":
        return (userRole === Roles.Mentee || userRole === Roles.Both);
      default: return true;
    }
  }

  const inputConditions = (fieldId) => {
    const { RequestDetails: requestDetails } = formData;
    switch (fieldId) {
      case "professionalPosition":
      case "company":
        return (requestDetails[fieldId].length <= 200);
      case "city":
        return isExists(requestDetails[fieldId]) && requestDetails[fieldId].length <= 200;
      default: return isExists(requestDetails[fieldId]);
    }
  }

  const isCountryGermany = () => {
    return formData.RequestDetails["countryCodeId"] === 64;
  }

  const getErrorValueByCondition = (condition) => {
    if (condition) return false;
    return true;
  }

  useEffect(() => {
    if (formData.Questionnaire === null || formData.SelectedLanguage !== selectedLanguage || formData.ViewProfileType !== viewProfileType) {     
      fillUserProfileDetails();
    }
    showOrHideMessage(false);
    resetDataOnLanguageChange();
  }, [selectedLanguage, viewProfileType]);              

  const userRole = getUserRole(user);

  const [programYear, setProgramYear] = useState(null);
  const fillUserProfileDetails = () => {
    getUserProfile(user.email, selectedLanguage, viewProfileType)  
      .then((response) => {
        if (response && response.isSuccess) {
          const { userProfile, userProfileMasterModel, userStatuses } = response.value;
          const { federalState, countryMaster, language, cluster, userProfileQuestions} = userProfileMasterModel;
          setFormData({
            ...formData,
           ViewProfileType: viewProfileType,
            SelectedLanguage: selectedLanguage,
            Countries: prepareOptionsForQuestions(
              countryMaster,
              "countryName",
              "countryCodeId",
              "Country",
              userProfile.countryCodeId
            ),
            FederalState: prepareOptionsForQuestions(
              federalState,
              "stateName",
              "stateOriginalId",
              "State",
              userProfile.stateOriginalId
            ),
            Languages: prepareOptionsForQuestions(
              language,
              "languageName",
              "languageId",
              "Language",
              userProfile.defaultLanguage,
              true
            ),
            Cluster: prepareOptionsForQuestions(
              cluster,
              "clusterText",
              "clusterId",
              "Cluster",
              userProfile.clusterId
            ),
            Questionnaire: userProfileQuestions,
            userDetails: userProfile,
            RequestDetails: prepareAPIRequestData(userProfile),
            nonEditableProfileDetails: prepareNonEditableDetails(userProfile),
            userStatuses: userStatuses
          });

          //set program year 
          const programYear = getUserProgramYear(cluster, userProfile.clusterId)
          setProgramYear(programYear);
          dispatch(ProfileUpdateReducer(formData));
        }
      })
      .catch(error => { handleError(error); });
  }

  const getUserProgramYear = (clusters, clusterId) => {
    const cluster = clusters.find(i => i.clusterId === clusterId);
    return isExists(cluster) ? cluster.clusterText : "";
  }
  const getImageOrSetDefaultImage = (imageUrl) => {
    if (imageUrl && imageUrl !== '') {
      return imageUrl;
    }
    return InAppImages.profileIcon;
  }

  const resetDataOnLanguageChange = () => {
    setRequiredFieldErrors(defaultStatusRequiredFields);
  }

  const saveAnswer = (selectedData, applyValidation = true) => {
    const { questionDetails, selectedOptions } = selectedData;
    let tempUserDetail = formData.RequestDetails;
    const updatedId = ((isExists(selectedOptions) && isExists(selectedOptions.saveId)) && selectedOptions.saveId ||
      (isExists(questionDetails) && isExists(questionDetails.saveId)) && questionDetails.saveId);

    if (updatedId) {
      tempUserDetail[updatedId] = selectedOptions[updatedId] || selectedOptions;
      setFormData({
        ...formData,
        RequestDetails: tempUserDetail
      });
      handleValidation(updatedId, applyValidation);
    }
  };

  const removeImage = () => {
    let tempUserDetail = formData.RequestDetails;
    tempUserDetail.isDeletedProfileImage = true;
    tempUserDetail["imageFile"] = null;
    setImagePath(null);
    setFormData({
      ...formData,
      RequestDetails: tempUserDetail
    });
  }

  const checkIsProgramYearDisabled = () => {
    const { userStatuses } = formData;
    if (userStatuses) {
      const statusMentee = userStatuses.find((user) => user.role === Roles.Mentee)?.userStatus;
      return !(isExists(statusMentee) && (statusMentee === UserRoleInformationState.NEW || statusMentee === UserRoleInformationState.AVAILABLE))
    }
    return true;
  }

  const prepareAPIRequestData = (userProfile) => {
    let requiredDetails = {
      email: userProfile.email,
      professionalPosition: userProfile.professionalPosition,
      company: userProfile.company,
      countryCodeId: userProfile.countryCodeId,
      stateOriginalId: userProfile.stateOriginalId,
      city: userProfile.city,
      languageId: userProfile.defaultLanguage,
      clusterId: userProfile.clusterId,
      profileQuestionAnswer: userProfile.userProfileQuestionAnswers,
      imageFile: getImageOrSetDefaultImage(userProfile.profileImage),
      isDeletedProfileImage: false
    }
    return requiredDetails;
  }

  const prepareNonEditableDetails = (userProfile) => {
    let nonEditUserData = [];
    const nonEditableLabels = {
      "firstName": "First name",
      "lastName": "Last name",
      "email": "E-mail address",
      "gender": "Gender",
      "dateOfBirth": "Birth date"
    }
    Object.keys(nonEditableLabels).forEach(eachItem => {
      nonEditUserData.push({
        label: nonEditableLabels[eachItem],
        value: getCustomValue(eachItem, userProfile)
      });
    });
    return nonEditUserData;
  }

  const getCustomValue = (eachItem, userProfile) => {
    if (eachItem === "dateOfBirth") {
      return new Date(userProfile[eachItem]).toLocaleDateString("en-GB").split("/").join(".");
    }
    else if (eachItem === "gender") {
      return translate(userProfile[eachItem]);
    }
    else {
      return userProfile[eachItem];
    }
  }

  var tempQuestion = [];
  const saveQuestions = (selectedData) => {
    const { questionDetails, selectedOptions } = selectedData;
    let tempUserDetail = { ...formData.RequestDetails };
    let answerFound = false;
    
    if (tempUserDetail.profileQuestionAnswer) {
      tempUserDetail.profileQuestionAnswer.forEach((eachAnswer) => {
        if (
          eachAnswer.userQuestionOriginalId ===
          questionDetails.userQuestionOriginalId
        ) {
          answerFound = true;
          tempQuestion.push({
            userQuestionOriginalId: questionDetails.userQuestionOriginalId,
            userAnswer: selectedOptions==undefined ? eachAnswer.userAnswer : selectedOptions
          });
        }
        else {
          tempQuestion.push({
            userQuestionOriginalId: eachAnswer.userQuestionOriginalId,
            userAnswer: eachAnswer.userAnswer
          });
        }
      });
    } else {
      tempUserDetail.profileQuestionAnswer = [];
    }
    if (!answerFound) {
      tempQuestion.push({
        userQuestionOriginalId: questionDetails.userQuestionOriginalId,
        userAnswer: selectedOptions === undefined ? "" : selectedOptions,
      });
    }
    if (tempQuestion.length > 2) {
      tempQuestion.pop();
      tempQuestion.pop();
    }
    tempUserDetail.profileQuestionAnswer = tempQuestion;
    setFormData({
      ...formData,
      RequestDetails: tempUserDetail
    });
  };

  const saveUserProfileToDb = async () => {
    if (handleValidation() && validateMaxLengthQuestions() && !isInvalidImage) {
      const { userDetails } = formData;
      const { RequestDetails: requestDetails } = formData;
      if (!isCountryGermany()) {
        requestDetails.stateOriginalId = null;
      }
      const isImgUpdate = (userDetails.profileImage !== requestDetails.imageFile);

      await updateUserProfile(requestDetails, isImgUpdate)
        .then((res) => {
          if (res && res.isSuccess) {
            showOrHideMessage(true, translate("Your user account has been successfully updated"), MessageTypes.success);
            setIsInvalidImage(false);

            //Bug #695
            const savedLang = formData.Languages.find(lang => lang.languageId === requestDetails.languageId);
            if (isExists(savedLang)) {
              const langCode = savedLang.languageName === Language.Deutsch.Name ?
                Language.Deutsch.Code : Language.English.Code;
              i18n.changeLanguage(langCode);
            }

            //update mini profile image as well
            if (isExists(imagePath)) {
              dispatch(updateProfileImgPath(imagePath));
            }
            else if (requestDetails.isDeletedProfileImage) {
              dispatch(updateProfileImgPath(InAppImages.profileIcon));
            }
          }
        })
        .catch(error => { handleError(error); });
    } else {
      showOrHideMessage(true, translate(MessageBarTemplates.General.profileMissingFieldsError), MessageTypes.error);
    }
  };

  const [messageData, setMessageData] = React.useState({
    message: "",
    type: MessageTypes.warning,
    show: false,
  });

  const showOrHideMessage = (show, message, type) => {
    setMessageData({ message, type, show });
  };

  const prepareOptionsForQuestions = (
    options,
    label,
    value,
    mappedLabel,
    MappedValue,
    translateLabel = false
  ) => {
    if (Array.isArray(options) && options.length > 1) {
      options = options.flat();
    }
    let allOptions = [];
    options.forEach((option) => {
      const modifiedOption = {
        ...option,
        label: translateLabel ? translate(option[label]) : option[label],
        value: option[value],
        saveId: value,
      };
      allOptions.push(modifiedOption);
      if (option[value] === MappedValue) {
        let MappedDetails = mappedAnswers;
        MappedDetails[mappedLabel] = modifiedOption;
        setMappedAnswers(MappedDetails);
      }
    });
    return allOptions;
  };

  const getQuestions = () => {
    let allQuestions = [];
    const { userDetails } = formData;
    formData.Questionnaire.forEach((eachQuestion, i, questionsArray) => {
      let answer = userDetails.userProfileQuestionAnswers.find(
        (item) =>
          item.userQuestionOriginalId === eachQuestion.userQuestionOriginalId
      );
      allQuestions.push(
        <Question
          key={i}
          questionName={translate(eachQuestion.userQuestionText)}
          customClasses="m-top-40"
          underline={i > questionsArray.length - 1 ? false : true}
        >
          <div className="w-523" >
            <CustomTextArea
              questionDetails={eachQuestion}
              selectedItems={saveQuestions}
              inputMaxlength={1000}
              allowExtraLength
              rows={3}
              info
              infoMessage={translate(`max-characters-exceeded-warning-label`, { count: 1000 })}
              placeholder={translate("Please enter here…")}
              inputValue={answer?.userAnswer || ""}
            />
          </div>
        </Question>
      );
    });
    return allQuestions;
  };

  const canLoadUserData = () => {
    const { nonEditableProfileDetails, RequestDetails: requestDetails, SelectedLanguage, userStatuses } = formData;
    return formData !== null
      && formData.Countries !== null
      && requestDetails !== null
      && nonEditableProfileDetails !== null
      && SelectedLanguage === selectedLanguage
      && userStatuses !== null
      && isExists(userRole)
  };
  const [imagePath, setImagePath] = useState(null);
  const inputRef = React.useRef(null)
  //Set profile picture
  const uploadPicture = (e) => {
    if (e.target.files && e.target.files[0] && allowedImageUpload(e.target.files[0])) {
      let readerProfileImg = new FileReader();
      readerProfileImg.onload = function (e) {
        const imgProfile = e.target.result;
        setImagePath(imgProfile);
        // dispatch(updateProfileImgPath(imgProfile));
        $('#profileImg').attr('src', imgProfile);
      }
      setIsInvalidImage(false);
      saveAnswer({
        questionDetails: { saveId: "imageFile" },
        selectedOptions: e.target.files[0]
      }, false);
      readerProfileImg.readAsDataURL(e.target.files[0]);
    }
    else {
      setIsInvalidImage(true);
      // dispatch(updateProfileImgPath(InAppImages.profileIcon));
      // $('#profileImg').attr('src', InAppImages.profileIcon);
    }
  }

  const allowedImageUpload = (imgData) => {
    const allowedExtension = { "image/png": true, "image/jpeg": true, "image/jpg": true, "image/gif": true };
    if (allowedExtension[imgData.type]) {
      const sizeOfImage = BytesToMb(Number(imgData.size));
      if (sizeOfImage <= 5) {
        return true;
      }
    }
    return false;
  }

  const handleButtonClick = (event) => {
    if (event.keyCode === 13) { 
      // simulates clicking on file input element and opens dialog box
      inputRef.current?.click();
      return;
    }
    return;
    };
  
  const removeButtonClick = (event) => {
    if (event.keyCode === 13) {
      removeImage();
      return;
    }
    return;
  }
  
  if (canLoadUserData()) {
    return (
      <>
        <div className="occupy-1 flex center-center m-bottom-60 m-top-60">
          <div className="w-max">
            <div className="container-fluid app-standard-gap">
              <div className="main-body">
                <div className="row">
                  {/* Profile details Section Starts here*/}
                  <div className="col-lg-4">
                    <div className={`card profile-card-border ${isInvalidImage ? "border-error" : ""} `} >
                      <div className="card-body">
                        <div className="d-flex flex-column align-items-center text-center">
                          <div
                            style={{ width: "170px", height: "170px" }}
                            className="m-top-30 m-bottom-22" 
                          >
                            <img
                              id="profileImg"
                              src={formData.RequestDetails.imageFile || InAppImages.profileIcon}
                              className="rounded-circle cProfileImg"
                              alt=""
                            />
                            <div className="edit-icon" onClick={() => document.getElementById('selectProfileImage').click()} >
                              <input id="selectProfileImage" type="file" style={{ display: 'none' }} name="image" onChange={uploadPicture} ref={inputRef} />
                              <AiOutlineEdit size={24} color={`${getColor("--icons-color")}`} tabIndex={0} onKeyDown={handleButtonClick} />
                            </div>
                            {(isExists(formData.RequestDetails.imageFile) && formData.RequestDetails.imageFile !== InAppImages.profileIcon) &&
                              <div className="remove-icon" onClick={removeImage} >
                                <input id="selectProfileImage" type="file" style={{ display: 'none' }} name="image" onChange={uploadPicture}/>
                                <BsTrash size={20} color={`${getColor("--icons-color")}`} tabIndex={0} onKeyDown={removeButtonClick} />
                              </div>}
                          </div>
                        </div>
                        {isInvalidImage && <ErrorText errorMessage={translate('user-profile-invalid-image')} customClasses="m-bottom-30" />}
                        <InformationBar squared message={translate(MessageBarTemplates.General.userProfileImgCriteria)} />
                      </div>
                    </div>
                  </div>

                  {/* Question Section Starts here*/}
                  <div className="col-lg-8 p-left-63 clear-p-left app-standard-gap-left mobile-top-60">
                    <Question questionName={translate("Country")} helperText="" underline>
                      <div className="w-487 p-top-17">
                        <SingleSelect
                          error={requiredfieldErrors.countryCodeId}
                          selectedItems={saveAnswer}
                          questionDetails={{ ...formData.Countries, saveId: "countryCodeId" }}
                          options={formData.Countries}
                          preSelectedOptions={mappedAnswers.Country}
                          placeholder={translate("Please select a value…")}
                          errorMessage={translate('requiredFieldError')}
                        />
                      </div>
                    </Question>

                    {isCountryGermany() &&
                      <Question
                        questionName={translate("Federal State")}
                        underline
                        customClasses="m-top-40"
                        helperText=""
                      >
                        <div className="w-487 p-top-17">
                          <SingleSelect
                            error={requiredfieldErrors.stateOriginalId}
                            errorMessage={translate('requiredFieldError')}
                            questionDetails={{ ...formData.FederalState, saveId: "stateOriginalId" }}
                            selectedItems={saveAnswer}
                            options={formData.FederalState}
                            preSelectedOptions={mappedAnswers.State}
                            placeholder={translate("Please select a value…")}
                          />
                        </div>
                      </Question>}

                    <Question
                      questionName={translate("City")}
                      underline
                      error={requiredfieldErrors.city}
                      customClasses="m-top-40"
                      helperText=""
                    >
                      <div className="w-487">
                        <CustomInputText
                          customClasses="m-top-17"
                          error={requiredfieldErrors.city}
                          inputMaxlength={200}
                          errorMessage={translate('requiredFieldError')}
                          questionDetails={{ saveId: "city" }}
                          selectedItems={saveAnswer}
                          placeHolderName={translate("Please enter here…")}
                          inputValue={formData.userDetails.city}
                          allowInternalValidation={true}
                          allowExtraLength
                        />
                      </div>
                    </Question>

                    {/* <Question
                      questionName={translate("Preferred account language")}
                      customClasses="m-top-40"
                      underline
                      helperText=""
                    >
                      <div className="w-487 p-top-17  m-left-15">
                        <SingleSelect
                          error={requiredfieldErrors.languageId}
                          errorMessage={translate('requiredFieldError')}
                          questionDetails={{ ...formData.Languages, saveId: "languageId" }}
                          selectedItems={saveAnswer}
                          options={formData.Languages}
                          preSelectedOptions={mappedAnswers.Language}
                          placeholder={translate("Please select a value…")}
                        />
                      </div>
                    </Question> */}

                    <Question
                      questionName={translate("Professional position")}
                      underline
                      customClasses="m-top-40"
                      helperText=""
                    >
                      <div className="w-487">
                        <CustomInputText
                          error={requiredfieldErrors.professionalPosition || false}
                          customClasses="m-top-17"
                          questionDetails={{ saveId: "professionalPosition" }}
                          selectedItems={saveAnswer}
                          placeHolderName={translate("Please enter here…")}
                          inputMaxlength={200}
                          inputValue={formData.userDetails.professionalPosition}
                          allowInternalValidation={true}
                          allowExtraLength
                        />
                      </div>
                     </Question>

                    <Question
                      questionName={translate("Company / Employer")}
                      underline
                      customClasses="m-top-40"
                      helperText=""
                    >
                      <div className="w-487">
                        <CustomInputText
                          customClasses="m-top-17"
                          inputMaxlength={200}
                          error={requiredfieldErrors.company || false}
                          questionDetails={{ saveId: "company" }}
                          selectedItems={saveAnswer}
                          placeHolderName={translate("Please enter here…")}
                          inputValue={formData.userDetails.company}
                          allowInternalValidation={true}
                          allowExtraLength
                        />
                      </div>
                    </Question>
                    {getQuestions()}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  } else return null;
};

export default UserFormControl;