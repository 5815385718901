import React from "react";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import { InformationBar } from "../../Common/HelperComponents";
import { Roles, CommandType, MessageBarTemplates, Pages } from "../../../Configurations/Constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const NoProposalsFound = (props) => {
    const { Role } = props;
    const { t: translate } = useTranslation();
    const history = useHistory();
    const handleClick = (command) => {
        if (command === CommandType.AdjustProfile) {
            history.push(Pages.QuestionnaireMentee.path);
        }
        else if (command === CommandType.SeeProfile) {
            history.push(Pages.QuestionnaireMentor.path);
        }
    }
    return (
        <div className="m-top-41">
            <p className="p-20-m">{translate(MessageBarTemplates[Role].noProposalsFound.subline)}</p>
            <InformationBar
                info
                middleIcon
                addons="m-top-18 m-bottom-69"
                message={translate(MessageBarTemplates[Role].noProposalsFound.info)}
            />
            <CustomButton
                name={translate(MessageBarTemplates[Role].noProposalsFound.actionButton)}
                onClickAction={() => handleClick(Role === Roles.Mentee ? CommandType.AdjustProfile : CommandType.SeeProfile)}
                className="custom-btn-primary w-321" />
        </div>
    )
}
export default NoProposalsFound;