import React, {useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';
import { useMsal, useAccount } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { getUserRole, isExists } from "../../Helpers/Utils";
import { useSelector, useDispatch } from 'react-redux';
import { getUserInfo } from '../../Store/signInSignUpSlice';
import { useErrorHandler } from 'react-error-boundary';
import { UserRoles } from '../../Configurations/UserPermissions';
import { Roles } from '../../Configurations/Constants';

const CommonRoute = (props) => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const handleError = useErrorHandler();

    useEffect(() => {
        if (!isExists(user)
            && account
            && isAuthenticated) {
            dispatch(getUserInfo(account.username))
                .then(res => res)
                .catch(error => {
                    handleError(error);
                });
        }
    }, [isAuthenticated]);

 const getRouteByRole = (user) => {
     let role = getUserRole(user);
     switch(role) {
        case Roles.Mentee: return UserRoles.Mentee.Permissions.Routes;
        case Roles.Mentor: return  UserRoles.Mentor.Permissions.Routes;
        case Roles.Admin: return  UserRoles.Admin.Permissions.Routes;
        case Roles.Both: return  UserRoles.Both.Permissions.Routes;
        default: return [];
     }
 }

 if (isExists(user)) {
    const allRoutes = getRouteByRole(user);
    return (
        <Switch>
            {allRoutes.map((route, i) => (
             isExists(route.global) ?
             <Route key={i} path={route.path} component={route.Component}/>
             :
             <Route key={i} exact path={route.path} component={route.Component}/>
            ))}
        </Switch>
    );
    } else return null;
}

export default CommonRoute;