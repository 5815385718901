import React, { useEffect } from "react";
import {
  PageContent,
  SocialMediaLinks,
  LegalLinks,
  Pages,
  Language,
} from "../../Configurations/Constants";
import { GeneratePara, Footer, NavigationBar } from "../Common/HelperComponents";
import { useTranslation } from "react-i18next";
import { getTranslationSource } from "../../Helpers/Utils";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';

const RenderPublicPage = (props) => {
  const { page, publicPage, lang } = { ...props };
  const  language = publicPage ? lang : null;
  const [data, setData] = React.useState(null);
  const [pageTitle, setPageTitle] = React.useState(null);
  const { t: translate, i18n } = useTranslation();
  const languagei18 = i18n.language;
  const history = useHistory();
  
  useEffect(() => {
    if (
      language &&
      (language === Language.English.Code || language === Language.Deutsch.Code)
    ) {
      i18n.changeLanguage(language);
      history.push(page.path);
    }
    if (page.name === Pages.LegalInfo.name){
      setPageTitle(Pages.LegalInfo.pageTitle);
      setData(getTranslationSource(translate, page));
    }
    else if (page.name === Pages.TermsAndCondition.name) {
      setPageTitle(Pages.TermsAndCondition.pageTitle);
      if (languagei18 === Language.Deutsch.Code)
        setData(PageContent.TermsAndCondition_de);
      else setData(PageContent.TermsAndCondition_en);
    } else if (page.name === Pages.DataPrivacyPolicy.name) {
      setPageTitle(Pages.DataPrivacyPolicy.pageTitle)
      if (languagei18 === Language.Deutsch.Code)
        setData(PageContent.DataPrivacyPolicy_de);
      else setData(PageContent.DataPrivacyPolicy_en);
    }
  }, [languagei18]);

  if (data && pageTitle)
    return (
      <>
        <Helmet>
           <title>{translate(pageTitle)}</title>
        </Helmet>
        { publicPage && <NavigationBar redirectProfile={() => { history.push(Pages.UserProfile.path); }} /> }
        <div className="occupy-1 flex center-center">
          <div className="w-max">
            <h1 className="pageHeadingH1">{data.Title}</h1>
            <div className="pageContainer">
              {data.Content.map((point, index) => (
                <GeneratePara
                  key={index}
                  pointNo={index}
                  heading={point.heading}
                  points={point.points}
                  isSmall={point.isSmall}
                />
              ))}
            </div>
          </div>
        </div>

        { publicPage && <Footer
          SocialMediaLinks={SocialMediaLinks}
          LegalLinks={LegalLinks}
          languageCode={languagei18}
        /> }
      </>
    );
  else return null;
};
export default RenderPublicPage;
