import { isExists } from "../Utils";
export function validateYearsFit(val, translate, isRequiredValidate) {
    let validationSummary = {
        isError: false,
        errorMessage: null
    }
    const year = new Date().getFullYear();

    if (!val && isRequiredValidate) {
        validationSummary.isError = true;
        validationSummary.errorMessage = translate('requiredFieldError');
    }
    else if (val && (!(/^([0-9]){4,4}$/.test(val)) || val > year || val < 1960)) {
        validationSummary.isError = true;
        validationSummary.errorMessage = translate('questionnaire-years-error');
    }
    else {
        validationSummary.isError = false;
        validationSummary.errorMessage = null;
    }
    return validationSummary;
}

export function validateSingleSelect(val, translate, isRequiredValidate) {
    let validationSummary = {
        isError: false,
        errorMessage: null
    }
    if (!isExists(val) && isRequiredValidate) {
        validationSummary.isError = true;
        validationSummary.errorMessage = translate('requiredFieldError');
    }
    else {
        validationSummary.isError = false;
        validationSummary.errorMessage = null;
    }
    return validationSummary;
}

export function validateMultiSelect(val, maxLen, question, translate, isRequiredValidate) {
    let validationSummary = {
        isError: false,
        errorMessage: null
    }
    let noPrefsIds = [];
    let noSBIds = [];
    question.possibleAnswers.forEach(element => {
        if (element.optionText === "no preference" || element.optionText === "keine Präferenz") {
            noPrefsIds.push(element.originalPossibleAnswerId);
        }
    });
    question.possibleAnswers.forEach(element => {
        if (element.optionText === "no such background" || element.optionText === "keiner dieser Backgrounds") {
            noSBIds.push(element.originalPossibleAnswerId);
        }
    });
    const isDefaultNPExist = isExists(val) && isExists(noPrefsIds) && val.some((item) => noPrefsIds.includes(item.originalPossibleAnswerId));
    const isDefaultNSBExist = isExists(val) && isExists(noSBIds) && val.some((item) => noSBIds.includes(item.originalPossibleAnswerId));

    if (!isExists(val) && isRequiredValidate) {
        validationSummary.isError = true;
        validationSummary.errorMessage = translate('requiredFieldError');
    }
    else if (isDefaultNPExist && val.length > 1) {
        validationSummary.isError = true;
        validationSummary.errorMessage = translate('questionnaire-NoPreference-error');
    }
    else if (isDefaultNSBExist && val.length > 1) {
        validationSummary.isError = true;
        validationSummary.errorMessage = translate('questionnaire-NoSuchBackground-error');
    }
    else if (maxLen === 1 && val.length > maxLen) {
        validationSummary.isError = true;
        validationSummary.errorMessage = translate('questionnaire-one-option-error');
    }
    else if (maxLen > 1 && val.length > maxLen) {
        validationSummary.isError = true;
        validationSummary.errorMessage = translate('questionnaire-max-option-exceed');
    }
    else {
        validationSummary.isError = false;
        validationSummary.errorMessage = null;
    }
    return validationSummary;
}