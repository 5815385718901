import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Route, BrowserRouter as Router } from "react-router-dom";
import MentorMe from './Components/MentorMe';
import { Pages, Language } from './Configurations/Constants';
import { useTranslation } from "react-i18next";
import { b2cPolicies } from './Configurations/AzureAuthConfig';
import { EventType, InteractionType } from '@azure/msal-browser';
import { register, registerRole } from './Store/signInSignUpSlice';
import { getRoleFromUrl, logoutFromApplication } from './Helpers/Utils';
import ParentErrorBoundary from "./Components/ErrorBoundaries/ParentErrorBoundary";
import { useErrorHandler } from 'react-error-boundary';

const App = (props) => {
    const { instance } = props;
    const { user } = useSelector((state) => state.user);
    const { i18n } = useTranslation();
    const language = user?.language;
    const history = useHistory();
    const dispatch = useDispatch();
    const handleError = useErrorHandler();

    useEffect(() => {
        if (language === Language.Deutsch.Name) {
            i18n.changeLanguage(Language.Deutsch.Code);
        }
        else if (language === Language.English.Name) {
            i18n.changeLanguage(Language.English.Code);
        }

        if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
            instance.setActiveAccount(instance.getAllAccounts()[0]);
        }
        instance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                instance.setActiveAccount(account);
                const role = getRoleFromUrl(window.location.href);
                if (account
                    && account?.idTokenClaims?.newUser
                    && role) {
                    dispatch(register(account, role))
                        .then(res => res)
                        .catch(error => {
                            handleError(error);
                        });
                }
                else {
                    dispatch(registerRole(account, role))
                        .then(res => res)
                        .catch(error => {
                            handleError(error);
                        });
                }
                history.push(Pages.Home.path);
            }
            else if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
                    }
                }
                if (event.error && event.error.errorMessage.indexOf("AADB2C90091") > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        instance.loginRedirect(b2cPolicies.authorities.signUpSignIn);
                    }
                }
                if (event.error && event.error.errorMessage.indexOf("AADB2C90006") > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        //if continue redirect loop issue persist, then use loginRedirect method
                        logoutFromApplication();
                    }
                }
            }
            else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                //Error : "AADB2C90077: User does not have an existing session and request prompt parameter has a value of 'None'.
                //|| event.error.errorMessage.indexOf("Token acquisition in iframe failed due to timeout") > -1
                if (event.error && (event.error.errorMessage.indexOf("AADB2C90077") > -1) || event.error.errorMessage.indexOf("Token acquisition in iframe failed due to timeout") > -1) {
                    instance.loginRedirect(b2cPolicies.authorities.signUpSignIn);
                }
                if (event.error && (event.error.errorMessage.indexOf("AADB2C90128") > -1) || event.error.errorMessage.indexOf("Please reauthenticate") > -1) {
                    instance.loginRedirect(b2cPolicies.authorities.signUpSignIn);
                }
            }
        });
    }, [language])
    return (
        <ParentErrorBoundary>
            <Router>
                <Route render={() => <MentorMe instance={instance} />} />
            </Router>
        </ParentErrorBoundary>
    );
}
export default App;