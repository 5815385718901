import { LogLevel } from "@azure/msal-browser";
import Constants, { apiEndpoints, AzureADB2CSetings } from './Constants'
/* Enter flows and custom policies */
export const b2cPolicies = {
    names: {
        signUpSignIn: AzureADB2CSetings.b2cPolicies.SignInSignUp
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${AzureADB2CSetings.authDomain}/${AzureADB2CSetings.domain}/${AzureADB2CSetings.b2cPolicies.SignInSignUp}`,
        }
    },
    authorityDomain: AzureADB2CSetings.authDomain
}

/* Configuration object to be passed to MSAL instance */
export const msalConfig = {
    auth: {
        clientId: AzureADB2CSetings.clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        postLogoutRedirectUri: Constants.MentorMeUIBaseUrl,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        //console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        return;
                    case LogLevel.Warning:
                        // console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};
export const protectedResources = {
    scopes: apiEndpoints.scopes
}
export const loginRequest = {
    scopes: ["openid", "offline_access"]
};
