import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { InformationBar } from "../../Common/HelperComponents";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import CustomTable from "../../CustomControls/CustomTable/CustomTable";
import CustomInputText from "../../CustomControls/Inputs/CustomInputText";
import { getClusters, createUpdateCluster, closeCluster } from '../../../Services/Admin/AdminService';
import Constants, { Pages } from "../../../Configurations/Constants";
import { Helmet } from 'react-helmet';
import AdminCommonNavbar from "./AdminCommonNavbar";
import { isExists } from "../../../Helpers/Utils";
import { useErrorHandler } from 'react-error-boundary';
import PopUp from '../../../Components/CustomControls/PopUp/PopUp';

const AdminProgramYear = (props) => {
  const { t: translate, i18n } = useTranslation();
  const handleError = useErrorHandler();

  const selectedLanguage =
    i18n.language === Constants.Language.Deutsch.Code
      ? Constants.Language.Deutsch.Name
      : Constants.Language.English.Name;
  const [messageData, setMessageData] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [formData, setFormData] = useState({
    Clusters: null,
    ClustersToDisplay: null,
    Language: selectedLanguage
  });

  const [clusterText, setClusterText] = useState({
    text: null,
    error: false
  });

  const [isDisplayConfirmPopup, setIsDisplayConfirmPopup] = useState({
    isDisplay: false,
    title: "",
    clusterId: null
  });
  const getReponseFromPopUp = (value) => {
    const { clusterId } = { ...isDisplayConfirmPopup };
    setIsDisplayConfirmPopup({
      ...isDisplayConfirmPopup,
      isDisplay: false,
      clusterId: null
    });
    if (value) {
      //close cluster here
      handleCloseCluster(clusterId);
    }
  }
  useEffect(() => {
    fillTableData();
  }, []);

  const fillTableData = async () => {
    await getClusters()
      .then((response) => {
        if (response && response.isSuccess) {
          setFormData({
            ...formData,
            Language: selectedLanguage,
            Clusters: response.value
          })
        }
      })
      .catch(error => { handleError(error); });;
  }

  const onClusterChange = (selectedData) => {
    const { selectedOptions } = selectedData;
    setClusterText({
      text: selectedOptions,
      error: false,
    });
  };

  const isValidClusterText = (text) => {
    if (text && text.length <= 30 && (/^([a-zA-Z0-9/ _-]+)$/.test(text))) {
      return true;
    }
    else {
      return false;
    }
  }

  const handleCreateCluster = async () => {
    if (isExists(clusterText.text) && isValidClusterText(clusterText.text)) {
      await createUpdateCluster(clusterText.text)
        .then(res => {
          if (res && res.isSuccess) {
            setClusterText({
              text: "",
              error: false,
            });
            fillTableData();
          }
        })
        .catch(error => { handleError(error); });
    } else {
      setClusterText({
        ...clusterText,
        error: true
      });
    }
  }

  const handleCloseCluster = async (clusterId) => {
    if (isExists(clusterId)) {
      await closeCluster(clusterId)
        .then(res => {
          if (res && res.isSuccess) {
            fillTableData();

            setIsDisplayConfirmPopup({
              isDisplay: false,
              title: "",
              clusterId: null
            });
          }
        })
        .catch(error => { handleError(error); });
    }
  }

  const handleCloseClusterPopup = (cluster) => {
    if (isExists(cluster) && cluster.clusterId) {
      setIsDisplayConfirmPopup(
        {
          isDisplay: true,
          title: [translate('deactivate-confirm',
            {
              clustername: cluster.clusterText
            })],
          clusterId: cluster.clusterId
        }
      );
    }
  }
  const showOrHideMessage = (show, message, type) => {
    setMessageData({ message, type, show });
  };

  if (formData.Clusters) {
    return (<>
      <Helmet>
        <title>{translate(Pages.AdminProgramYear.pageTitle)}</title>
      </Helmet>
      <AdminCommonNavbar msgData={messageData} setMessage={showOrHideMessage} />
      <div className="occupy-1 flex center-center m-bottom-60 m-top-60">
        <div className="w-max app-standard-gap p-left-60 p-right-60">
          <p className="p1 m-bottom-56">{translate("Program year")}</p>

          {/* Confirm popup for the cluster */}
          <PopUp
            show={isDisplayConfirmPopup.isDisplay}
            acceptName={translate('Deactivate')}
            rejectName={translate('Cancel')}
            sendResponse={getReponseFromPopUp}
            questions={isDisplayConfirmPopup.title} />

          <InformationBar
            info
            addons="m-top-59 m-bottom-62"
            message={translate("create-program-year-info-text")} />

          <Row className="m-bottom-60">
            <p className="p2-bold-20-v3">
              {translate("create-program-year")}
            </p>
            <Col sm={12} lg={6} md={12} className="m-top-24">
              <Row className="flex">
                <Col sm={12} lg={8} md={12}>
                  <CustomInputText
                    placeHolderName={translate("Please enter here…")}
                    inputMaxlength={30}
                    selectedItems={onClusterChange}
                    error={clusterText.error}
                    errorMessage={isExists(clusterText.text) ? translate('create-program-year-error1') : translate("requiredFieldError")}
                    inputValue={clusterText.text}
                    simple />
                </Col>
                <Col sm={12} lg={4} md={12}>
                  <CustomButton
                    name={translate("create")}
                    className="custom-btn-primary w-123"
                    onClickAction={handleCreateCluster} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <CustomTable
              allItems={formData.Clusters}
              itemsPerPage={25}
              itemKeys={["clusterText", "deactivate"]}
              columnNames={[translate("title"),
              translate("Deactivate")]}
              onToogleActions={(each) => { }}
              injectComponets={{ "deactivate": { type: "deactivateCluster" } }}
              disabledRowClick={true}
              onButtonAction={handleCloseClusterPopup} />
          </Row>

        </div>
      </div>
    </>
    );
  }
  else {
    return null;
  }
};

export default AdminProgramYear;
