import React, { memo } from "react";
import { useTranslation } from "react-i18next";

const TimerCard = (props) => {
  const { t: translate } = useTranslation();
  const { hours, minutes, endDate, isMentee, mini, customClasses } = props;

  const minutesDisplay = minutes && (Number(minutes) < 10 ? '0' : '') + minutes;
  const hoursDisplay = hours && (Number(hours) < 10 ? '0' : '') + hours;

  const getTimerPart = () => {
    return (
      <div className="w-120">
        <div className="flex center-center border-seconday">
          <p className="p-30 cprimary m-bottom-5">{hoursDisplay || "00"}:{minutesDisplay || "00"}</p>
        </div>
        <div className="flex center-center">
          <p className="p2 cprimary m-top-8">{translate("hours-left")}</p>
        </div>
      </div>
    );
  }

  const getTimerLabel = () => {
    return isMentee ? translate("mentor-timer-end-date") : translate("mentee-timer-end-date");
  }

  if (mini) {
    return (
      <div id="TimerCard-mini" className={`p-all-10 ${customClasses || ""}`}>
        <div id="TimerCard-top" className={`row h-93`}>
          <div className="col-sm-12 col-md-12 col-lg-12 bg-primary-light timer-section flex center-center">
            {getTimerPart()}
          </div>
        </div>
        <div id="TimerCard-bottom" className="row h-83">
          <div className="col-sm-12 col-md-12 col-lg-12 bg-grey timer-text-section flex start-center">
            <div className="m-left-16">
              <p>{getTimerLabel()}</p>
              <p className="timer-date m-top-9">{endDate}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={`container m-all-0`}>
      <div id="TimerCard" className={`row h-93 ${customClasses || ""}`}>
        <div className="col-sm-12 col-md-12 col-lg-3 bg-primary-light timer-section flex center-center">
          {getTimerPart()}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-9 bg-grey timer-text-section flex start-center">
          <div className="m-left-56">
            <p>{getTimerLabel()}<span className="m-left-9">{endDate}</span></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TimerCard);