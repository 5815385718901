import React from "react";
import SingleSelect from "../CustomControls/DropDowns/SingleSelect";
import { useTranslation } from "react-i18next";
import CustomBadge from "../CustomControls/Badge/CustomBadge";
import Constants, { Roles } from "../../Configurations/Constants";
const UserCard = (props) => {

    const {
        userRole,
        userPID,
        userName,
        userEmail,
        userProposalDate,
        userDefaultProposalData,
        userProposalData,
        userInformation,
        userInformationDeclineReason,
        fetchSelectedData,
        statusPattern = "Dropdown"
    } = props;
    const { t: translate } = useTranslation();
    const handleSelectedItem = (selectedData) => {
        fetchSelectedData({
            selectedData,
            userRole
        });
    }

    return (
        <div className="user-card">
            <div className="user-card-header flex" >
                <div className=" occupy-1 start-center">
                    <p className="p2-bold-primary m-bottom-10">{userRole === Roles.Mentee ? translate('mentee') : translate('mentor')}</p>
                </div>
                <div className="occupy-1 end-center" >
                    <p className="p2-medium-lh-20 m-bottom-10"> {userPID}</p>
                </div>
            </div>
            <p className="p2-medium-f-24 m-top-46">{userName}</p>
            <p className="p2-medium-lh-20 m-top-25">{translate("proposal-last-updated")}</p>
            <p className="p2-medium-lh-20-light m-top-5">{userProposalDate}</p>
            <p className="p2-medium-lh-21 m-top-16">{translate("proposal-status")}</p>
            <div className="m-top-9 w-50-p">
                {statusPattern === "Dropdown" ?
                    <SingleSelect
                        selectedItems={handleSelectedItem}
                        preSelectedOptions={userDefaultProposalData}
                        options={userProposalData}
                        successTick={false} />
                    :
                    <CustomBadge type={Constants.AllStates[userDefaultProposalData.value].type} labelName={userDefaultProposalData.value} />
                }
            </div>
            <p className="p2-medium-lh-20 m-top-22">{translate("information") || "-"}</p>
            <p className="p2-medium-lh-20-light m-top-5">{userInformation}</p>
            <p className="p2-medium-lh-20 m-top-22">{translate("declineReason") || "-"}</p>
            <p className="p2-medium-lh-20-light m-top-5">{userInformationDeclineReason}</p>
            <p className="p2-medium-lh-20 m-top-22">{translate("E-mail address") || "-"}</p>
            <p className="p2-medium-lh-20-light m-top-5">{userEmail}</p>
        </div>
    );
}

export default UserCard;