import React from "react";
import { Accordion } from "react-bootstrap";

const CustomAccordion = (props) => {
  const { accTitle, children, activeKey, setActiveKey, heading , mixedStyle, bodyClasses, accItemClasses  } = props;
  return (
    <Accordion defaultActiveKey="0"
      activeKey={activeKey}
      onSelect={(e) => setActiveKey(e)}>
      <Accordion.Item className={accItemClasses} eventKey="0">
        <Accordion.Header className={`p2-medium-white ${mixedStyle ? "mixed" : ""}`}>
          {mixedStyle ? 
          <div className="row occupy-1">
            <div className="heading-1 col-sm-12 col-md-2 col-lg-2">
              <p className="p2-bold-v2-white">{heading}</p>
            </div>
            <div className="heading-2 col-sm-12 col-md-9 col-lg-9 flex start-center">
            <p className="p2-bold-20-white">{accTitle}</p>
            </div>
          </div>  :  accTitle}
          </Accordion.Header>
        <Accordion.Body className={`bg-grey ${bodyClasses || ""}`}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default CustomAccordion;