import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useState, useRef } from 'react';
import { InAppImages } from '../../../Configurations/Constants';

const DropDownHeader = props => {
    const { name, isDropdownOpened, showHideDropdown, ...rest } = props;
    const { user } = useSelector((state) => state.user);
    const [profileImage, setProfileImage] = React.useState(null);
    useEffect(() => {
        if (user && user.profileImage
            && user.profileImage !== ''
            && user.profileImage !== '-')
            setProfileImage(user.profileImage);
        else
            setProfileImage(InAppImages.profileIcon);
    }, [profileImage, user])
    return (
        <div data-testid="dropdown-header" id="dropdown-header" className={isDropdownOpened ? "headerShadow" : ""} onClick={showHideDropdown} {...rest}>
            <div className="occupy-1 center-center">
                <img src={profileImage} alt="" className="accountProfileImage" />
                <span className={isDropdownOpened ? "no-underLine" : "underLine"}>{name}</span>
            </div>
        </div>
    );
}

export const Options = props => {
    let { name, onClickOption } = props;
    return (
        <div id="dropdown-list" onClick={onClickOption}>
            <div className="options">
                <span>{name}</span>
            </div>
        </div>
    );
}

const DropDown = props => {
    const [isOpen, setIsOpen] = useState(false);
    const { displayName, dmargin, children } = props;
    const toggling = () => {
        if (!isOpen) {
            document.addEventListener('click', handleClickOutside, true);
        }
        else {
            document.removeEventListener('click', handleClickOutside, true);
        }
        setIsOpen(!isOpen);
    };
    const dropDownStyles = {
        commonWidth: {
            "width": (displayName.length * 9 + 62) + "px",
        }
    }

    const clickReference = useRef(null);
    const handleClickOutside = (event) => {
        if (clickReference.current && !clickReference.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    return (
        <div id="dropdown-Container" ref={clickReference} style={{ marginLeft: dmargin }}>
            <DropDownHeader name={displayName} isDropdownOpened={isOpen} showHideDropdown={toggling} style={dropDownStyles.commonWidth} />
            {isOpen && <>
                <div id="dropdown-list-container" style={dropDownStyles.commonWidth} onClick={() => setIsOpen(false)}>
                    {children}
                </div>
            </>}
        </div>
    );
};

export default DropDown;
