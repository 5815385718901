import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { InformationBar } from "../../Common/HelperComponents";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import SingleSelect from "../../CustomControls/DropDowns/SingleSelect";
import Constants, { MessageTypes, Pages } from "../../../Configurations/Constants";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import {
  getManualMatchingMasterData,
  createMatchingTandemManual
} from '../../../Services/Admin/AdminService';
import { isExists } from "../../../Helpers/Utils";
import PopUp from '../../../Components/CustomControls/PopUp/PopUp';
import FilterMatchingTandem from '../Matching/FilterMatchingTandem';
import AdminCommonNavbar from "./AdminCommonNavbar";
import { useErrorHandler } from 'react-error-boundary';

const MatchingTandemAdmin = () => {
  const { t: translate, i18n } = useTranslation();
  const selectedLanguage =
    i18n.language === Constants.Language.Deutsch.Code
      ? Constants.Language.Deutsch.Name
      : Constants.Language.English.Name;
  const handleError = useErrorHandler();
  const [formData, setFormData] = useState({
    ProposalData: null,
    TandemStates: null,
    Language: selectedLanguage,
    UserSearchData: null,
    ClustersSearchData: null
  });
  const [isInputError, setInputError] = useState({
    isMenteeError: false,
    isMentorError: false
  });
  const [isDisplayConfirmPopup, setIsDisplayConfirmPopup] = useState(false);

  useEffect(() => {
    if (formData.ProposalData === null) {
      fillTandemData();
    }
  }, [formData, selectedLanguage]);

  const showOrHideMessage = (show, message, type) => {
    setMessageData({ message, type, show });
  };
  const [messageData, setMessageData] = useState({
    message: "",
    type: "",
    show: false,
  });

  const fillTandemData = async () => {
    showOrHideMessage(false);
    await getManualMatchingMasterData()
      .then((response) => {
        if (response && response.isSuccess) {
          const tandemStatuses = isExists(response.value["tandemStates"]) ? response.value["tandemStates"] : [];
          const proposalStatuses = isExists(response.value["proposalStates"]) ? response.value["proposalStates"] : [];
          const menteeSearchData = isExists(response.value["mentees"]) ? response.value["mentees"] : [];
          const mentorSearchData = isExists(response.value["mentors"]) ? response.value["mentors"] : [];
          const userSearchData = isExists(response.value["allUsers"]) ? response.value["allUsers"] : [];
          const clusterSearchData = isExists(response.value["clusters"]) ? response.value["clusters"] : [];
          setFormData(
            {
              ...formData,
              ProposalData: {
                Mentees: buildMenteeMentorSearchData(menteeSearchData, true),
                Mentors: buildMenteeMentorSearchData(mentorSearchData, false),
                SelectedMentee: null,
                SelectedMentor: null
              },
              TandemStates: tandemStatuses,
              ProposalStates: proposalStatuses,
              UserSearchData: userSearchData,
              ClustersSearchData: clusterSearchData,
              Language: selectedLanguage
            }
          )
        }
      })
      .catch(error => { handleError(error); });
  }

  const buildMenteeMentorSearchData = (options, isMentee) => {
    let items = [];
    options.forEach((option) => {
      const modifiedOption = {
        option,
        isMentee,
        label: `${option["firstName"]} ${option["lastName"]}`,
        subLabel: option["email"] || "-",
        value: option["email"]
      }
      items.push(modifiedOption);
    });
    return items;
  }

  const getUserInputMentee = (selectedItem) => {
    const { selectedOptions } = selectedItem;
    if (isExists(selectedOptions)) {
      setFormData({
        ...formData,
        ProposalData: {
          ...formData.ProposalData,
          SelectedMentee: selectedOptions.option
        }
      });
      setInputError({
        ...isInputError,
        isMenteeError: false
      })
    }
    else {
      setFormData({
        ...formData,
        ProposalData: {
          ...formData.ProposalData,
          SelectedMentee: null
        }
      });
    }
  }

  const getUserInputMentor = (selectedItem) => {
    const { selectedOptions } = selectedItem;
    if (isExists(selectedOptions)) {
      setFormData({
        ...formData,
        ProposalData: {
          ...formData.ProposalData,
          SelectedMentor: selectedOptions.option
        }
      });
      setInputError({
        ...isInputError,
        isMentorError: false
      });
    }
    else {
      setFormData({
        ...formData,
        ProposalData: {
          ...formData.ProposalData,
          SelectedMentor: null
        }
      });
    }
  }

  const createMatchingTandem = async () => {
    const isSameUser = formData.ProposalData.SelectedMentee.email === formData.ProposalData.SelectedMentor.email;
    if (isSameUser) {
      showOrHideMessage(true, translate('admin-mentor-only-differnt-user-error'), MessageTypes.error);
    }
    else {
      const tandemData = {
        mentees: formData.ProposalData.SelectedMentee,
        mentors: formData.ProposalData.SelectedMentor,
      }
      await createMatchingTandemManual(tandemData)
        .then(res => {
          if (res && res.isSuccess) {
            fillTandemData();
            showOrHideMessage(true, translate('admin-mentor-created-text'), MessageTypes.success);
          }
        })
        .catch(error => { handleError(error); });
    }
  }

  const getReponseFromPopUp = (value) => {
    setIsDisplayConfirmPopup(false);
    if (value) {
      createMatchingTandem();
    }
  }

  const handleCreateTandem = () => {
    const isValidMentee = isExists(formData.ProposalData.SelectedMentee);
    const isValidMentor = isExists(formData.ProposalData.SelectedMentor);

    if (isValidMentee && isValidMentor) {
      if (formData.ProposalData.SelectedMentee.email === formData.ProposalData.SelectedMentor.email) {
        showOrHideMessage(true, translate('admin-mentor-only-differnt-user-error'), MessageTypes.error);
      }
      else {
        setIsDisplayConfirmPopup(true);
      }
    }
    else {
      setInputError({
        ...isInputError,
        isMenteeError: !isValidMentee,
        isMentorError: !isValidMentor
      });
    }
  }

  if (isExists(formData.ProposalData)) {
    return (
      <>
        <Helmet>
          <title>{translate(Pages.AdminDashBoard.pageTitle)}</title>
        </Helmet>
        <AdminCommonNavbar msgData={messageData} setMessage={showOrHideMessage} />
        <div className="occupy-1 flex center-center m-bottom-60 m-top-60">
          <div className="w-max app-standard-gap">
            <>
              <p className="p1 m-bottom-56">{translate("matching-tandems")}</p>
              {/* Create matching tandems*/}
              <PopUp show={isDisplayConfirmPopup}
                acceptName={translate('create')}
                rejectName={translate('Cancel')}
                sendResponse={getReponseFromPopUp}
                questions={[translate('admin-mentor-only-confirm-create')]} />

              <p className="p2-bold-20 m-bottom-23">
                {translate("admin-create-matching-tandems")}  </p>

              <div className="occupy-1 paragraph-container column">
                <InformationBar
                  info
                  light
                  addons="m-top-18 m-bottom-25"
                  message={translate("admin-create-matching-info-text")}
                />
                <Row>
                  <Col sm={12} lg={3} md={12} className="m-top-10">
                    <p className="p2-bold-v2 m-bottom-18">
                      {translate("mentee-proposal")}
                    </p>
                    <div>
                      <SingleSelect
                        selectedItems={getUserInputMentee}
                        error={isInputError.isMenteeError}
                        errorMessage={translate("requiredFieldError")}
                        options={formData.ProposalData.Mentees}
                        successTick={false}
                        placeholder={translate('admin-user-info-searchbox-placeholder')}
                        isClearSelection={true}
                        serachIcon />
                    </div>

                    {/* Remove comment for Phase-2
                     <div className="m-top-30">
                      <SingleSelect
                        selectedItems={getUserInputMentee}
                        error={isInputError.isMenteeError}
                        errorMessage={translate("requiredFieldError")}
                        options={formData.ProposalData.Mentees}
                        successTick={false}
                        placeholder={translate('admin-user-info-searchbox-placeholder')}
                        isClearSelection={true}
                        serachIcon />
                    </div> */}

                  </Col>
                  <Col sm={12} lg={3} md={12} className="m-top-10">
                    <p className="p2-bold-v2 m-bottom-18">
                      {translate("mentor-proposal")}
                    </p>
                    <div>
                      <SingleSelect
                        selectedItems={getUserInputMentor}
                        error={isInputError.isMentorError}
                        errorMessage={translate("requiredFieldError")}
                        options={formData.ProposalData.Mentors}
                        successTick={false}
                        placeholder={translate('admin-user-info-searchbox-placeholder')}
                        isClearSelection={true}
                        serachIcon />
                    </div>

                    {/* Remove comment for Phase-2
                     <div className="m-top-30">
                      <SingleSelect
                        selectedItems={getUserInputMentee}
                        error={isInputError.isMenteeError}
                        errorMessage={translate("requiredFieldError")}
                        options={formData.ProposalData.Mentees}
                        successTick={false}
                        placeholder={translate('admin-user-info-searchbox-placeholder')}
                        isClearSelection={true}
                        serachIcon />
                    </div> */}
                  </Col>
                  <Col
                    sm={12}
                    lg={3}
                    md={12}
                    className="occupy-1 flex">
                    <div className="h-45 m-top-52">
                      <CustomButton
                        name={translate('create')}
                        className={"custom-btn-primary w-150"}
                        onClickAction={handleCreateTandem} />
                    </div>
                  </Col>
                </Row>
              </div>

              {/* View matching tandems*/}
              {formData.TandemStates &&
                <FilterMatchingTandem
                  TandemStates={formData.TandemStates}
                  ProposalStates={formData.ProposalStates}
                  setMessage={showOrHideMessage}
                  Users={formData.UserSearchData}
                  Clusters={formData.ClustersSearchData} />}
            </>
          </div>
        </div>
      </>
    );
  }
  else
    return null;
};

export default MatchingTandemAdmin;
