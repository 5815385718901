const TermsAndCondition = {
  Title: 'Allgemeine Geschäftsbedingungen',
  Content: [
    {
      heading: "§ 1 Geltung, Begriffsdefinitionen",
      points: [
        <>(1) MentorMe gGmbH, Prenzlauer Allee 186, 10405 Berlin, Deutschland (im Folgenden: „wir“) betreibt unter der Webseite <a href="https://mentorme-ngo.org" target="_blank" className="hyperlink-active">https://mentorme-ngo.org</a> eine Matchingplattform für beruflche Mentoringzwecke als Dienstleistung. Die nachfolgenden allgemeinen Geschäftsbedingungen gelten für alle Leistungen zwischen uns und unseren Kunden (im Folgenden: „Kunde“ oder „Sie“) in ihrer zum Zeitpunkt der Bestellung gültigen Fassung, soweit nicht etwas anderes ausdrücklich vereinbart wurde.</>,
        `(2) „Verbraucher“ im Sinne dieser Geschäftsbedingungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. „Unternehmer“ ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt, wobei eine rechtsfähige Personengesellschaft eine Personengesellschaft ist, die mit der Fähigkeit ausgestattet ist, Rechte zu erwerben und Verbindlichkeiten einzugehen.`
      ],
    },
    {
      heading: "§ 2 Zustandekommen der Verträge, Speicherung des Vertragstextes",
      points: [
        <>(1) Die folgenden Regelungen über den Vertragsabschluss gelten für Services, angeboten im Namen von MentorMe über unseren Online-Shop unter <a href="https://mentorme-ngo.org" target="_blank" className="hyperlink-active">https://mentorme-ngo.org</a> oder angeboten im Auftrag von Dritten für interne Mentoringzwecke dieser Dritten.</>,
        `(2) Unsere Produktdarstellungen im Internet sind unverbindlich und kein verbindliches Angebot zum Abschluss eines Vertrages.`,
        `(3) Bei Eingang einer Bestellung in unserem Online-Shop von Kunden oder über einen schriftlichen Vertragsabschluss von Unternehmen und Organisationen gelten folgende Regelungen: Der Kunde gibt ein bindendes Vertragsangebot ab, indem er die in unserem Online-Shop vorgesehene Bestellprozedur, oder über ein Unternehmen oder eine Organisationen erworbene Matching-Dienstleistung, erfolgreich durchläuft. Die Bestellung erfolgt in folgenden Schritten:`,
        `(3.1) Für Einzelkunden:`,
        <ol>
          <li>Auswahl der gewünschten Ware, der gewählten Dienstleistung(en),</li><br/>
          <li>Hinzufügen der Produkte durch Anklicken des entsprechenden Buttons (z.B. „In den Warenkorb“, „In die Einkaufstasche“ o.ä.),</li><br/>
          <li>Prüfung der Angaben im Warenkorb,</li><br/>
          <li>Aufrufen der Bestellübersicht durch Anklicken des entsprechenden Buttons (z.B. „Weiter zur Kasse“, „Weiter zur Zahlung“, „Zur Bestellübersicht“ o.ä.),</li><br/>
          <li>Eingabe/Prüfung der Adress- und Kontaktdaten, Auswahl der Zahlungsart, Bestätigung der AGB und Widerrufsbelehrung,</li><br/>
          <li>Abschluss der Bestellung durch Betätigung des Buttons „Jetzt kaufen“. Dies stellt Ihre verbindliche Bestellung dar.</li><br/>
          <li>Der Vertrag kommt zustande, indem Ihnen innerhalb von drei Werktagen an die angegebene E-Mail-Adresse eine Bestellbestätigung von uns zugeht.</li><br/>
          <li>Vor der Bestellung können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen, insbesondere der Bestelldaten, der AGB und der Widerrufsbelehrung, erfolgt per E-Mail nach dem Auslösen der Bestellung durch Sie, zum Teil automatisiert. Wir speichern den Vertragstext nach Vertragsschluss nicht.</li><br/>
          <li>Eingabefehler können mittels der üblichen Tastatur-, Maus- und Browser-Funktionen (z.B. »Zurück-Button« des Browsers) berichtigt werden. Sie können auch dadurch berichtigt werden, dass Sie den Bestellvorgang vorzeitig abbrechen, das Browserfenster schließen und den Vorgang wiederholen.</li><br/>
          <li>	Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.</li>
        </ol>,
        `(3.2) Für Mitarbeiter*innen von Kunden, sie Unternehmen oder Organisationen sind:`,
        <ol>
          <li> Auswahl der gewünschten Ware, der gewählten Dienstleistung(en) durch das Unternehmen oder die Organisationen</li><br/>
          <li> Der Vertrag kommt zustande, indem innerhalb von 30 Werktagen an die angegebene E-Mail-Adresse des Unternehmens oder der Organisationen eine Bestellbestätigung von uns zugeht oder ein Vertrag von beiden Seiten unterzeichnetet wird. </li>
        </ol>,
        `(4) Im Falle des Vertragsschlusses kommt der Vertrag mit MentorMe gGmbH, Prenzlauer Allee 186, 10405 Berlin, Deutschland zustande.`
      ],
    },
    {
      heading: "§ 3 Gegenstand des Vertrages und wesentliche Merkmale der Produkte",
      points: [
        `(1) Bei unserem Online-Shop ist Vertragsgegenstand:`,
        <ol type="a">
          <li> Der Verkauf von Dienstleistungen. Die konkret angebotenen Dienstleistungen können Sie unseren Artikelseiten und unserer Website bzw. der Website des Unternehmens oder der Organisation, die unsere Matching-Dienstleistungen erworben hat, entnehmen.</li><br/>
          <li> Die Erbringung von Dienstleistungen. Die konkret angebotenen Dienstleistungen können Sie unseren Artikelseiten oder Website entnehmen. Nutznießer unserer Services von Unternehmen oder Organisationen können die angebotenen Dienstleistungen ggf. auch von den Websiten der Unternehmen oder Organisationen entnehmen.</li>
        </ol>,
        `(2) Die wesentlichen Merkmale der Ware und Dienstleistungen finden sich in der Artikelbeschreibung oder auf den Websiten der Unternehmen oder Organisationen, die eine Matching-Dienstleistung von uns erworben haben.`,
        `(3) Für den Verkauf digitaler Services gelten die aus der Servicebeschreibung ersichtlichen oder sich sonst aus den Umständen ergebenden Beschränkungen, insbesondere zu Hard- und/oder Softwareanforderungen an die Zielumgebung. Wenn nicht ausdrücklich etwas anderes vereinbart ist, ist Vertragsgegenstand nur die private und gewerbliche Nutzung der Services ohne das Recht zur Weiterveräußerung oder Unterlizensierung.`
      ],
    }, {
      heading: "§ 4 Preise, Versandkosten und Lieferung",
      points: [
        `(1) Die in den jeweiligen Angeboten angeführten Preise sowie die Versandkosten sind Gesamtpreise und beinhalten alle Preisbestandteile einschließlich aller anfallenden Steuern.`,
        `(2) Der jeweilige Kaufpreis ist vor der Lieferung des Services zu leisten(Vorkasse), es sei denn, wir bieten ausdrücklich den Kauf auf Rechnung an.Die unseren Einzelkunden zur Verfügung stehenden Zahlungsarten sind unter einer entsprechend bezeichneten Schaltfläche im Online - Shop oder im jeweiligen Angebot ausgewiesen.Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche sofort zur Zahlung fällig.`,
        `(3) Zusätzlich zu den angegebenen Preisen können für die Lieferung von Produkten und Services Versandkosten anfallen, sofern der jeweilige Artikel nicht als versandkostenfrei ausgewiesen ist.Die Versandkosten werden Ihnen auf den Angeboten, ggf.im Warenkorbsystem und auf der Bestellübersicht nochmals deutlich mitgeteilt.`,
        `(4) Alle angebotenen Services sind, sofern nicht in der Servicebeschreibung deutlich anders angegeben, sofort versandfertig(Lieferzeit: max. 3 Wochen) nach dem Eingang der Zahlung.`,
        `(5) Die Lieferung erfolgt weltweit.`
      ],
    }, {
      heading: "§ 5 Zurückbehaltungsrecht, Eigentumsvorbehalt",
      points: [
        `(1) Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.`,
        `(2) Der Service bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum.`
      ],
    }, {
      heading: "§ 6 Widerrufsrecht",
      points: [`Als Verbraucher haben Sie ein Widerrufsrecht. Dieses richtet sich nach unserer Widerrufsbelehrung.`],
    }, {
      heading: "§ 7 Haftung",
      points: [
        `(1) Vorbehaltlich der nachfolgenden Ausnahmen ist unsere Haftung für vertragliche Pflichtverletzungen sowie aus unerlaubter Handlung auf Vorsatz oder grobe Fahrlässigkeit beschränkt.`,
        `(2) Wir haften bei leichter Fahrlässigkeit im Falle der Verletzung des Lebens, des Körpers, der Gesundheit oder bei Verletzung einer vertragswesentlichen Pflicht unbeschränkt. Wenn wir durch leichte Fahrlässigkeit mit der Leistung in Verzug geraten sind, wenn die Leistung unmöglich geworden ist oder wenn wir eine vertragswesentliche Pflicht verletzt haben, ist die Haftung für darauf zurückzuführende Sach- und Vermögensschäden auf den vertragstypisch vorhersehbaren Schaden begrenzt. Eine vertragswesentliche Pflicht ist eine solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht, deren Verletzung die Erreichung des Vertragszwecks gefährdet und auf deren Einhaltung Sie regelmäßig vertrauen dürfen. Dazu gehört insbesondere unsere Pflicht zum Tätigwerden und der Erfüllung der vertraglich geschuldeten Leistung, die in § 3 beschrieben wird.`
      ],
    }, {
      heading: "§ 8 Vertragssprache",
      points: [`Als Vertragssprache steht Englisch oder Deutsch zur Verfügung.`],
    },
    {
      heading: "§ 9 Gewährleistung/Kundendienst",
      points: [
        `(1) Die Gewährleistung richtet sich nach den gesetzlichen Bestimmungen.`,
        `(2) Gegenüber Unternehmern beträgt die Gewährleistungsfrist auf gelieferte Dienste 12 Monate.`,
        `(3) Als Verbraucher werden Sie gebeten, den Service / das digitale Gut oder die erbrachte Dienstleistung bei Vertragserfüllung umgehend auf Vollständigkeit, offensichtliche Mängel und Transportschäden zu überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich mitzuteilen.Kommen Sie dem nicht nach, hat dies natürlich keine Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.`,
        <>(4) Unser Kundendienst für Fragen, Reklamationen und Beanstandungen steht Ihnen Mo.- Do.von 09 - 16 Uhr, per E - Mail: <a href="mailto:mail@mentorme-ngo.org" className="hyperlink-active">mail@mentorme-ngo.org</a> zur Verfügung.</>
      ],
    },
    {
      heading: "§ 10 Schlussbestimmungen",
      points: [
        `(1) Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).`,
        `(2) Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.`,
        `(3) Sofern es sich beim Kunden um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem Kunden und dem Anbieter der Sitz des Anbieters.`
      ]
    }
  ]
}

export default TermsAndCondition;
