import { createSlice } from '@reduxjs/toolkit';
import { updateUserProfile } from '../Services/User/UserProfileService';
import { apiEndpoints } from '../Configurations/Constants';

const slice = createSlice({
    name: 'userprofile',
    initialState: {
        updateprofileMessage: null,
        userProfile:{userProfile:{},Questionnaire:{}}
    },

    reducers: {
        profileUpdateSuccess: (state, action) => {
            state.updateprofileMessage = action.payload;
        },
        ProfileUpdateReducer:(state,action) =>{
            state.userProfile = {...state.userProfile,...action.payload};
            return state;
        }
    }
});

export default slice.reducer;
const { profileUpdateSuccess} = slice.actions;

export const updateProfileData = (formdata) => async dispatch => {
    updateUserProfile(apiEndpoints.User.UpdateProfile, formdata)
        .then((res) => {
            if (res) {
                dispatch(profileUpdateSuccess(res.value));
            }
        })
        .catch(error => { throw error });
}

export const {ProfileUpdateReducer} = slice.actions;
export const selectProfileData = (state) => state