import { Get, Post } from '../service';
import { apiEndpoints } from '../../Configurations/Constants';
export async function registerUser(endpoint, account, role) {
    const registrationData = {
        FirstName: account.idTokenClaims.given_name,
        LastName: account.idTokenClaims.family_name,
        DOB: account.idTokenClaims.extension_Birthdate,
        Language: account.idTokenClaims.extension_Language,
        Gender: account.idTokenClaims.extension_Gender ? account.idTokenClaims.extension_Gender : "NA",
        Email: account.username,
        IsAcceptedDataPrivacyPolicy: account.idTokenClaims.extension_TermsAndCondition1,
        IsAcceptedTermsConditions: account.idTokenClaims.extension_TermsAndCondition2,
        Role: role
    }
    return await Post(endpoint, registrationData, true)
        .then(res => res)
        .catch(error => { throw error; });
}

export async function updateRole(endpoint, account, role) {
    if (role) {
        const data = {
            Email: account.username,
            Role: role
        }
        return await Post(endpoint, data, true)
            .then(res => res)
            .catch(error => { throw error; });
    }
}

export async function getUserDetails(email) {
    const data = {
        emailId: email
    }
    return await Get(apiEndpoints.Account.GetUserDetails, data)
        .then(res => {
            const response = Object.assign(
                { email: (data.emailId).trim() },
                { ...res.value });
            return response;
        })
        .catch(error => { throw error; });
}
