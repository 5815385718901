import React from "react";
import CustomButton from "../../CustomControls/Buttons/CustomButton";
import { InformationBar } from "../../Common/HelperComponents";
import { Roles, Pages, CommandType, MessageBarTemplates } from "../../../Configurations/Constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const NoProfile = (props) => {
    const { Role } = props;
    const { t: translate } = useTranslation();
    const history = useHistory();
    const handleClick = (command) => {
        if (command === CommandType.CreateMentee) {
            //redirect to Matching page
            history.push(Pages.QuestionnaireMentee.path);
        }
        else {
            history.push(Pages.QuestionnaireMentor.path);
        }
    }
    return (
        <div className="m-top-41">
            <p className="p-20-m">{translate(MessageBarTemplates[Role].noProfile.subline)}</p>
            <InformationBar
                info
                middleIcon
                addons="m-top-18 m-bottom-69"
                message={translate(MessageBarTemplates[Role].noProfile.info)} />
            <CustomButton
                name={translate(MessageBarTemplates[Role].noProfile.actionButton)}
                onClickAction={() => handleClick(Role === Roles.Mentee ? CommandType.CreateMentee : CommandType.CreateMentor)}
                className="custom-btn-primary w-321" />
        </div>
    )
}
export default NoProfile;