import { Get, Post } from '../service';
import { apiEndpoints } from '../../Configurations/Constants';
import { isExists } from '../../Helpers/Utils';
import { getUserDetails } from '../Login/LoginService';

export async function getProposals(emailId) {
  const requestEndpoint = apiEndpoints.MatchingTandem.GetProposals;
  const data = {
    email: emailId
  }
  return await Post(requestEndpoint, data)
    .then(res => res)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getProposalsWithUserInfo(emailId) {
  let result = {
    userDetails: null,
    proposals: null,
    isSuccess: false
  }
  try {
    await Promise.all([
      getUserDetails(emailId)
        .then(res => {
          if (res) {
            result = {
              ...result,
              userDetails: res
            }
          }
        })
        .catch(error => { throw error; }),
      Post(apiEndpoints.MatchingTandem.GetProposals, {
        email: emailId
      })
        .then(res => {
          if (res && res.isSuccess) {
            result = {
              ...result,
              proposals: res.value
            }
          }
        })
        .catch(error => { throw error; })
    ]);

    if (isExists(result.proposals) && isExists(result.userDetails)) {
      result = {
        ...result,
        isSuccess: true
      }
    }
  }
  catch (error) {
    throw error;
  }
  return result;
}

export async function getProposedUserProfile(emailId, role, language, matchingTandemId) {
  const requestEndpoint = apiEndpoints.User.GetProposedUserProfile + `?email=${emailId}&role=${role}&language=${language}`;
  return await Get(requestEndpoint)
    .then(res => {
      if (res.isSuccess && isExists(res.value)) {
        const response = Object.assign(
          { email: (emailId) },
          { matchingTandemId: (matchingTandemId) },
          { ...res.value });
        return response;
      }
      return null;
    })
    .catch(error => {
      throw error;
    })
}

export async function acceptProposal(data) {
  return await Post(apiEndpoints.MatchingTandem.AcceptProposal, data)
    .then(res => res)
    .catch(error => { throw error; });
}
export async function declineProposal(data) {
  return await Post(apiEndpoints.MatchingTandem.DeclineProposal, data)
    .then(res => res)
    .catch(error => { throw error; });
}

export async function getDeclineReason(emailId, role, language) {
  const requestEndpoint = apiEndpoints.MatchingTandem.DeclineReason + `?email=${emailId}&role=${role}&language=${language}`;
  return await Get(requestEndpoint)
    .then(res => {
      if (res.isSuccess && isExists(res.value)) {
        return res.value;
      }
      return null;
    })
    .catch(error => {
      throw error;
    })
}