import React, { Component } from 'react';
import LogError from '../../Helpers/LoggingUtility';
import { isExists } from '../../Helpers/Utils';
import ErrorPage from '../PublicPages/ErrorPage';

class ParentErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorCode: null
        }
    }

    componentDidCatch(error, errorInfo) {
        if (isExists(error) && isExists(error.message) && error.message.indexOf('Network Error') > -1) {
            console.log("Error during connection/logging to API");
        }
        else {
            if (error && error.code) {
                this.setState({
                    errorCode: error.code
                })
            }
            navigator.onLine && LogError(error, errorInfo.componentStack);
        }
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage errorCode={this.state.errorCode} center={true}/>
        } else {
            return this.props.children;
        }
    }
}

export default ParentErrorBoundary;