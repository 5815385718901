import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Pages } from '../../Configurations/Constants';
import { PageNotFound } from '../../Configurations/UserPermissions';
import RenderPublicPage from '../PublicPages/RenderPages';

const PublicPagesRoute = (props) => {
    const {page, isPublic} = props;
    let { url } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${url}/en`} render={() => <RenderPublicPage publicPage={isPublic} page={page} lang="en"/> }/>
            <Route exact path={`${url}/de`} render={() => <RenderPublicPage publicPage={isPublic} page={page} lang="de"/> }/>
            <Route exact path={`${url}`} render={() => <RenderPublicPage publicPage={isPublic} page={page}/> }/>
            <Route  path={Pages.Error.path} component={PageNotFound}/>
        </Switch>
    )
}

export default PublicPagesRoute;