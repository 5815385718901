import { Get, Post } from '../service';
import { apiEndpoints } from '../../Configurations/Constants';

export async function getUserProfile(emailId, language, viewProfileType) {                
    const requestEndpoint = apiEndpoints.User.GetUserProfile + `?email=${emailId}&language=${language}&viewProfileType=${viewProfileType}`;  
    return await Get(requestEndpoint)
        .then(res => res)
        .catch(error => { throw error; });
}

export async function updateUserProfile(selectedData) {
    const requestProfile = Object.assign({}, selectedData);
    delete requestProfile.imageFile;

    const requestUpdateImg = new FormData();
    requestUpdateImg.append("email", selectedData.email);
    requestUpdateImg.append("imageFile", selectedData.imageFile);
    requestUpdateImg.append("IsDeleted", selectedData.isDeletedProfileImage || false);

    return await Post(apiEndpoints.User.UpdateProfileImage, requestUpdateImg)
        .then(async res => {
            if (res && res.isSuccess) {
                return await Post(apiEndpoints.User.UpdateProfile, requestProfile)
                    .then(result => result)
                    .catch(error => { throw error; });
            }
        })
        .catch(error => { throw error; });
}